import React from "react";
import CustomerInformation from "./Components/CustomerInformation";
import LocationSection from "./Components/LocationSection";
import TypeOfServiceSection from "./Components/TypeOfServiceSection";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import errorMessage from "../../util/errorMessage";
import { agent_create_job } from "../../features/agentlessForm";
import {
  isJobComplete,
  resetLeadDid,
  setDialerPhone,
  setIsTranfer,
} from "../../features/dialerSlice";
import {
  add_images,
  get_jobs_data_by_phone,
  send_image_form,
  send_location_form,
} from "../../features/jobsSlice";
import {
  agent_get_new_qoute,
  create_lead_initial,
  get_leads_by_id,
  reset_quote,
  update_vicidial_id,
} from "../../features/leadsSlice";
import { sendIframeMsg } from "../../util/iFrame";
import DynamicComponent from "./Components";
import Button from "../../components/atoms/Button";
import QuoteModal from "./Components/QuoteModal";
import JobListing from "./Components/JobListing";
import _ from "lodash";
import { DotsLoader, FormSelectInput } from "../../components";
import { get_job_category } from "../../features/jobCategorySlice";
import { agentCreateLead } from "../../services/agentlessFormService";
import socket from "../../components/organisms/SMS/socket";
import FormInput from "./Common/FormInput";
import { get_industry_drd } from "../../features/IndustrySlice";
import AddUpdateLeadBetaPage from "../LeadBeta";
import {
  apiCheckServiceDirect,
  sellLeadToServiceDirect,
  sellLeadToServiceDirect2,
} from "../../services/leads";
import TransferCall from "../LeadBeta/Common/Sections/TransferCall";
function AddUpdateLeadPage() {
  const leads = useSelector((state) => state.leads);
  const loading = useSelector((state) => state.agentlessForm?.isLoading);
  const { industryDrd } = useSelector((state) => state.industry);
  const { record } = useSelector((state) => state.jobCategory);
  const [jobDetail, setJobDetail] = React.useState(null);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const {
    phone_number,
    callType,
    dialer_lead_id,
    vicidial_id,
    did_pattern,
    did_description,
    conversation_detail,
    industry_id,
    industry_name,
  } = useSelector((state) => state.dialer);
  const [isMultipleJobs, setIsMultipleJobs] = React.useState(false);
  const [vicidialId, setVicidialId] = React.useState(null);
  const params = useParams();
  const { id } = params;
  const [errors, setErrors] = React.useState([]);
  const { isLoading, quote } = useSelector((state) => state.leads);
  const jobLoader = useSelector((state) => state.jobs?.isLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUpdating = location.pathname.startsWith("/leads/update");
  const menualData = location.state;

  let initialValues = {
    industry_id: "6366126737c4d7c72204c6c0",
    industry_name: "Towing",
    phone: "",
    job_category_id: null,
    service_cost: null,
    scheduled_on: null,

    name: null,
    car_year: null,
    car_model: null,
    car_make: null,
    is_neutral: false,

    email: null,
    alternate_phone: null,

    drop_latitude: 0,
    drop_longitude: 0,
    drop_country: null,
    drop_city: null,
    drop_state: null,
    drop_zip: null,
    complete_drop_address: null,

    pickup_latitude: 0,
    pickup_longitude: 0,
    pickup_country: null,
    pickup_city: null,
    pickup_state: null,
    pickup_zip: null,
    complete_pickup_address: null,
    distance: null,
    special_instruction: null,
    car_service: "",
    payment_type: "COD",
    scheduled_end: null,
  };
  React.useEffect(() => {
    dispatch(get_job_category({ get_new_records_also: 1 }));
    dispatch(get_industry_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getLead = async () => {
    try {
      const res = await dispatch(get_leads_by_id(id));
      if (res.payload?.status === 200) {
        formik.setValues({
          ...res.payload.data,
          id: id,
          name: menualData?.name ? menualData?.name : res?.payload?.data?.name,
          email: menualData?.email
            ? menualData?.email
            : res?.payload?.data?.email,
          alternate_phone: menualData?.alternate_phone
            ? menualData?.alternate_phone
            : res?.payload?.data?.alternate_phone,
          service_name: record?.records?.find(
            ({ _id }) => _id === res.payload.data?.job_category_id
          )?.name,
          payment_type: formik.values.payment_type,
          industry_id: res.payload.data?.industry_id?._id,
          industry_name: res.payload.data?.industry_id?.name,
        });
      }
    } catch (error) {
      console.error(
        "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
        error
      );
      errorMessage({ payload: error, action: "Lead", msg: "loaded" });
    }
  };
  if (isUpdating) {
    const lead = _.find(leads.record, { _id: id });
    if (lead) {
      initialValues = {
        ...lead,
        id: lead._id,
      };
    }
  }
  React.useEffect(() => {
    if (
      (isUpdating && leads?.record?.length === 0) ||
      leads?.record === undefined ||
      id
    ) {
      getLead();
    }
    // eslint-disable-next-line
  }, [id]);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      // const quoting_status = formik.values?.quoting_request_status;
      const {
        quoting_request_status = null,
        service_cost = null,
        quoting_request_price = null,
      } = values;
      if (quoting_request_status === "Pending")
        return toast.error(
          "You quoting request is pending, please wait for approval."
        );
      if (quoting_request_status === "Rejected")
        return toast.error("You quoting request has been rejected");
      if (
        (service_cost === null || service_cost === undefined) &&
        (quoting_request_price === null ||
          quoting_request_price === undefined) &&
        (leads.quote?.service_cost === null ||
          leads.quote?.service_cost === undefined)
      )
        return toast.error("Job couldn't be create without service cost");
      const payload = {
        service_cost: quoting_request_price
          ? quoting_request_price
          : service_cost
          ? service_cost
          : leads.quote?.service_cost,
        scheduled_on: formik.values.scheduled_on,
        payment_type: formik?.values.payment_type,
        id: formik.values._id,
      };
      try {
        const res = await dispatch(agent_create_job(payload));
        if (res.payload?.status === 200) {
          setJobDetail(res?.payload?.data);
          dispatch(isJobComplete(true));
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Lead",
            msg: "created",
          });
        }
      } catch (err) {
        errorMessage({
          payload: err,
          setErrors: setErrors,
          action: "Lead",
          msg: "created",
        });
      }
    },
  });
  const handleNewImages = (obj) => {
    const lead_id = formik.values.id ? formik.values.id : id;
    if (obj?.job?._id === lead_id) {
      const { attachments } = obj?.job;
      formik.setFieldValue("attachments", attachments);
    }
  };
  const handleNewMessage = (obj) => {
    const lead_id = formik.values.id ? formik.values.id : id;
    if (obj?.lead?._id === lead_id) {
      const {
        pickup_latitude,
        pickup_longitude,
        pickup_country,
        pickup_city,
        pickup_state,
        pickup_zip,
        complete_pickup_address,
      } = obj?.lead;

      formik.setFieldValue("pickup_latitude", pickup_latitude);
      formik.setFieldValue("pickup_longitude", pickup_longitude);
      formik.setFieldValue("pickup_country", pickup_country);
      formik.setFieldValue("pickup_city", pickup_city);
      formik.setFieldValue("pickup_state", pickup_state);
      formik.setFieldValue("pickup_zip", pickup_zip);
      formik.setFieldValue("complete_pickup_address", complete_pickup_address);
    }
  };
  React.useEffect(() => {
    // Connect only if not already connected
    if (!socket.connected) {
      socket.connect();
    }
    // Setup event listeners only after successful connection
    const setupEventListeners = () => {
      socket.on("client_job_location_found", handleNewMessage);
      socket.on("client_job_image_uploaded", handleNewImages);
    };

    if (socket.connected) {
      setupEventListeners();
    } else {
      socket.on("connect", setupEventListeners);
    }
    return () => {
      socket.off("client_job_location_found", handleNewMessage);
      socket.off("client_job_image_uploaded", handleNewImages);
      socket.off("connect", setupEventListeners); // Ensure to remove this listener to prevent duplication
      if (socket.connected) {
        socket.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  // console.log("formik", formik.values);
  React.useEffect(() => {
    if (record && record?.records?.length && formik.values.job_category_id) {
      const service_name = record?.records?.find(
        ({ _id }) => _id === formik.values.job_category_id
      )?.name;
      formik.setFieldValue("service_name", service_name);
    }
    // eslint-disable-next-line
  }, [record, formik.values.job_category_id]);
  const receiveMessage = (event) => {
    if (event?.data?.action === "vendor_id_confirm") {
      setVicidialId(event?.data?.vicidial_id);
    }
  };
  window.addEventListener("message", receiveMessage);
  const handleSendImageForm = () => {
    if (!formik.values.phone) return toast.error("Phone number is required");
    if (isUpdating) {
      const lead_id = formik.values.id ? formik.values.id : id;
      dispatch(send_image_form(lead_id));
    } else {
      createMenualLead("image_form");
    }
  };
  const handleSendLocationForm = () => {
    if (!formik.values.phone) return toast.error("Phone number is required");
    if (isUpdating) {
      const lead_id = formik.values.id ? formik.values.id : id;
      dispatch(send_location_form(lead_id));
    } else {
      createMenualLead("location_form");
    }
  };
  const handleSaveImageForm = () => {
    if (!formik.values.phone) return toast.error("Phone number is required");
    if (isUpdating) {
      const lead_id = formik.values.id ? formik.values.id : id;
      onAddImages(lead_id);
    } else {
      createMenualLead("save_image");
    }
  };
  const onAddImages = async (id) => {
    try {
      const images = [];
      formik.values.attachments.forEach((attachment) => {
        if (typeof attachment === "string" && attachment.startsWith("data:")) {
          images.push(attachment);
        }
      });
      const res = await dispatch(add_images({ img: images, id }));
      if (res?.payload?.status === 200) {
        toast.success("Images Added Successfully");
        formik.setFieldValue("attachments", res.payload.data.attachments);
      } else {
        errorMessage({ payload: res.payload, action: "Images", msg: "added" });
      }
    } catch (err) {
      errorMessage({ payload: err, action: "Images", msg: "added" });
    }
  };
  const createMenualLead = async (action) => {
    if (!formik.values.phone) return toast.error("Phone number is required.");
    if (
      formik.values.payment_type === "Insurance" &&
      !formik.values?.extra_fields?.insurance_name
    )
      return toast.error("Please select an insurance name.");
    if (!formik.values.job_category_id || !formik.values.service_name)
      return toast.error("Please select at least one service type.");
    try {
      let phone = phone_number ? phone_number : formik?.values?.phone;
      const res = await dispatch(
        create_lead_initial({
          industry_id: formik.values.industry_id
            ? formik.values.industry_id
            : "6366126737c4d7c72204c6c0",
          // : industry?.industryDrd?.find(({ name }) => name === "Towing")?._id,
          phone:
            phone?.length === 10 && !phone?.includes("+")
              ? `+1${phone}`
              : phone?.length === 11 &&
                !phone?.includes("+") &&
                phone?.startsWith("1")
              ? `+${phone}`
              : phone,
          call_payload: null,
          dialer_lead_id: null,
          vicidial_id: null,
          call_type: "Manual",
        })
      );
      if (res?.payload?.status === 200) {
        formik?.setFieldValue("id", res?.payload?.data?._id);

        if (action === "get_quote") {
          await getQuote({
            ...formik.values,
            // ...res?.payload?.data,
            id: res?.payload?.data?._id,
            job_category_id: formik?.values?.job_category_id,
            extra_fields: formik.values?.extra_fields,
            name: formik.values.name,
            email: formik.values.email,
            alternate_phone: formik.values.alternate_phone,
            menualVicidialId: vicidialId,
          });
        } else if (action === "image_form") {
          dispatch(send_image_form(res?.payload?.data?._id));
          await saveCompleteInfo({
            ...formik.values,
            id: res?.payload?.data?._id,
            job_category_id: formik?.values?.job_category_id,
            extra_fields: formik.values?.extra_fields,
            name: formik.values.name,
            email: formik.values.email,
            alternate_phone: formik.values.alternate_phone,
            menualVicidialId: vicidialId,
            industry_id: formik.values.industry_id
              ? formik.values.industry_id
              : "6366126737c4d7c72204c6c0",
          });
        } else if (action === "location_form") {
          dispatch(send_location_form(res?.payload?.data?._id));
          await saveCompleteInfo({
            ...formik.values,
            id: res?.payload?.data?._id,
            job_category_id: formik?.values?.job_category_id,
            extra_fields: formik.values?.extra_fields,
            name: formik.values.name,
            email: formik.values.email,
            alternate_phone: formik.values.alternate_phone,
            menualVicidialId: vicidialId,
            industry_id: formik.values.industry_id
              ? formik.values.industry_id
              : "6366126737c4d7c72204c6c0",
          });
        } else if (action === "save_image") {
          onAddImages(res?.payload?.data?._id);
          await saveCompleteInfo({
            ...formik.values,
            id: res?.payload?.data?._id,
            job_category_id: formik?.values?.job_category_id,
            extra_fields: formik.values?.extra_fields,
            name: formik.values.name,
            email: formik.values.email,
            alternate_phone: formik.values.alternate_phone,
            menualVicidialId: vicidialId,
            industry_id: formik.values.industry_id
              ? formik.values.industry_id
              : "6366126737c4d7c72204c6c0",
          });
        }
        navigate(`/leads/update/${res?.payload?.data?._id}`, {
          state: {
            name: formik.values.name,
            email: formik.values.email,
            alternate_phone: formik.values.alternate_phone,
            menualVicidialId: vicidialId,
          },
        });
      } else {
        errorMessage({
          payload: res.payload?.error,
          setErrors: setErrors,
          action: "SMS",
          msg: "send",
        });
      }
    } catch (error) {
      console.error("🚀 ~ file: index.jsx:475 ~ handleSMS ~ error:", error);
      errorMessage({
        payload: error,
        setErrors: setErrors,
        action: "SMS",
        msg: "send",
      });
    }
  };
  const saveCompleteInfo = async (values) => {
    try {
      await agentCreateLead(values);
    } catch (err) {
      console.log("👊 ~ saveCompleteInfo ~ err:", err);
    }
  };
  const createLeadInitial = async ({
    callType = null,
    phone_number = null,
    dialer_lead_id = "",
    did_pattern,
    did_description,
    conversation_id = null,
  }) => {
    try {
      const callPayload =
        callType === "Incoming" && did_pattern
          ? [{ did: did_pattern, did_group_id: did_description }]
          : null;

      const formatPhoneNumber = (phone) => {
        if (phone?.length === 10 && !phone?.includes("+")) return `+1${phone}`;
        if (
          phone?.length === 11 &&
          !phone?.includes("+") &&
          phone?.startsWith("1")
        )
          return `+${phone}`;
        return phone;
      };

      const res = await dispatch(
        create_lead_initial({
          industry_id: formik.values.industry_id || "6366126737c4d7c72204c6c0",
          phone: formatPhoneNumber(phone_number),
          call_payload: callPayload,
          dialer_lead_id,
          call_type: callType,
          conversation_id,
          payment_type: formik.values.payment_type,
        })
      );

      dispatch(resetLeadDid());

      if (res?.payload?.status === 200) {
        const { lead_num, _id } = res.payload.data;
        formik.setFieldValue("id", _id);
        sendIframeMsg({
          action: "send_vendor_lead_code",
          user: user?.dialer_data?.dialer_user,
          pass: user?.dialer_data?.dialer_pass,
          vendor_lead_code: lead_num,
        });

        setIsMultipleJobs(false);
        navigate(`/leads/update/${_id}`);
      } else {
        errorMessage({
          payload: res.payload?.error,
          setErrors,
          action: "SMS",
          msg: "send",
        });
      }
    } catch (error) {
      console.error("Error in createLeadInitial:", error);
      errorMessage({ payload: error, setErrors, action: "SMS", msg: "send" });
    }
  };

  const createAutoLead = async () => {
    if (!phone_number) return;

    // Set values in formik directly
    const fieldsToSet = {
      phone: phone_number,
      call_type: callType,
      dialer_lead_id,
      vicidial_id,
      did_pattern,
      did_description,
      conversation_id: conversation_detail?._id,
      industry_id,
      industry_name,
    };
    Object.entries(fieldsToSet).forEach(([field, value]) =>
      formik.setFieldValue(field, value)
    );

    dispatch(isJobComplete(false));

    const jobs = await dispatch(
      get_jobs_data_by_phone({ phone_num: phone_number })
    );
    const hasMultipleJobs =
      jobs?.payload?.data?.totalItems > 0 &&
      jobs?.payload?.data?.records?.length > 0;

    if (hasMultipleJobs) {
      setIsMultipleJobs(true);
    } else {
      await createLeadInitial({
        phone_number,
        callType,
        dialer_lead_id,
        vicidial_id,
        did_pattern,
        did_description,
        conversation_id: conversation_detail?._id || null,
      });
    }

    dispatch(
      setDialerPhone({
        phone: "",
        callType: "",
        dialer_lead_id: null,
        vicidial_id: null,
        did_description: "",
        did_pattern: "",
        industry_id: null,
        industry_name: null,
      })
    );
  };
  const getElementIdsWithExtraFields = () => {
    const elements = document.querySelectorAll('[id*="extra_fields."]');
    const ids = Array.from(elements).map((el) => el.id); // Extract ids
    return ids;
  };
  // Get Quote
  const populateExtraFields = (userInput, fieldArray) => {
    const extraFields = {};
    fieldArray.forEach((field) => {
      const fieldKey = field.split(".")[1];
      extraFields[fieldKey] =
        userInput && fieldKey in userInput ? userInput[fieldKey] : "";
    });

    return extraFields;
  };
  // const fields = getElementIdsWithExtraFields();
  // const getMissingKeys = (idsArray, referenceObject) => {
  //   // Extract unique keys from the IDs array
  //   const uniqueKeys = [...new Set(idsArray.map(id => id.split('.')[1]))];

  //   // Filter keys that are not present in the referenceObject
  //   const missingKeys = uniqueKeys.filter(key => !(key in referenceObject));

  //   return missingKeys;
  // };
  // console.log(getMissingKeys(fields, labels));
  const getQuote = async (values) => {
    if (
      values.payment_type === "Insurance" &&
      !values?.extra_fields?.insurance_name
    )
      return toast.error("Please select an insurance name.");
    if (!values.job_category_id || !formik.values.service_name)
      return toast.error("Please select at least one service type.");
    const fields = getElementIdsWithExtraFields();
    const populatedExtraFields = populateExtraFields(
      values?.extra_fields,
      fields
    );
    try {
      const res = await dispatch(
        agent_get_new_qoute({
          ...values,
          extra_fields: populatedExtraFields,
          industry_id: values.industry_id
            ? values.industry_id
            : "6366126737c4d7c72204c6c0",
        })
      );
      if (res?.payload?.status === 200) {
        formik.setFieldValue("service_cost", res?.payload?.data?.service_cost);
        formik.setFieldValue("extra_fields", populatedExtraFields);
      } else {
        errorMessage({
          payload: res.payload,
          action: "Quote",
          msg: "generated",
        });
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:333 ~ getQuote ~ err:", err);
      errorMessage({ payload: err, action: "Quote", msg: "generated" });
    }
  };
  React.useEffect(() => {
    createAutoLead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone_number]);
  React.useEffect(() => {
    async function fetchData() {
      const lead_id = formik.values.id ? formik.values.id : id;
      if (lead_id && vicidialId) {
        try {
          const res = await dispatch(
            update_vicidial_id({ id: lead_id, vicidial_id: vicidialId })
          );
          if (res.payload?.status === 200) {
            formik.setValues({
              ...res.payload.data,
              id: lead_id,
              name: menualData?.name
                ? menualData?.name
                : res?.payload?.data?.name,
              email: menualData?.email
                ? menualData?.email
                : res?.payload?.data?.email,
              alternate_phone: menualData?.alternate_phone
                ? menualData?.alternate_phone
                : res?.payload?.data?.alternate_phone,
            });
          }
          setVicidialId(null);
        } catch (err) {
          console.error(
            "🚀 ~ file: index.jsx:243 ~ React.useEffect ~ err:",
            err
          );
        }
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [vicidialId, formik.values.id, id]);

  // useEffect(() => {
  //   // Function to get all elements with "extra_fields" in their id
  //   const getElementIdsWithExtraFields = () => {
  //     const elements = document.querySelectorAll('[id*="extra_fields"]');
  //     const ids = Array.from(elements).map((el) => el.id); // Extract ids
  //     console.log("IDs with 'extra_fields':", ids);
  //     return ids;
  //   };

  //   // Get the list of IDs
  //    getElementIdsWithExtraFields();
  // }, [formik.values]);

  const checkServiceDirect = async (zipcode, industyId) => {
    if (formik?.values?.call_type !== "Incoming" || !formik?.values?._id) {
      return;
    }
    const newCheckServiceMess = zipcode;
    formik.setFieldValue("pickup_zip", newCheckServiceMess);
    setAvailableVendors([]);
    setCheckServiceMess("");
    setCheckServiceMsg(null);
    if (formik?.values?._id && formik?.values?.sold_to !== null) return;

    if (!newCheckServiceMess) {
      return;
    }

    const currentIndustryId = industyId;
    const currentZip = newCheckServiceMess;

    const previousIndustryId = previousValues.industry_id;
    const previousZip = previousValues.pickup_zip;
    if (
      currentIndustryId === previousIndustryId &&
      currentZip === previousZip
    ) {
      console.log("No changes in values, skipping API call.");
      return;
    }
    setPreviousValues({
      industry_id: currentIndustryId,
      pickup_zip: currentZip,
    });

    setIsLoader(true);

    const payload = {
      zip: newCheckServiceMess,
      industry_id: currentIndustryId,
    };
    dispatch(setIsTranfer(false));
    try {
      const res = await apiCheckServiceDirect(payload);

      if (res.status === 200 || res.status === 201) {
        setCheckServiceMess(res?.data?.available_buyer);
        setCheckServiceMsg(null);
      } else {
        setCheckServiceMsg(res.data.message || "No service available");
        setAvailableVendors(res?.data?.data || []);
      }
    } catch (err) {
      setCheckServiceMess(false);
      setCheckServiceMsg(err?.response.data.message || "No service available");
      setAvailableVendors(err?.response?.data?.data || []);

      console.error(err);
    } finally {
      setIsLoader(false);
    }
  };
  const [isLoader, setIsLoader] = React.useState(false);

  const [checkServiceMess, setCheckServiceMess] = React.useState("");
  const [checkServiceMsg, setCheckServiceMsg] = React.useState("");
  const [availableVendors, setAvailableVendors] = React.useState([]);

  const [previousValues, setPreviousValues] = React.useState({
    industry_id: "",
    pickup_zip: "",
  });

  const callSellLeadToServiceDirect = async (details, value) => {
    let leadId = formik?.values?._id || null;
    let payload = {
      leadDetails: details,
      lead_id: leadId,
      transfer_value: value,
    };
    if (!leadId) return toast.error("Lead id is missing");
    if (leadId) {
      setIsLoader(true);
      try {
        const res = await sellLeadToServiceDirect(payload);
        if (res.status === 200 || res.status === 201) {
          toast.success(
            res?.data?.message || "Sell request has been created successfully."
          );
          dispatch(setIsTranfer(true));
          setTimeout(async () => {
            await callSellLeadToServiceDirect2();
          }, 5000);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoader(false);
      }
    }
  };
  const callSellLeadToServiceDirect2 = async () => {
    setIsLoader(true);
    const payload = {
      lead_id: formik?.values?._id || null,
    };
    if (payload.lead_id) {
      try {
        const res = await sellLeadToServiceDirect2(payload);

        if (res.status === 200 || res.status === 201) {
          toast.success(
            res?.data?.message || "You has been left the call successfully."
          );
          dispatch(setIsTranfer(false));
          navigate("/leads");
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.message || "You couldn't be left from the call."
        );
        console.error(err);
      } finally {
        setIsLoader(false);
      }
    } else {
      toast.error("Lead id not found");
    }
  };
  return (
    <div>
      {isLoading || jobLoader || loading ? <DotsLoader /> : null}
      {isMultipleJobs && (
        <JobListing
          onCancelModal={() => setIsMultipleJobs(false)}
          callType={formik.values.call_type}
          createLeadInitial={() =>
            createLeadInitial({
              phone_number: formik.values.phone,
              callType: formik.values.call_type,
              dialer_lead_id: formik.values.dialer_lead_id,
              did_pattern: formik.values.did_pattern,
              did_description: formik.values.did_description,
              industry_id: formik.values.industry_id,
            })
          }
        />
      )}
      <div>
        <div className="px-3.7 w-full">
          <div className="flex items-center">
            <h5 className="my-2">Creating Lead</h5>
          </div>
        </div>

        {/* <div className="pl-2 pt-3 !mb-2">
          <div className="font-semibold text-lg">Customer Information:</div>
        </div> */}
        <div className="flex grid grid-cols-4 items-center gap-4 pb- px-2 border-t">
          <div className="">
            <FormInput
              name="company_name"
              type="text"
              label="Site Name"
              formik={formik}
              disabled={true}
              value={
                formik.values.call_payload_domain
                  ? formik.values.call_payload_domain
                  : ""
              }
            />
          </div>
          <div className="mb-2">
            <label
              className="font-semibold text-xs text-gray-500 mb-1"
              htmlFor={"lead_num"}
            >
              {"Select Industry"}
            </label>

            <FormSelectInput
              name="industry_id"
              options={industryDrd}
              valueProp="_id"
              labelProp="name"
              // formik={formik}
              value={formik.values?.industry_id}
              onChange={(value) => {
                const name =
                  industryDrd?.find(({ _id }) => _id === value)?.name || "";
                formik.setFieldValue("industry_id", value);
                formik.setFieldValue("industry_name", name);
                formik.setFieldValue("extra_fields", null);
                checkServiceDirect(formik.values.pickup_zip, value);
              }}
            />
          </div>
          {formik?.values?.call_type === "Incoming" &&
          formik?.values?._id &&
          formik?.values?.industry_name &&
          formik?.values?.industry_name !== "Towing" ? (
            <div className="relative col-span-2 flex p-2 mt-2 justify-center w-full border rounded-md flex-col">
              <label className="font-semibold text-xs text-gray-500 mb-1">
                Transfer Call
              </label>
              <TransferCall
                isLoader={isLoader}
                setIsLoader={setIsLoader}
                callSellLeadToServiceDirect={callSellLeadToServiceDirect}
                checkServiceMess={checkServiceMess}
                checkServiceDirect={checkServiceDirect}
                formik={formik}
                checkServiceMsg={checkServiceMsg}
                availableVendors={availableVendors}
              />
            </div>
          ) : null}
        </div>
      </div>

      {formik?.values?.industry_name &&
      formik?.values?.industry_name !== "Towing" ? (
        <AddUpdateLeadBetaPage
          formik={formik}
          vicidialId={vicidialId}
          isLoader={isLoader}
          setIsLoader={setIsLoader}
          callSellLeadToServiceDirect={callSellLeadToServiceDirect}
          checkServiceMess={checkServiceMess}
          checkServiceDirect={checkServiceDirect}
        />
      ) : (
        <div className="md:borde md:bg-light md:p-2 rounded">
          {quote ? (
            <QuoteModal
              formik={formik}
              onCancelModal={() => {
                setJobDetail(null);
                dispatch(reset_quote());
                if (jobDetail) {
                  navigate("/leads");
                }
              }}
              lead_id={formik.values.id ? formik.values.id : id}
              jobDetail={jobDetail}
            />
          ) : null}

          <div className="px-">
            <CustomerInformation
              formik={formik}
              errors={errors}
              handleSendImageForm={handleSendImageForm}
              handleSaveImageForm={handleSaveImageForm}
            />
            <TypeOfServiceSection formik={formik} errors={errors} />
            <LocationSection
              formik={formik}
              errors={errors}
              handleSendLocationForm={handleSendLocationForm}
            />
            <DynamicComponent
              formik={formik}
              serviceName={formik.values.service_name}
              errors={errors}
            />
          </div>
          <div className="flex w-full justify-end mt-2">
            <Button
              text="Get Quote"
              variant="btn_submit"
              // disabled={!formik.values.service_name}
              onClick={() =>
                isUpdating
                  ? getQuote(formik.values)
                  : createMenualLead("get_quote")
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AddUpdateLeadPage;

import api from "./api";

// export const getOutboundGroup = async () => {
//   return await api.get(`/api/call_centers/outbound-did`);
// };
export const getOutboundGroup = async (data) => {
  return await api.post(`/api/call_centers/outbound-did-report`, data);
};
export const createOutboundGroup = async (data) => {
  return await api.post("/api/call_centers/outbound-did", data);
};
export const updateOutboundGroup = async (data) => {
  return await api.put("/api/call_centers/outbound-did", data);
};
export const deleteOutboundGroup = async (id) => {
  return await api.delete("/api/call_centers/outbound-did", { data: { id } });
};
export const getOutboundGroupDrd = async () => {
  return await api.get(`/api/call_centers/outbound-did`);
};

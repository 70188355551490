import React, { useState, useEffect } from "react";
import { DotsLoader } from "../../../components";
import { toast } from "react-toastify";
import { FaRegCreditCard, FaEye, FaEyeSlash } from "react-icons/fa";
import CustomModal from "../../../components/molecules/Modal/customModal";
import { getFullCardNumber } from "../../../services/jobs";

const CardDetailModal = ({ onCancelForm, modalTitle, selectedId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  // Helper function to convert hex string to ArrayBuffer
  function hexStringToBuffer(hex) {
    const length = hex.length / 2;
    const buffer = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      buffer[i] = parseInt(hex.substr(i * 2, 2), 16);
    }
    return buffer.buffer;
  }

  // Import AES key from a hex string
  async function importKey(hexKey) {
    const keyBuffer = hexStringToBuffer(hexKey);
    return await crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "AES-CBC" },
      false,
      ["decrypt"]
    );
  }

  async function decryptCardDetails(encryptedData, ivHex, keyHex) {
    const iv = hexStringToBuffer(ivHex);
    const key = await importKey(keyHex);

    // Convert encrypted data from hex to ArrayBuffer
    const encryptedArray = hexStringToBuffer(encryptedData);

    try {
      const decryptedData = await crypto.subtle.decrypt(
        { name: "AES-CBC", iv: iv },
        key,
        encryptedArray
      );

      const decoder = new TextDecoder();
      const decryptedString = decoder.decode(decryptedData);

      return JSON.parse(decryptedString);
    } catch (error) {
      console.error("Decryption failed:", error);
      throw new Error("Decryption failed!");
    }
  }

  const fetchFullCardNumber = async () => {
    if (!selectedId) return;

    const keyHex =
      "6a72f56d832d1c6b9e7a4d5804d0e5a93d72a57e29d8a68b1c7d2e4a6d934b9d";
    setIsLoading(true);

    try {
      const response = await getFullCardNumber(selectedId);

      if (response.status !== 200 && response.status !== 201) {
        console.error("Failed to fetch data", response);
        toast.error("Failed to fetch card details.");
        return;
      }

      const { iv, encryptedData } = response.data?.encryptedCardInfo || {};

      if (!iv || !encryptedData) {
        console.error("Missing data: iv or encryptedData not found");
        toast.error("Missing card data.");
        return;
      }

      const decryptedDetails = await decryptCardDetails(
        encryptedData,
        iv,
        keyHex
      );
      setCardInfo(decryptedDetails);
    } catch (error) {
      console.error("Error fetching full card number:", error);
      toast.error("Error fetching card details.");
      setCardInfo(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      fetchFullCardNumber();
    }
    // eslint-disable-next-line
  }, [selectedId]);

  const breakIntoChunks = (number, chunkSize) => {
    const regex = new RegExp(`.{1,${chunkSize}}`, "g");
    return number.match(regex);
  };

  const chunks = cardInfo?.cardNumber
    ? breakIntoChunks(cardInfo.cardNumber, 4)
    : [];

  const convertToDate = (number) => {
    if (!number) return "Invalid Date";

    const str = number.toString();
    const year = str.substring(0, 4);
    const month = str.substring(4, 6);
    const lastTwoDigitsYear = year.substring(2, 4);
    return `${month}/${lastTwoDigitsYear}`;
  };

  return (
    <CustomModal
      title={modalTitle}
      handleModal={onCancelForm}
      className="max-w-md"
      hideHeader
    >
      {isLoading && <DotsLoader />}

      <div className="flex justify-center items-center p-0 mt-0">
        <div className="flex flex-col justify-center items-center mt-8">
          <div className="relative bg-gray-900 text-gray-400 p-8 rounded-lg shadow-xl w-[350px] h-[200px] flex flex-col transition-all ease-in-out duration-500 hover:scale-110">
            <div className="flex justify-between items-center font-bold text-xl z-10">
              <div>
                <FaRegCreditCard className="text-white w-8 h-8" />
              </div>
              <div>Virtual Credit Card</div>
            </div>
            {!isDetailsVisible ? (
              <div className="flex justify-between items-center font-semibold text-md mt-auto mb-1 z-10 text-gray-300">
                {chunks.map((chunk, index) => (
                  <div key={index}>{chunk}</div>
                ))}
              </div>
            ) : (
              <div className="flex justify-between items-center font-semibold text-md mt-auto mb-1 z-10 text-gray-300">
                <div>XXXX</div>
                <div>XXXX</div>
                <div>XXXX</div>
                <div>XXXX</div>
              </div>
            )}

            <div className="flex justify-between items-center font-semibold text-md z-10 mt-2">
              <div className="text-sm">
                CVV: {isDetailsVisible ? cardInfo?.cvv : "XXX"}
              </div>
              <div className="text-sm">
                Exp:
                <span className="text-gray-300 text-sm ml-1">
                  {isDetailsVisible
                    ? convertToDate(cardInfo?.expirationDate)
                    : "XX/XX"}
                </span>
              </div>
            </div>

            {cardInfo?.expirationDate && cardInfo?.cvv ? (
              <div
                onClick={() => setIsDetailsVisible(!isDetailsVisible)}
                className="cursor-pointer ml-auto mt-3"
              >
                {isDetailsVisible ? (
                  <FaEyeSlash className="text-white" />
                ) : (
                  <FaEye className="text-white" />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default CardDetailModal;

import React from "react";
import { FaPhone } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FormInput,
  FormSelectInput,
  FormTextArea,
} from "../../../../components";
import FormRadioGroup from "../../../../components/molecules/FormRadioGroup";
import { call_by_link } from "../../../../features/dialerSlice";
import { job_call_logs } from "../../../../features/jobsSlice";
import { sendIframeMsg } from "../../../../util/iFrame";
import DynamicComponent from "../../../Lead/Components";
import DynamicComponent2 from "../../../LeadBeta/Components/index";

function SummarySection(props) {
  const dispatch = useDispatch();
  const dialer = useSelector((state) => state.dialer);
  const { formik, jobTypes, errors } = props;
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [vicidialId, setVicidialId] = React.useState(null);
  const [type, setType] = React.useState(null);
  const receiveMessage = (event) => {
    if (event?.data?.action === "vendor_id_confirm") {
      if (document.visibilityState === "visible") {
        setVicidialId(event?.data?.vicidial_id);
      }
    }
  };

  // Commented code start
  // const handleVisibilityChange = () => {
  //   if (document.visibilityState === "visible") {
  //     window.addEventListener("message", receiveMessage);
  //   } else {
  //     window.removeEventListener("message", receiveMessage);
  //   }
  // };

  // Attach visibility change event listener
  // document.addEventListener("visibilitychange", handleVisibilityChange);

  // Call the function initially if the tab is already in focus
  // if (document.visibilityState === "visible") {
  //   window.addEventListener("message", receiveMessage);
  // }
  // Commented code End

  window.addEventListener("message", receiveMessage);
  React.useEffect(() => {
    if (vicidialId && formik.values.id && type) {
      dispatch(
        job_call_logs({
          lead_id: formik.values.id,
          type: type,
          vicidial_id: vicidialId,
          call_type: "Outgoing",
        })
      );
      setType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vicidialId]);

  const callOn = async (number) => {
    if (dialer?.status === "PAUSE") {
      try {
        const res = await dispatch(call_by_link(number));
        if (res?.payload?.status === 200) {
          toast.success("Call Successfully");
          setTimeout(() => {
            setType("Client");
            sendIframeMsg({
              action: "send_vendor_lead_code",
              user: user?.dialer_data?.dialer_user,
              pass: user?.dialer_data?.dialer_pass,
              vendor_lead_code: formik?.values?.lead_num,
            });
          }, 5000); // 5 seconds timeout
        }
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:68 ~ callOn ~ err:", err);
      }
    } else if (dialer?.status === "") {
      toast.error("Please login to dialer");
    } else {
      toast.error("Please Pasue your dialer before call");
    }
  };
  return (
    <div className="px-2">
      <div className="bg-white border px-2 md:px-4 rounded">
        <div className="pl-2 pt-3 !mb-2">
          <h5 className="">Summary</h5>
        </div>
        <div className="grid md:grid-cols-4">
          <div className="px-2 py-0 mb-3">
            <FormInput
              errors={errors}
              name="name"
              label="Client Name"
              formik={formik}
              type="text"
            />
          </div>

          <div className="px-2 py-0 mb-3">
            <FormInput
              errors={errors}
              name="email"
              label="Email"
              formik={formik}
              type="email"
            />
          </div>
          <div className="px-2 py-0 mb-3 flex flex-row items-center">
            <PhoneInput
              defaultCountry="US"
              className={[
                " appearance-none border border-[#a9a9a9] rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
                `rounded-l-none`,
                formik.errors.phone && formik.touched.phone ? "invalid" : "",
              ].join(" ")}
              onChange={(e) => formik.setFieldValue("phone", e)}
              value={formik.values.phone || ""}
              placeholder={"Phone"}
              initialValueFormat="national"
            />
            <i className="cursor-pointer">
              <FaPhone
                color={"#000"}
                className="mb-1 -ml-6"
                onClick={() => callOn(formik.values?.phone)}
              />
            </i>
          </div>
          <div className="px-2 py-0 mb-3 flex flex-row items-center">
            <PhoneInput
              defaultCountry="US"
              className={[
                " appearance-none border border-[#a9a9a9] rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
                `rounded-l-none`,
                formik.errors.alternate_phone && formik.touched.alternate_phone
                  ? "invalid"
                  : "",
              ].join(" ")}
              onChange={(e) => formik.setFieldValue("alternate_phone", e)}
              value={formik.values.alternate_phone || ""}
              placeholder={"Alternate Phone"}
              initialValueFormat="national"
            />
            <i className="cursor-pointer">
              <FaPhone
                color={"#000"}
                className="mb-1 -ml-6"
                onClick={() => callOn(formik.values?.alternate_phone)}
              />
            </i>
          </div>
          {formik?.values?.industry_id?.name === "Towing" && (
            <div className="px-2 py-0 mb-3">
              <FormSelectInput
                errors={errors}
                name="job_category_id"
                options={jobTypes?.record?.records}
                label="Jobs Category"
                formik={formik}
                type="text"
                labelProp="name"
                valueProp="_id"
              />
            </div>
          )}

          <div className="px-2 py-0 relative">
            <FormRadioGroup
              errors={errors}
              name="payment_type"
              label="Payment Type"
              labelProp="label"
              valueProp="value"
              options={[
                { label: "C.O.D", value: "COD" },
                { label: "Insurance", value: "Insurance" },
              ]}
              formik={formik}
              type="text"
              isHorizontal
              isBorder
              isFloat
            />
          </div>
        </div>
        {!formik?.values?.extra_fields ? (
          <div className="md:grid md:grid-cols-4 mt-3 md:mt-0">
            {formik?.values?.industry_id?.name === "Towing" ? (
              <>
                <div className="px-2 py-0 mb-3">
                  <FormInput
                    errors={errors}
                    name="car_year"
                    label="Car Year"
                    formik={formik}
                    type="text"
                    value={
                      formik?.values?.car_year ||
                      formik?.values?.extra_fields?.car_year ||
                      ""
                    }
                  />
                </div>

                <div className="px-2 py-0 mb-3">
                  <FormInput
                    errors={errors}
                    name="car_make"
                    label="Car Make"
                    formik={formik}
                    type="text"
                    value={
                      formik?.values?.car_make ||
                      formik?.values?.extra_fields?.car_make ||
                      ""
                    }
                  />
                </div>

                <div className="px-2 py-0 mb-3">
                  <FormInput
                    errors={errors}
                    name="car_model"
                    label="Car Model"
                    formik={formik}
                    type="text"
                    value={
                      formik?.values?.car_model ||
                      formik?.values?.extra_fields?.car_model ||
                      ""
                    }
                  />
                </div>
                <div className="px-2 py-0 mb-3 relative">
                  <FormRadioGroup
                    errors={errors}
                    name="is_neutral"
                    label="Neutral"
                    labelProp="label"
                    valueProp="value"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    // eslint-disable-next-line
                    convertor={(value) => value == "true"}
                    formik={formik}
                    type="text"
                    isHorizontal
                    isBorder
                    isFloat
                    isCheck={
                      formik?.values?.is_neutral ||
                      formik?.values?.extra_fields?.is_neutral ||
                      false
                    }
                  />
                </div>
              </>
            ) : null}
            <div className="px-2 py-0 col-span-4 mb-3">
              <FormTextArea
                label="Special Instruction"
                rows={5}
                name="special_instruction"
                formik={formik}
                type="text"
              />
            </div>
          </div>
        ) : formik?.values?.industry_id?.name &&
          formik?.values?.industry_id?.name === "Towing" ? (
          <>
            <DynamicComponent
              formik={formik}
              serviceName={
                jobTypes?.record?.records?.find(
                  ({ _id }) => _id === formik.values.job_category_id
                )?.name
              }
              errors={errors}
            />
            <div className="px-2 py-0 col-span-4 mb-3">
              <FormTextArea
                label="Special Instruction"
                rows={5}
                name="special_instruction"
                formik={formik}
                type="text"
              />
            </div>
          </>
        ) : (
          <div className="my-2">
          <DynamicComponent2
            formik={formik}
            industryName={formik?.values?.industry_id?.name}
            setIsLoader={() => {}}
          />
          </div>
        )}
      </div>
    </div>
  );
}

export default SummarySection;

import React, { useState } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import InputMask from "react-input-mask";
import * as Yup from "yup";
function AddOutboundForm({
  onCancelForm,
  modalTitle,
  onFormSubmit,
  selectedData,
}) {
  const [isLoader, setIsLoader] = React.useState(false);
  const [sections, setSections] = useState({
    did_pattern: selectedData?.did_pattern ?? "XXXXXXXXXX",
  });
  const msg = selectedData?.id ? "updated" : "created";

  const handleInputChange = (field, value) => {
    setSections((prevSections) => ({
      ...prevSections,
      [field]: value,
    }));
  };

  const validationSchema = Yup.object({
    provider: Yup.string().required("Provider is required"),
  });
  const formik = useFormik({
    initialValues: {
      provider: selectedData?.provider ?? "",
      did_type: selectedData?.did_type || "",
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsLoader(true);
      try {
        const url = selectedData?.id
          ? `/api/call_centers/outbound-did/${selectedData?.id}`
          : "/api/call_centers/outbound-did";
        const res = selectedData?.id
          ? await api.put(url, {
              did_pattern: sections.did_pattern,
              ...values,
            })
          : await api.post(url, {
              did_pattern: sections.did_pattern,
              ...values,
            });
        if ((res.status === 200 || res.status === 201) && res?.data?.status) {
          toast.success(`Outbound DIDs ${msg} successfully.`);
          onFormSubmit();
        } else {
          toast.error(`Outbound DID couldn't be ${msg} !`);
        }
      } catch (err) {
        if (err?.response?.data?.error) {
          toast.error(err?.response?.data?.error);
        } else {
          toast.error(err?.response?.data);
          return;
        }
      }
      setIsLoader(false);
    },
  });

  return (
    <Modal
      title={modalTitle}
      onSubmit={formik.handleSubmit}
      onClick={onCancelForm}
      className="max-w-5xl"
      modalType={false}
      formIsValid={formik.isValid}
      isUpdate={!!selectedData}
      onCancelModal={onCancelForm}
      // isLoading={isLoading}
    >
      {isLoader ? <DotsLoader /> : null}
      <div className="mb-0 p-2 mt-3">
        <div className="flex items-center gap-x-4 border-b mb-4 grid grid-cols-2">
          <div className="w-full">
            <InputMask
              mask="XXXXXXXXXX"
              value={sections.did_pattern}
              onChange={(e) => handleInputChange("did_pattern", e.target.value)}
              maskChar="X"
              formatChars={{
                X: "[0-9]",
              }}
            >
              {(inputProps) => (
                <FormInput
                  {...inputProps}
                  name="did_pattern"
                  label="DID Pattern"
                  className="text-xs"
                />
              )}
            </InputMask>
          </div>
          <div className="">
            <FormSelectInput
              name="provider"
              label="Carrier"
              formik={formik}
              options={[
                { value: "Intel", label: "Intel" },
                { value: "BVS", label: "BVS" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className="mt-3">
            <FormSelectInput
              name="did_type"
              label="Usage"
              formik={formik}
              options={[
                { value: "Local Presence", label: "Local Presence" },
                { value: "Private DID", label: "Private DID" },
                { value: "Campaign", label: "Campaign" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddOutboundForm;


import api from "./api";

export const getJobCategory = async (data) => {
  // const { user } = store.getState().auth;
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const isNewForm =
    user?.company_name === "Towing Wiz" || user?.company_name === "call81";
  const params = isNewForm ? { ...data, ...{ get_new_records_also: 1 } } : data;
  return await api.get(`/api/job_categories`, { params });
};
export const createJobCategory = async (data) => {
  return await api.post(`/api/job_categories`, data);
};
export const updateJobCategory = async (data) => {
  return await api.put(`/api/job_categories`, data);
};
export const deleteJobCategory = async (id) => {
  return await api.delete(`/api/job_categories/${id}`);
};

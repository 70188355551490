import { MultiSelect } from "primereact/multiselect";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, DotsLoader } from "../../../components";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { create_batch } from "../../../features/domainCenterSlice";
import { get_tld } from "../../../features/TldSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
const BatchSaveModal = (props) => {
  const {
    industry_id,
    search_details,
    locations,
    onCancelForm,
    title,
    country_id,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, record } = useSelector((state) => state.tld);

  const [keywords, setKeywords] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    dispatch(get_tld());
  }, [dispatch]);

  const validationSchema = Yup.object({
    tlds: Yup.array()
      .min(1, "At least one TLD is required")
      .required("TLDs are required"),
    keywords: Yup.array()
      .min(1, "At least one keyword is required")
      .required("Keywords are required")
      .test("not-empty", "Each keyword should not be empty", (value) => {
        return (
          value &&
          value.length > 0 &&
          value.every((keyword) => keyword.trim().length > 0)
        );
      }),
    note: Yup.string().required("Note is required"),
  });

  const formik = useFormik({
    initialValues: {
      industry_id,
      country_id,
      search_details,
      locations,
      tlds: [],
      keywords: [],
      note: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.note !== "") {
        try {
          let payload = {
            ...values,
            keywords: keywords,
          };
          setIsLoader(true);
          const res = await dispatch(create_batch(payload));
          if (res?.payload?.status === 200 || res?.payload?.status === 201) {
            onCancelForm();
            setIsLoader(false);
            toast.success("Batch created successfully.");
            navigate(`/domain_center/batch_report/${res.payload?.data?._id}`);
          } else {
            toast.error("Couldn't be added");
          }
          setIsLoader(false);
        } catch (err) {
          setIsLoader(false);
          console.error("🚀 ~ file: saveBatch.jsx:62 ~ handleSave ~ err:", err);
          toast.error("Couldn't be added");
        }
      } else {
        toast.error("Note is required");
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("keywords", keywords);
    // eslint-disable-next-line
  }, [keywords]);

  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      {isLoading || isLoader ? <DotsLoader /> : null}
      <div className="relative">
        <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
          <button
            onClick={onCancelForm}
            className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="z-10 w-auto w-full md:max-w-[1024px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white">
          <div className="pt-0 max-w-[994px] h-full px-3.5 m-auto">
            <header className="bg-primary-100 h-[60px] flex items-center justify-between shadow-lg">
              <h1 className=" text-2xl text-white m-4 font-medium">{title}</h1>
              <h1 className=" text-2xl text-white m-4 font-medium">
                {`Selected Records: ${
                  formik.values?.batch_for_all
                    ? props?.totalItems
                    : locations?.length
                }`}
              </h1>
            </header>
            <div className="md:p-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-3 gap-x-2 items-center mb-2">
                  <div className="span-col-1">
                    <label className="inputLabel" htmlFor="tlds">
                      {"TLDs"}
                    </label>
                    <MultiSelect
                      placeholder="Select TLDs"
                      options={record}
                      optionLabel="name"
                      optionValue="name"
                      filter
                      maxSelectedLabels={2}
                      value={formik.values.tlds}
                      onChange={(e) => formik.setFieldValue("tlds", e.value)}
                      className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                    />
                    {formik.touched.tlds && formik.errors.tlds && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.tlds}
                      </div>
                    )}
                  </div>
                  <div className="span-col-1 ">
                    <label className="inputLabel" htmlFor="note">
                      {"Note"}
                    </label>
                    <input
                      className="form-control"
                      placeholder="Note"
                      {...formik.getFieldProps("note")}
                    />
                    {formik.touched.note && formik.errors.note && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.note}
                      </div>
                    )}
                  </div>
                </div>
                <TagsInput
                  tags={keywords}
                  setTags={setKeywords}
                  placeholder="Add keywords"
                />
                {formik.touched.keywords && formik.errors.keywords && (
                  <div className="text-red-500 text-sm">
                    {formik.errors.keywords}
                  </div>
                )}

                <div className="!p-4 text-right">
                  <Button
                    text="Cancel"
                    className="mx-2"
                    onClick={onCancelForm}
                    variant="btn_cancel"
                  />
                  <Button
                    text={"Submit"}
                    type="submit"
                    className=""
                    isLoading={isLoading || isLoader}
                    variant="btn_submit"
                    disabled={formik.isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BatchSaveModal;

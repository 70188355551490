import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";

function SchedulerSection({ formik }) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="border border-[#F0F0F0] p-3">
          <p className="font-semibold text-sm mb-2">Date And Time Schedule</p>
          <div className="flex items-center">
            <div className="mr-2">
              <p className="font-semibold text-xs text-gray-500 mb-1">Start Date</p>
              <DateTimePicker
                value={formik?.values?.scheduled_on}
                onChange={(val) => {
                  formik?.setFieldValue("scheduled_on", val?.toString());
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="" />
                )}
              />
            </div>
            <div className="">
              <p className="font-semibold text-xs text-gray-500 mb-1">End Date</p>
              <DateTimePicker
                value={formik?.values?.scheduled_end}
                onChange={(val) => {
                  formik?.setFieldValue("scheduled_end", val?.toString());
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="" />
                )}
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
}

export default SchedulerSection;

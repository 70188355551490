import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_geo_vendors_new_drd } from "../../features/GeoVendorsSlice";
import { get_geo_industry_drd } from "../../features/GeoIndustrySlice";
import { useNavigate } from "react-router-dom";
import { get_vendor_location_report } from "../../features/geoLocSlice";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
const VendorLocationsReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, vendorLocation } = useSelector((state) => state.geoLoc);
  const { industryDrd } = useSelector((state) => state.geoIndustry);
  const vendors = useSelector((state) => state.geoVendors);
  const [searchText, setSearchText] = useState("");
  const [vendorType, setVendorType] = useState("Own");
  const [filterData, setfilterData] = useState({
    industry_ids: [],
    vendor_ids: [],
  });
  useEffect(() => {
    dispatch(get_vendor_location_report());
    dispatch(get_geo_industry_drd());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(get_geo_vendors_new_drd({ vendor_type: vendorType }));
    // eslint-disable-next-line
  }, [vendorType]);
  const logs = vendorLocation?.filter(
    (log) =>
      log?.industry_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      log?.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      log?.last_name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Tech Name", field: "name", flex: 1, minWidth: 150 },
    { headerName: "Industry", field: "industry_name", flex: 1, minWidth: 150 },
    {
      headerName: "Total Population",
      field: "dc_total_population",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value.toLocaleString("en-US");
      },
    },
    {
      headerName: "Total Cities",
      field: "dc_total_cities",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Total State",
      field: "dc_total_states",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Total Zips",
      field: "dc_total_zips",
      flex: 1,
      minWidth: 100,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      <PageHeader
        route="Setting > Vendors"
        heading="Geo Location Listing"
        onClick={() => navigate("/geolocation/locations")}
        isAllowed={userPermission("Geo Location")}
      />

      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="flex flex-row items-center">
          <div className="w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Industries"}
            </label>
            <MultiSelect
              placeholder="Select Industry"
              options={industryDrd}
              optionLabel="name"
              optionValue="_id"
              filter
              maxSelectedLabels={2}
              value={filterData?.industry_ids}
              onChange={(e) => {
                setfilterData({ ...filterData, industry_ids: e.value });
              }}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="w-full ml-2">
            <label className="inputLabel" htmlFor="vendors">
              {"Vendors Type"}
            </label>
            <Dropdown
              value={vendorType}
              onChange={(e) => {
                setVendorType(e.value);
              }}
              options={[
                { label: "All", value: "All" },
                { label: "Global", value: "Global" },
                { label: "Own", value: "Own" },
              ]}
              optionLabel="label"
              placeholder="Select Vendor Type"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="w-full ml-2">
            <label className="inputLabel" htmlFor="vendors">
              {"Vendors"}
            </label>
            <MultiSelect
              placeholder="Select Vendors"
              options={vendors?.geoVendorDrd}
              optionLabel={(option) =>
                `${option?.first_name ? option?.first_name + " | " : ""} ${
                  option?.last_name ? option?.last_name + " | " : ""
                } ${option?.phone ? " | " + option?.phone : ""}`
              }
              optionValue="_id"
              filter
              maxSelectedLabels={2}
              value={filterData?.vendor_ids}
              onChange={(e) => {
                setfilterData({ ...filterData, vendor_ids: e.value });
              }}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
        </div>

        <div className="col-span-12 md:col-span-3 flex justify-center !mt-2">
          <div className="float-right">
            <Button
              text={"Search"}
              className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
              onClick={() => dispatch(get_vendor_location_report(filterData))}
            />
            <Button
              text="Reset"
              className="py-1.5 px-3 align-middle bg-menu text-white"
              onClick={() => {
                setfilterData({ industry_ids: [], vendor_ids: [] });
                dispatch(get_vendor_location_report());
              }}
            />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={logs?.map((record, index) => {
            let counter = index + 1;
            const {
              industry_name,
              first_name,
              last_name,
              dc_total_states,
              dc_total_cities,
              dc_total_zips,
              dc_total_population,
            } = record;
            const name = `${first_name ? first_name : ""} ${
              last_name ? last_name : ""
            }`;
            return {
              counter,
              records: { ...record, _id: index + 1 },
              name,
              industry_name,
              dc_total_cities,
              dc_total_zips,
              dc_total_states,
              dc_total_population,
            };
          })}
          // onRowSelectionModelChange={handleRowSelection}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={vendorLocation?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode={"client"}
          // checkboxSelection={true}
          // ref={gridRef}
        />
      </div>
    </>
  );
};

export default VendorLocationsReport;

import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import {
  createLeadCampaign,
  updateLeadCampaign,
} from "../../../services/leadCampaign";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  status: Yup.string().required("Status is required"),
});

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle, reloadListing }) => {
  let initialValues = {
    title: "",
    status: "active",
  };

  const [isLoading, setIsloading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  if (editingRecord) {
    const { _id, title, status } = editingRecord;
    initialValues = { id: _id, title, status };
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (isLoading) {
        toast.error("Please wait until the previous action is completed");
        return;
      }
      setIsloading(true);

      const handleApiCall = async (payload, isEdit) => {
        try {
          const res = isEdit
            ? await updateLeadCampaign(payload)
            : await createLeadCampaign(payload);
          if (res?.status === 200) {
            toast.success(
              isEdit
                ? "Lead campaign updated successfully."
                : "Lead campaign created successfully."
            );
            reloadListing();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Lead campaign",
              msg: isEdit ? "updated" : "created",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "Lead campaign",
            msg: isEdit ? "updated" : "created",
          });
        }
      };

      const { active, ...payload } = values;
      const isEdit = Boolean(editingRecord);

      await handleApiCall(payload, isEdit);

      setIsloading(false);
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid && formik.dirty}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="w-full grid mt-4 md:grid-cols-2 gap-x-2">
          <div className="mb-3">
            <FormInput
              name="title"
              label="Title"
              formik={formik}
              errors={errors}
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              name="status"
              label="Status"
              formik={formik}
              errors={errors}
              options={[
                { label: "Active", value: "active" },
                { label: "InActive", value: "inactive" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DotsLoader } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../../components/molecules/PageHeader";
import AddOutboundForm from "./AddUpdateForm";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  get_outbound_group,
  update_outbound,
} from "../../../../features/outboundGroupSlice";
import Swal from "sweetalert2";
import { utils, writeFile } from "xlsx";
import ImportDIDsForm from "./ImportOutboundDIDsForm";
function OutboundDIDs() {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const { isLoading, outboundDrd } = useSelector((state) => state?.outbound);
  const [isLoader, setIsLoading] = React.useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [isImportForm, setIsImportForm] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  useEffect(() => {
    dispatch(
      get_outbound_group({
        page: paginationModel?.page,
        size: paginationModel?.pageSize,
        sqlwhere: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFormHandler = (record) => () => {
    setSelectedData({});
    setIsEditing(true);
  };

  const openImportFormHandler = () => {
    setIsImportForm(true);
  };
  const handleEdit = (record) => {
    setIsEditing(true);
    setSelectedData(record);
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_outbound_group({
        sqlwhere: "",
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure to perform this action?",
      text: "Outbound DIDs will be deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: "w-90 p-4 text-sm",
        title: "text-lg font-bold",
        content: "text-sm font-semibold",
        confirmButton: "text-sm py-2 px-4",
        cancelButton: "text-sm py-2 px-4",
      },
    }).then(async (result) => {
      if (!result.isConfirmed) return;
      setIsLoading(true);
      try {
        const res = await api.delete(`/api/call_centers/outbound-did/${id}`);
        if (res?.status === 200 || res?.status === 201) {
          toast.success("Deleted Outbound DIDs Successfully");
          const updatedOutboundDrd = outboundDrd.data.filter(
            (item) => item.id !== id
          );
          dispatch(update_outbound(updatedOutboundDrd));
        } else {
          toast.error("Failed to delete Outbound DIDs");
        }
        setIsLoading(false);
      } catch (err) {
        toast.error("An error occurred while deleting Outbound DIDs");
        console.error(err);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    });
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 10 },
    {
      headerName: "DID Pattern",
      field: "did_pattern",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Carrier",
      field: "provider",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Campaign",
      field: "campaign_id",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Usage",
      field: "did_type",
      flex: 1,
      minWidth: 120,
      filterable: false,
    },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
      minWidth: 120,
      filterable: false,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <FaEdit
              className="h-4 w-4 mr-2 cursor-pointer"
              color="error"
              onClick={() => handleEdit(params?.row?.records)}
            />
            <FaTrashAlt
              className="h-4 w-4 cursor-pointer"
              color="error"
              onClick={() => handleDelete(params?.row?.records?.id)}
            />
          </>
        );
      },
    },
  ];

  const onFormSubmit = () => {
    setIsEditing(false);
    dispatch(
      get_outbound_group({
        page: paginationModel?.page,
        size: paginationModel?.pageSize,
        sqlwhere: "",
      })
    );
  };

  const cancelFormHandler = () => {
    setIsEditing(false);
    setIsImportForm(false);
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const data = outboundDrd?.data;
      const rows = [["#", "DID Pattern", "Provider", "Created At"]];
      data.forEach((item, index) => {
        const { did_pattern, provider, created_date } = item;
        let created_at = dayjs(created_date).format("ddd, MMM D, YYYY h:mm A");
        const counter = index + 1;
        rows.push([counter, did_pattern, provider, created_at]); // Add a row without domains
      });
      const workbook = utils.book_new();
      const sheetName = "Outbound DIDs list";
      const worksheet = utils.aoa_to_sheet(rows);
      utils.book_append_sheet(workbook, worksheet, sheetName);

      writeFile(workbook, "outbound_did_list.xlsx");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ handleExport ~ err:", err);
    }
  };

  return (
    <>
      {isLoading || (isLoader && <DotsLoader />)}
      <PageHeader
        heading="Outbound DIDs"
        isAllowed={true}
        onClick={openFormHandler(0)}
        extraButton={true}
        additionalBtnTitle="Import Outbound DIDs"
        iconClassName=" w-5 h-5"
        additionalBtnAllow={true}
        extraButtonClick={openImportFormHandler}
      />

      {isEditing && (
        <AddOutboundForm
          modalTitle="Outbound DIDs"
          selectedData={selectedData}
          onCancelForm={cancelFormHandler}
          onFormSubmit={onFormSubmit}
        />
      )}

      {isImportForm && (
        <ImportDIDsForm
          modalTitle="Import Outbound DIDs"
          onCancelForm={cancelFormHandler}
          onSave={() =>
            dispatch(
              get_outbound_group({
                page: paginationModel?.page,
                size: paginationModel?.pageSize,
                sqlwhere: "",
              })
            )
          }
        />
      )}

      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={outboundDrd?.data?.map((item, index) => {
            let counter = index + offset + 1;
            let created_at = dayjs(item?.created_date).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            const { did_pattern, provider, campaign_id, did_type } = item;
            return {
              records: { ...item },
              counter,
              did_pattern,
              provider,
              created_at,
              campaign_id,
              did_type,
            };
          })}
          searchable="No"
          totalItems={outboundDrd?.totalItems}
          isLoading={isLoading}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          filterMode="client"
          paginationModel={paginationModel}
          exportButton={handleExport}
        />
      </div>
    </>
  );
}

export default OutboundDIDs;

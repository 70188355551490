import React from "react";
import { getFormattedDate } from "../../util/common";

const SummaryModal = ({ isOpen, onClose, formik }) => {
  const {
    job_number = "",
    name = "",
    industry_id = null,
    extra_fields = {},
    phone,
    complete_pickup_address,
    call_payload,
    scheduled_end,
    scheduled_on,
    call_payload_domain,
  } = formik?.values || {};
  const {
    job_type,
    occupant_type = "",
    property_type = "",
  } = extra_fields || {};
  const formatValue = (val) => {
    if (typeof val !== "boolean" && !val) return null;
    if (typeof val === "boolean") {
      return val ? "Yes" : "No";
    }
    if (val && !val.includes("_")) return val;
    return val
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const renderItem = (label, value) => (
    <div className="flex gap-x-1 text-sm">
      <label>{label}:</label>
      <span>{formatValue(value)}</span>
    </div>
  );
  return (
    <div
      className={`fixed inset-0 z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div
        className="bg-black opacity-50 absolute inset-0"
        onClick={onClose}
      ></div>
      <div className="bg-white w-full md:w-96 lg:w-1/3 h-full shadow-lg absolute right-0">
        <span
          className="absolute top-2 right-2 cursor-pointer"
          onClick={onClose}
        >
          &times;
        </span>
        <h2 className="p-4 text-lg font-bold">Summary</h2>
        <div className="flex w-full items-start justify-between">
          <div>
            <div className=" !p-3">
              {/* <p className="text-[16px] font-bold">Whats App Format</p> */}
              <p className="text-base">{`Hi, New job ${
                job_number ? `${job_number}` : ``
              } - ${name || ""}`}</p>
              <div className="pl-2 pt-2">
                {renderItem("Industry", industry_id?.name)}
                {renderItem("Name", name)}
                {renderItem("Phone", phone)}
                {renderItem(
                  "Appointment",
                  `${getFormattedDate(scheduled_on)} - ${getFormattedDate(
                    scheduled_end
                  )}`
                )}
                {renderItem("Property Type", property_type)}
                {renderItem("Property Occupancy", occupant_type)}
                {renderItem("Job Type", job_type)}
                {renderItem("Address", complete_pickup_address)}
                {renderItem("Site Name", call_payload_domain)}

                <p className="text-[13px] pt-2">Additional notes:</p>
                <p className="text-[13px]">
                  {formik.values?.extra_fields?.tech_note || ""}
                </p>
                <p className="text-[13px] pt-2">Company Name:</p>
                <p className="text-[13px]">
                  {call_payload ? call_payload[0]?.did_group_id : ""}
                </p>
                <p className="text-[13px]"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;

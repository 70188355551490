import React, { useState } from "react";
import { sendMessageForMerchant } from "../../services/SmsCenterService";
import Button from "../../components/atoms/Button";
import { MdAttachFile } from "react-icons/md";
import { FaFilePdf, FaFileWord, FaFileCode, FaFileAlt } from "react-icons/fa";
import api from "../../services/api";
import Loader from "../../components/atoms/Loader";
import { toast } from "react-toastify";
import { MdOutlineFileUpload } from "react-icons/md";
import { FormInput, FormTextArea } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
function MessageModal({ onClosemodal }) {
  const [filesData, setFilesData] = React.useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [fileSizeError, setFileSizeError] = useState("");
  const [fileCountError, setFileCountError] = useState("");
  const initialValues = {
    from_number: "",
    message: "",
    custom_phone: "",
  };
  React.useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClosemodal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onClosemodal]);

  const handleSubmit = async (values) => {
    const mediaUrls = filesData ? filesData.map((file) => file.path) : [];
    try {
      const res = await sendMessageForMerchant({
        message: values.message,
        user_id: "",
        from_number: values.from_number,
        custom_phone: values.custom_phone,
        ...(mediaUrls.length && { mediaUrls }),
        user_type: "Custom",
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Message sent successfully");
        onClosemodal();
      } else {
        console.error("Failed to send message: ", res.payload);
      }
    } catch (err) {
      console.error("Error occurred while sending message:", err);
    }
  };

  const validationSchema = Yup.object({
    from_number: Yup.string()
      .required("From Number is required")
      .matches(
        /^\+?\d{10,11}$/,
        "From Number must be between 10 and 11 digits"
      ),
    custom_phone: Yup.string()
      .required("To Number is required")
      .matches(/^\+?\d{10,11}$/, "To Number must be between 10 and 11 digits"),
    message: Yup.string()
      .required("Message is required")
      .min(1, "Message cannot be empty")
      .max(500, "Message cannot be longer than 500 characters"),
  });

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      let newFiles = [...selectedFiles];
      let sizeError = "";
      let countError = "";
      let duplicateError = "";

      if (newFiles.length + fileArray.length > 6) {
        countError = "You can only upload up to 6 files.";
      }

      const uploadedFileNames = new Set(
        [...newFiles, ...filesData].map((file) => file.name)
      );

      fileArray.forEach((file) => {
        if (uploadedFileNames.has(file.name)) {
          duplicateError = `File "${file.name}" has already been uploaded or selected. Please choose a different file.`;
        } else {
          if (file.size > 1024 * 1024) {
            sizeError = `File "${file.name}" exceeds 1 MB. Please choose a smaller file.`;
          } else {
            newFiles.push(file);
          }
        }
      });

      setFileSizeError(sizeError);
      setFileCountError(countError);
      setUploadError(duplicateError);

      if (!sizeError && !countError && !duplicateError) {
        setSelectedFiles(newFiles);
      }
    }
  };

  const removeFile = (index, event) => {
    event.preventDefault();
    event.stopPropagation();

    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);

    if (updatedFiles.length === 0) {
      setFileSizeError("");
      setFileCountError("");
      setUploadError(null);
    }
  };

  const uploadFiles = async (event) => {
    event.preventDefault();

    setUploading(true);
    setUploadError(null);
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    try {
      const response = await api.post("/api/sms/upload_mms_files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        const data = await response.data;
        setFilesData(data.files);
        setSelectedFiles([]);
        toast.success("Files uploaded successfully.");
      } else {
        toast.error("Failed to upload files. Please try again.");
        setUploadError("Failed to upload files. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to upload files. Please try again.");
      setUploadError("Failed to upload files. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const getFileIcon = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="preview"
          className="w-16 h-16 object-cover rounded-md"
        />
      );
    }

    // For PDFs
    if (file.name.endsWith(".pdf")) {
      return <FaFilePdf size={60} color="#E63946" />;
    }

    // For DOCX files
    if (file.name.endsWith(".docx")) {
      return <FaFileWord size={60} color="#4CAF50" />;
    }

    // For JSON files
    if (file.name.endsWith(".json")) {
      return <FaFileCode size={60} color="#FF5722" />;
    }

    // For other text files
    return <FaFileAlt size={60} color="#9E9E9E" />;
  };

  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2 text-center ">
          <div
            className={`inline-block bg-white w-2/4 my-0 mx-auto p-5 rounded max-h-[87vh] text-left overflow-y-auto`}
          >
            <form className="flex flex-col justify-between w-full bottom-0 bg-[#f8f8f8] p-3.7  ">
              <div className="mb-3 mt-2">
                <FormInput
                  name="from_number"
                  label="From Number"
                  formik={formik}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  name="custom_phone"
                  label="To Number"
                  formik={formik}
                />
              </div>
              <FormTextArea
                label="Enter Message"
                rows={5}
                name="message"
                formik={formik}
                type="text"
              />
              <div className="flex items-center justify-between space-x-4 mt-2">
                <label htmlFor="file-upload" className="cursor-pointer">
                  <div className="flex items-center relative space-x-2 p-2 px-6 text-white">
                    <MdAttachFile
                      size={24}
                      color="black"
                      title="Upload Files"
                      className="w-6 h-6 ml-2 mr-2 cursor-pointer"
                    />

                    {filesData?.length ? (
                      <span className="absolute top-0 right-0 text-xs text-white bg-red-500 rounded-full px-1 ml-1 mr-2">
                        {filesData?.length}
                      </span>
                    ) : null}
                  </div>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*,.pdf,.json,.docx,.txt"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                />
              </div>

              {(fileSizeError || fileCountError) && (
                <div className="flex items-center text-sm text-red-800 mt-2">
                  <div>
                    <span className="font-medium">
                      {fileSizeError && <p>{fileSizeError}</p>}
                      {fileCountError && <p>{fileCountError}</p>}
                    </span>
                  </div>
                </div>
              )}

              {uploading && (
                <div className="mt-4 text-center text-blue-500">
                  <Loader linear="linear" />
                  Uploading Files...
                </div>
              )}
              {uploadError ? (
                <div className="mt-4 text-center text-red-500">
                  {uploadError}
                </div>
              ) : null}

              {selectedFiles.length > 0 && (
                <div className="grid grid-cols-3 gap-4 mt-2 border-2 border-dotted p-2 rounded-md">
                  {selectedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="relative flex flex-col justify-center items-center border border-gray-300 p-2 rounded-md"
                    >
                      <div className="text-center flex flex-col justify-center items-center">
                        {getFileIcon(file)}
                        <p className="mt-2 text-xs text-gray-700 break-words w-[100px] px-1 py-1 text-center overflow-wrap break-word">
                          {file.name}
                        </p>
                      </div>

                      <button
                        onClick={(e) => removeFile(index, e)}
                        className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-full shadow-md hover:bg-red-600 transition h-6 w-6 flex justify-center items-center"
                      >
                        <span className="text-lg font-bold">&times;</span>
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {selectedFiles.length ? (
                <div className="flex justify-between items-center mt-2 text-sm text-gray-500">
                  <span>
                    {selectedFiles.length} file
                    {selectedFiles.length > 1 ? "s" : ""} selected
                  </span>

                  {selectedFiles.length > 0 && !uploading && (
                    <button
                      onClick={uploadFiles}
                      className="p-1 px-2 bg-primary-100 text-white rounded-sm shadow-md hover:bg-blue-600 transition"
                    >
                      <MdOutlineFileUpload size={20} color="black" />
                      <span className="text-sm font-medium mr-2 ml-1">
                        Upload Files
                      </span>
                    </button>
                  )}
                </div>
              ) : null}
            </form>
            <div className="text-center">
              <Button
                text="Cancel"
                variant="btn_cancel"
                className="ml-3 mt-2"
                onClick={onClosemodal}
              />
              <Button
                text="Send"
                variant="btn_submit"
                className="ml-3 mt-2"
                onClick={formik.handleSubmit}
                type="submit"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageModal;

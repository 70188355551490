import React from "react";
import { FormTextArea } from "../../../components";
import LocationSection from "../Common/Sections/LocationSection";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";
import TechNote from "../Common/Sections/TechNote";

const DryerventSection = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceMess,
  checkServiceDirect,
}) => {
  return (
    <div className="grid grid-cols-2 gap-3">
      <PropertyOccupancy formik={formik} fieldsToShow={[]} />
      <div>
        <LocationSection
          formik={formik}
          setIsLoader={setIsLoader}
          callSellLeadToServiceDirect={callSellLeadToServiceDirect}
          checkServiceMess={checkServiceMess}
          checkServiceDirect={checkServiceDirect}
        />
        <SchedulerSection formik={formik} />
      </div>

      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          What type of issue are you experiencing?
        </label>
        <FormRadioGroup
          id="extra_fields.issue"
          name="extra_fields.issue"
          labelProp="label"
          valueProp="value"
          options={[
            { value: "Clogged Dryer Vent", label: "Clogged Dryer Vent" },
            { value: "Yearly Maintenance", label: "Yearly Maintenance" },
          ]}
          isOnChange={(e) => {
            formik.setFieldValue("extra_fields.issue", e);
          }}
          isCheck={formik.values?.extra_fields?.issue || null}
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
      </div>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          Where is the vent located?
        </label>
        <FormRadioGroup
          id="extra_fields.located"
          name="extra_fields.located"
          labelProp="label"
          valueProp="value"
          options={[
            { value: "Interior exit", label: "Interior exit" },
            { value: "Exterior exit", label: "Exterior exit" },
          ]}
          isOnChange={(e) => {
            formik.setFieldValue("extra_fields.located", e);
          }}
          isCheck={formik.values?.extra_fields?.located || null}
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
      </div>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          Is the vent system accessible?
        </label>
        <FormRadioGroup
          id="extra_fields.accessible"
          name="extra_fields.accessible"
          labelProp="label"
          valueProp="value"
          options={[
            { value: true, label: "Yes (describe below)" },
            { value: false, label: "No" },
          ]}
          isOnChange={(e) => {
            const value = e === "true";
            formik.setFieldValue("extra_fields.accessible", value);
          }}
          isCheck={
            formik.values?.extra_fields?.accessible ||
            formik.values?.extra_fields?.accessible === false
              ? formik.values?.extra_fields?.accessible
              : null
          }
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
        {formik.values?.extra_fields?.accessible ? (
          <div className="mt-2">
            <FormTextArea
              rows={6}
              name="extra_fields.accessible_description"
              formik={formik}
              type="text"
              label="Description:"
            />
          </div>
        ) : null}
      </div>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          Have you noticed any visible damage?
        </label>
        <FormRadioGroup
          id="extra_fields.damage"
          name="extra_fields.damage"
          labelProp="label"
          valueProp="value"
          options={[
            { value: true, label: "Yes (describe below)" },
            { value: false, label: "No" },
          ]}
          isOnChange={(e) => {
            const value = e === "true";
            formik.setFieldValue("extra_fields.damage", value);
          }}
          isCheck={
            formik.values?.extra_fields?.damage ||
            formik.values?.extra_fields?.damage === false
              ? formik.values?.extra_fields?.damage
              : null
          }
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
        {formik.values?.extra_fields?.damage ? (
          <div className="mt-2">
            <FormTextArea
              rows={6}
              name="extra_fields.damage_description"
              formik={formik}
              type="text"
              label="Description:"
            />
          </div>
        ) : null}
      </div>
      <div className="col-span-2">
        <TechNote formik={formik} />
      </div>
    </div>
  );
};

export default DryerventSection;

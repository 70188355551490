import React from "react";
export default function FormCheckbox({
  labelText,
  checked,
  formik,
  name,
  value,
  onChange,
  disabled,
  className,
  rootClassName,
  id,
}) {
  return (
    <div className={`flex items-center !py-1.5 ${rootClassName}`}>
      <label
        className={`text-sm text-[#495057] cursor-pointer flex items-center`}
      >
        <input
          id={id}
          type="checkbox"
          name={name}
          className={`form-checkbox h-5 w-5 text-primary-100 !rounded-sm focus:ring-0 cursor-pointer mr-2 ${className}`}
          value={value ? value : ""}
          checked={checked || formik?.values[name]}
          onChange={
            onChange
              ? onChange
              : () => formik.setFieldValue(name, !formik.values[name])
          }
          disabled={disabled}
        />
        {labelText}
      </label>
    </div>
  );
}

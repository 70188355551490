import React from "react";
import TechNote from "../Common/Sections/TechNote";
import { FormTextArea } from "../../../components";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import LocationSection from "../Common/Sections/LocationSection";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";

const ChimneySweepingSection = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceDirect,
  checkServiceMess,
}) => {
  const jobTypes = [
    { label: "Wood Stove", value: "Wood Stove" },
    { label: "Gas Fireplace", value: "Gas Fireplace" },
    { label: "Pellet Stove", value: "Pellet Stove" },
    { label: "Furnace", value: "Furnace" },

    { label: "Chimney Sweep", value: "Chimney Sweep" },
    { label: "Wood Fireplace", value: "Wood Fireplace" },
  ];
  const isSelected = (value) => {
    if (formik?.values?.extra_fields?.job_type === value) return true;
    return false;
  };
  return (
    <div>
      <div className="grid grid-cols-2 gap-3">
        <PropertyOccupancy formik={formik} fieldsToShow={[]} />
        <div>
          <LocationSection
            setIsLoader={setIsLoader}
            formik={formik}
            callSellLeadToServiceDirect={callSellLeadToServiceDirect}
            checkServiceMess={checkServiceMess}
            checkServiceDirect={checkServiceDirect}
          />
          <SchedulerSection formik={formik} />
        </div>
        <div className="border border-[#F0F0F0] p-2 col-span-2">
          <label className="mb-2 font-semibold text-sm">Job Type</label>
          <div className="flex flex-col lg:flex-row gap-x-4 gap-y-2 border-b border-gray-200">
            <div className="w-full">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-2 !mb-4 mt-2 font-semibold">
                {jobTypes?.map(({ label, value }) => {
                  return (
                    <button
                      id="extra_fields.job_type"
                      type="button"
                      key={value}
                      className={`text-xs flex items-center border border-gray-300 p-1 hover:bg-client-100 hover:text-white transition-colors duration-200 h-[25px] cursor-pointer gap-x-1 ${
                        isSelected(value)
                          ? "bg-client-100 text-white"
                          : "bg-gray-200 text-gray-500"
                      }`}
                      onClick={() => {
                        formik.setFieldValue("extra_fields.job_type", value);
                      }}
                    >
                      {isSelected(value) ? (
                        <MdOutlineRadioButtonChecked size={18} />
                      ) : (
                        <MdOutlineRadioButtonUnchecked size={18} />
                      )}
                      {label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mb-2 flex justify-between">
            <div className="flex items-center">
              <FormRadioGroup
                id="extra_fields.subtype_of_service"
                name="subtype_of_service"
                labelProp="label"
                valueProp="value"
                options={[
                  { label: "Repair", value: "Repair" },
                  {
                    label: "Clean",
                    value: "Clean",
                  },
                  {
                    label: "Both",
                    value: "Both",
                  },
                ]}
                isOnChange={(e) => {
                  formik.setFieldValue("extra_fields.subtype_of_service", e);
                }}
                isCheck={
                  formik.values?.extra_fields?.subtype_of_service || null
                }
                isRadio={true}
                isHorizontal
              />
            </div>
            <div className="flex items-center">
              <div className="flex items-center">
                <input
                  id="extra_fields.is_installation"
                  name="extra_fields.is_installation"
                  type="checkbox"
                  checked={formik.values?.extra_fields?.is_installation}
                  onChange={() => {
                    formik.setFieldValue(
                      "extra_fields.is_installation",
                      formik.values?.extra_fields?.is_installation
                        ? false
                        : true
                    );
                  }}
                />
                <label className="pl-2 text-[15px]">Installation</label>
              </div>
              <div className="flex items-center pl-3">
                <input
                  id="extra_fields.is_inspection"
                  name="extra_fields.is_inspection"
                  type="checkbox"
                  checked={formik.values?.extra_fields?.is_inspection}
                  onChange={() => {
                    formik.setFieldValue(
                      "extra_fields.is_inspection",
                      formik.values?.extra_fields?.is_inspection ? false : true
                    );
                  }}
                />
                <label className="pl-2 text-[15px]">Inspection</label>
              </div>
            </div>
          </div>
        </div>
        <TechNote formik={formik} />
        <div className="border border-[#F0F0F0] p-3">
          <label className="mb-2 font-semibold text-sm">Comments</label>
          <FormTextArea
            rows={5}
            name="special_instruction"
            formik={formik}
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

export default ChimneySweepingSection;

import dayjs from "dayjs";
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  get_recent_message_list_new,
  setMessCurrentPage,
  setChatCurrentPage,
} from "../../../features/smsCenterSlice";
import Loader from "../../atoms/Loader";
import { throttle } from "lodash";
function ChatHeads(props) {
  const {
    userId,
    setUserId,
    chatFilter,
    setIsLoadChat,
    setIsChatEnd,
    multiArray,
    setMultiArray,
    setChatArray,
    setIsEnd,
    isEnd,
    setUserPhone,
    isHiddenPhones,
    isUserPhone,
    clearChatConversation,
    isMarkasRead,
  } = props;
  const dispatch = useDispatch();
  const [isLoader, setIsLoading] = useState(false);
  const { isLoading, recentSmsMsg, currentMessPage, recentSmsMsgonLoad } =
    useSelector((state) => state.smsCenter);
  const containerRef = useRef();
  const lastItemRef = useRef(null);
  const [isManualTrigger, setIsManualTrigger] = useState(false);
  useEffect(() => {
    if (lastItemRef.current && isManualTrigger) {
      lastItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [multiArray, isManualTrigger]);

  useEffect(() => {
    const container = containerRef.current;
    container.scrollTop = 0;
  }, [chatFilter]);

  useEffect(() => {
    if (recentSmsMsgonLoad?.data && multiArray?.length === 0) {
      setMultiArray(recentSmsMsgonLoad?.data);
    }
    // eslint-disable-next-line
  }, [recentSmsMsgonLoad?.data, multiArray?.length]);

  const handleLoadMore = async () => {
    if (isLoader || isEnd) return;
    setIsLoading(true);
    let filter = {};

    if (chatFilter === "all") {
      filter = { page: currentMessPage, user_type: "" };
    } else if (chatFilter === "unread") {
      filter = { page: currentMessPage, unread: true };
    } else if (chatFilter === "vendor") {
      filter = { page: currentMessPage, user_type: "Vendor" };
    } else if (chatFilter === "client") {
      filter = { page: currentMessPage, user_type: "Client" };
    }

    try {
      const res = await dispatch(get_recent_message_list_new({ ...filter }));

      if (res?.payload?.status === 200) {
        const data = res?.payload?.data?.data;
        if (data?.length) {
          setMultiArray((prevData) => [...prevData, ...data]);
          await dispatch(setMessCurrentPage(currentMessPage + 1));
        } else {
          setIsEnd(true);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setIsLoading(false);
      setIsManualTrigger(false);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;

    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 20 &&
      !isEnd &&
      !isLoader
    ) {
      handleLoadMore();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const throttledScrollHandler = throttle(handleScroll, 300);

    container.addEventListener("scroll", throttledScrollHandler);

    return () => {
      container.removeEventListener("scroll", throttledScrollHandler);
    };
    // eslint-disable-next-line
  }, [currentMessPage, isEnd, isLoader]);

  const handleLinkClick = () => {
    setIsManualTrigger(true);
    handleLoadMore();
  };
  return (
    <>
      <div className="h-[450px] overflow-y-auto scrollbar-custom" ref={containerRef}>
        {multiArray?.map((data, index) => {
          let name = data?.user?.first_name
            ? data?.user?.first_name
            : data?.client_id?.name
            ? data?.client_id?.name
            : data?.vendor_id?.first_name
            ? data?.vendor_id?.first_name
            : data?.user_phone
            ? data?.user_phone
            : "";
          let name2 = data?.user?.last_name
            ? data?.user?.last_name
            : data?.vendor_id?.last_name
            ? data?.vendor_id?.last_name
            : "";
          let text = name
            ? name
                .replaceAll("+", "")
                .split(" ")
                .slice(0, 2)
                .map((string) => (string ? string[0].toUpperCase() : ""))
            : "";
          const recordType =
            data?.role?.name ||
            (data?.client_id?._id
              ? "Client"
              : data?.vendor_id?._id
              ? "Vendor"
              : "Custom");
          const fullName = `${name ? name : ""} ${name2 ? name2 : ""}`.trim();
          return (
            <div
              ref={index === multiArray.length - 1 ? lastItemRef : null}
              className={`wms-hysearch-result border-bottom ${
                userId &&
                userId?.user?._id === data?.user?._id &&
                userId?.client?._id === data?.client?._id &&
                userId?.user_phone === data?.user_phone
                  ? "bg-[#f7f8f8] border-l-4 border-menu"
                  : ""
              }`}
              onClick={() => {
                if (isLoader || isLoading || isMarkasRead) return;
                if (isUserPhone !== data?.user_phone) {
                  dispatch(setChatCurrentPage(1));
                  setUserId({
                    ...data,
                    user_type: data?.user_id?._id
                      ? "User"
                      : data?.client_id?._id
                      ? "Client"
                      : data?.vendor_id?._id
                      ? "Vendor"
                      : "Custom",
                  });
                  setUserPhone(data?.user_phone);
                  setIsLoadChat(false);
                  setChatArray([]);
                  setIsChatEnd(false);
                }
                clearChatConversation();
              }}
              key={index}
            >
              <div className="wms-imgcollage">
                <div className="wms-lettercoll">
                  <span>{text}</span>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="wms-hysearch-result-name wms-truncate-ellipsis">
                  <span className="mr-1">{fullName}</span>
                  <span
                    style={{ color: "#888", fontSize: 12 }}
                    className="wms-hysearch-result-des"
                  >
                    {recordType}
                  </span>
                </div>
                <span style={{ fontSize: 10, color: "#888888" }} className="w-[100px] mr-2 font-semibold">
                  {dayjs(data.createdAt).format("h:mm A | D MMM")}
                </span>
              </div>
              <div className="wms-hysearch-result-desc wms-truncate-ellipsis">
                <span className="wmsgrey">
                  {" "}
                  {data?.message_type === "" ? "You:" : name + ":"}{" "}
                </span>
                {data.message}
              </div>

              {data?.unread_count > 0 &&
                !isHiddenPhones.includes(data?.user_phone) && (
                  <small className="wms_menu_item_count -mt-1 !ml-1! px-[5px] py-[2px] rounded-full bg-menu text-white">
                    {data?.unread_count}
                  </small>
                )}
            </div>
          );
        })}
      </div>
      <span className="text-[#2d4665] text-sm flex items-center justify-between text-center w-full ml-2 border-t border-gray-300 pt-1 px-2 py-1 mb-2">
        {!isLoading &&
        multiArray.length &&
        (recentSmsMsgonLoad?.totalItems > 0 || recentSmsMsg?.totalItems > 0) ? (
          <>
            <span className="flex items-center text-[#2d4665]">
              Total messages:
              <span className="font-medium text-sm text-primary-100 ml-2 mt-1">
                {multiArray.length} of{" "}
                {recentSmsMsgonLoad?.totalItems || recentSmsMsg?.totalItems}
              </span>
            </span>

            {!isEnd && multiArray.length < (recentSmsMsgonLoad?.totalItems || recentSmsMsg?.totalItems)  ? (
              <>
                <span className="mx-2 text-gray-500">|</span>
                <span
                  className="text-primary-100 text-sm cursor-pointer ml-4 hover:text-primary-100 mr-4"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleLinkClick();
                  }}
                >
                  Load more...
                </span>
              </>
            ) : null}
          </>
        ) : (
          <span className="mb-4 flex justify-center items-center w-full">
            {isLoading ? <Loader linear="linear" /> : null}
          </span>
        )}
      </span>
    </>
  );
}

export default ChatHeads;

import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { Loader } from "../../../components";

function SaveFilterModal({ onClose, filters, setFilters, editingRecord }) {
  const [loading, setLoading] = useState(false);
  const [filterName, setFilterName] = useState(editingRecord?.note ?? "");
  const [saveMsg] = useState(editingRecord?._id ? "updated" : "saved");
  const handleSaveFilters = async () => {
    const payload = {
      // filter_name: filterName,
      report_name: "INBOUND_REPORT",
      data: { filters },
      note: filterName,
    };
    setLoading(true);
    try {
      const url = editingRecord?._id
        ? `api/report_filter_columns/${editingRecord?._id}`
        : `api/report_filter_columns`;
      const res = editingRecord?._id
        ? await api.put(url, payload)
        : await api.post(url, payload);
      if (res.status === 200) {
        toast.success(
          res?.data?.error ||
            res?.data?.message ||
            `Filter ${saveMsg} successfully`
        );
        setFilters(res?.data);
        onClose();
      } else {
        toast.error(
          res?.data?.error || res?.data?.message || `Filter couldn't ${saveMsg}`
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Filter couldn't saved"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-50 bg-black/60">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">Save selected filters</h2>
          <label className="block mb-1">Please enter the filter name:</label>
          <input
            className="border !border-[#a9a9a9] !rounded py-2 px-3.5 w-full"
            placeholder="Filter Name"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          {loading ? (
            <div className="flex justify-end !mt-4">
              <Loader />
            </div>
          ) : (
            <div className="flex justify-end !mt-4">
              <button
                className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleSaveFilters}
              >
                {editingRecord?._id ? "Update" : "Save"}
              </button>
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default SaveFilterModal;

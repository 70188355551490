import React, { useState, useRef } from "react";
import { Button, Backdrop, DotsLoader } from "../../../components";
import api from "../../../services/api";
import { FaChevronUp } from "react-icons/fa";
import { toast } from "react-toastify";
import LeadByTimeListings from "./LeadByTimeListings";

function AddUpdateLeadPage({ onCancelForm, data, filters }) {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsloading] = React.useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [selectedLeadType, setSelectedLeadType] = useState(null);
  const optionsRef = useRef();

  const getLeadsData = async (filter) => {
    setIsloading(true);
    setRecords([]);
    try {
      const res = await api.post(
        `/api/lead_source/lead_source_report_by_date`,
        filter
      );
      if (res.status === 200 || res.status === 201) {
        setRecords(res.data);
      }
      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      toast.error(
        "Failed to fetch leads data." ||
          err.response.data.message
      );
      console.log(err);
    }
  };

  React.useEffect(() => {
    getLeadsData({
      date: data?.date,
      lead_source_id: filters?.lead_source_id,
      campaign_id: filters?.campaign_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOpen = (index) => expandedIndex === index;
  const toggleListVisibility = (index, item) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    if (expandedIndex !== index) {
      setSelectedLeadType(item);
    }
  };

  function formatString(str) {
    let formattedStr = str.replace(/([a-z])([A-Z])/g, "$1 $2");
    formattedStr = formattedStr.replace(/(\d)([A-Za-z])/g, "$1 $2");

    formattedStr = formattedStr.replace(/([A-Za-z])(\d)/g, "$1 $2");

    formattedStr =
      formattedStr.charAt(0).toUpperCase() +
      formattedStr.slice(1).toLowerCase();

    formattedStr = formattedStr.replace(/\bsec\b/g, "Seconds");

    return formattedStr;
  }

  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };

  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  return (
    <>
      {isLoading && <DotsLoader />}

      <div className="min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
        <div className="overflow-y-auto h-screen">
          <div className="flex items-center justify-between w-full text-client-50 py-2.5 px-5 bg-white shadow-client overflow-y-auto">
            <h4>Leads Listing</h4>
            <Button
              text="X"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_danger"
            />
          </div>
          <Backdrop onClick={onCancelForm} />

          <div className="!p-5 grid gap-5  mb-0">
            <div className="!py-5 !px-3 md:px-10 bg-white rounded-3xl shadow-client">
              <div>
                {Object.keys(records).length ? (
                  (() => {
                    const leadTypes = [
                      "lessThan30SecLeads",
                      "lessThan60SecLeads",
                      "moreThan60SecLeads",
                    ];

                    return leadTypes.map((item, index) => {
                      const leadCount = records[item]?.length || 0;

                      return (
                        <div
                          key={item}
                          className="mx-4 bg-white dark:bg-gray-800 last:border-none dark:border-white/20 !py-0 mb-2 shadow-sm border border-neutral-200 mt-2"
                        >
                          <div className="px-6 !py-3 flex bg-white border-b border-neutral-200 items-center justify-between flex-wrap gap-2">
                            <div className="flex items-center justify-between text-sm gap-3 !text-black">
                              <p className="font-bold text-black w-12">
                                {index + 1}.
                              </p>

                              <div className="w-80">
                                <b className="text-black">Lead :</b>
                                <span className="ml-1">
                                  {formatString(item)}
                                </span>
                              </div>

                              <div className="w-40 ml-4">
                                <b className="text-black">Lead Count:</b>
                                <span className="ml-1">({leadCount})</span>
                              </div>
                            </div>

                            <div className="ml-auto flex items-center gap-3">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleListVisibility(index, item);
                                }}
                              >
                                <FaChevronUp
                                  className={`w-5 transition-transform transform text-gray-400 ${
                                    isOpen(index) && "rotate-180"
                                  }`}
                                />
                              </button>
                            </div>
                          </div>

                          <div
                            className={`px-2 overflow-hidden transition-max-height duration-500 ease-in-out ${
                              isOpen(index) ? "max-h-screen" : "max-h-0"
                            }`}
                          >
                            {isOpen(index) && leadCount > 0 ? (
                              <div>
                                {selectedLeadType === item && (
                                  <LeadByTimeListings records={records[item]} />
                                )}
                              </div>
                            ) : (
                              <div className="flex items-center justify-center text-center font-semibold px-4 py-4 ">
                                No Record Found
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    });
                  })()
                ) : (
                  <div className="w-full flex justify-center">
                    <strong>No record found</strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUpdateLeadPage;

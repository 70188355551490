import React, { memo } from "react";
import GaterepairSection from "../IndustryForms/Gaterepair";
import LocksmithSection from "../IndustryForms/Locksmith";
import CarpetCleaningSection from "../IndustryForms/CarpetCleaning";
import AirDuctSection from "../IndustryForms/AirDuct";
import ChimneySweepingSection from "../IndustryForms/ChimneySweeping";
import GarageDoorSection from "../IndustryForms/GarageDoor";
import DryerventSection from "../IndustryForms/Dryervent";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import LocationSection from "../Common/Sections/LocationSection";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import TechNote from "../Common/Sections/TechNote";
import { FormTextArea } from "../../../components";

const DefaultComponent = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceMess,
  checkServiceDirect,
}) => (
  <div>
    <div className="grid grid-cols-2 gap-3">
      <PropertyOccupancy formik={formik} />
      <div>
        <LocationSection
          formik={formik}
          setIsLoader={setIsLoader}
          callSellLeadToServiceDirect={callSellLeadToServiceDirect}
          checkServiceMess={checkServiceMess}
          checkServiceDirect={checkServiceDirect}
        />
        <SchedulerSection formik={formik} />
      </div>
      <TechNote formik={formik} />
      <div className="border border-[#F0F0F0] p-3">
        <label className="mb-2 font-semibold text-sm">Comments</label>
        <FormTextArea
          rows={5}
          name="special_instruction"
          formik={formik}
          type="text"
        />
      </div>
    </div>
  </div>
);
const componentMap = {
  "Dryer Vent Cleaning": DryerventSection,
  "Gate Repair": GaterepairSection,
  Locksmith: LocksmithSection,
  "Carpet Cleaning": CarpetCleaningSection,
  Airduct: AirDuctSection,
  "Chimney Sweeping": ChimneySweepingSection,
  "Garage Door Repair": GarageDoorSection,
};
const DynamicComponent = memo(
  function DynamicComponent({
    industryName,
    formik,
    setIsLoader,
    callSellLeadToServiceDirect,
    checkServiceMess,
    checkServiceDirect,
  }) {
    const SelectedComponent = componentMap[industryName] || DefaultComponent;
    return (
      // <Suspense fallback={<div>Loading...</div>}>
      <SelectedComponent
        formik={formik}
        setIsLoader={setIsLoader}
        callSellLeadToServiceDirect={callSellLeadToServiceDirect}
        checkServiceMess={checkServiceMess}
        checkServiceDirect={checkServiceDirect}
      />
      // </Suspense>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.industryName === nextProps.industryName &&
      JSON.stringify(prevProps.formik.values) ===
        JSON.stringify(nextProps.formik.values) &&
      prevProps.checkServiceMess === nextProps.checkServiceMess &&
      prevProps.checkServiceDirect === nextProps.checkServiceDirect
    );
  }
);

export default DynamicComponent;

import React, { useState } from "react";
import { Modal } from "../../../components";
import { MdAttachFile } from "react-icons/md";
import { FaFilePdf, FaFileWord, FaFileCode, FaFileAlt } from "react-icons/fa";
import api from "../../../services/api";
import Loader from "../../atoms/Loader";
import { toast } from "react-toastify";
import { FcPrevious, FcNext } from "react-icons/fc";
import { MdOutlineFileUpload } from "react-icons/md";

const ImageUpload = ({ onCancelForm, modalTitle, onUploadSuccess }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [fileSizeError, setFileSizeError] = useState("");
  const [fileCountError, setFileCountError] = useState("");

  const handleFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      let newFiles = [...selectedFiles];
      let sizeError = "";
      let countError = "";
      let duplicateError = ""; 
  
      if (newFiles.length + fileArray.length > 6) {
        countError = "You can only upload up to 6 files.";
      }

      fileArray.forEach((file) => {
        const isDuplicate = newFiles.some((existingFile) => existingFile.name === file.name);
        if (isDuplicate) {
          duplicateError = `File "${file.name}" is already attached. Please choose a different file.`;
        } else if (file.size > 1024 * 1024) {
          sizeError = `File "${file.name}" exceeds 1 MB. Please choose a smaller file.`;
        } else {
          newFiles.push(file);
        }
      });
  
      setFileSizeError(sizeError);
      setFileCountError(countError);
      setUploadError(duplicateError); 
  
      if (!sizeError && !countError && !duplicateError) {
        setSelectedFiles(newFiles);
      }
    }
  };
  

  const removeFile = (index, event) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    if (index <= currentFileIndex) {
      setCurrentFileIndex(Math.max(currentFileIndex - 1, 0));
    }
  };

  const nextFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (currentFileIndex < selectedFiles.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
    }
  };

  const prevFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
    }
  };

  const uploadFiles = async (event) => {
    event.preventDefault();

    setUploading(true);
    setUploadError(null);
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    try {
      const response = await api.post("/api/sms/upload_mms_files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        const data = await response.data;
        if (onUploadSuccess) onUploadSuccess(data);
        onCancelForm();
        toast.success("Files uploaded successfully.");
      } else {
        toast.error("Failed to upload files. Please try again.");
        setUploadError("Failed to upload files. Please try again.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to upload files. Please try again.");
      setUploadError("Failed to upload files. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const getFileIcon = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="preview"
          className="w-24 h-24 object-cover rounded-md"
        />
      );
    }

    // For PDFs
    if (file.name.endsWith(".pdf")) {
      return <FaFilePdf size={60} color="#E63946" />;
    }

    // For DOCX files
    if (file.name.endsWith(".docx")) {
      return <FaFileWord size={60} color="#4CAF50" />;
    }

    // For JSON files
    if (file.name.endsWith(".json")) {
      return <FaFileCode size={60} color="#FF5722" />;
    }

    // For other text files
    return <FaFileAlt size={60} color="#9E9E9E" />;
  };

  return (
    <Modal
      title={modalTitle}
      hideHeader
      onCancelModal={onCancelForm}
      onClick={onCancelForm}
      hideButtons
    >
      <div className="space-y-4">
        <label htmlFor="file-upload" className="cursor-pointer">
          <div className="flex items-center justify-center space-x-2 p-2 px-6 bg-primary-100 text-white rounded-full shadow-md hover:bg-gray-400 transition">
            <MdAttachFile size={24} color="black" />
            <span className="text-sm font-medium mr-2">Attach Files</span>
          </div>
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/*,.pdf,.json,.docx,.txt"
          multiple
          onChange={handleFileChange}
          className="hidden"
        />

        {(fileSizeError || fileCountError) && (
          <div className="flex items-center  text-sm text-red-800">
            <div>
              <span className="font-medium">
                {fileSizeError && <p>{fileSizeError}</p>}
                {fileCountError && <p>{fileCountError}</p>}
              </span>
            </div>
          </div>
        )}

        {selectedFiles.length > 0 && (
          <div className="grid grid-cols-4 gap-4 mt-4 border-2 border-dotted p-4 rounded-md">
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className="relative flex flex-col justify-center items-center border border-gray-300 p-2 rounded-md"
              >
                <div className="text-center flex flex-col justify-center items-center">
                  {getFileIcon(file)}
                  <p className="mt-2 text-xs text-gray-700 break-words">
                    {file.name}
                  </p>
                </div>

                <button
                  onClick={(e) => removeFile(index, e)}
                  className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-full shadow-md hover:bg-red-600 transition h-6 w-6 flex justify-center items-center"
                >
                  <span className="text-lg font-bold">&times;</span>
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="text-sm text-gray-500 mt-2 text-center">
          {selectedFiles.length} file{selectedFiles.length > 1 ? "s" : ""}{" "}
          selected
        </div>

        {selectedFiles.length > 0 && !uploading && (
          <div className="mt-4 text-center">
            <button
              onClick={uploadFiles}
              className="space-x-2 p-2 px-6 bg-primary-100 text-white rounded-sm shadow-md  hover:bg-blue-600 transition"
            >
              <MdOutlineFileUpload size={22} color="black" />
              <span className="text-sm font-medium mr-2">Upload Files</span>
            </button>
          </div>
        )}

        {uploading && (
          <div className="mt-4 text-center text-blue-500">
            <Loader linear="linear" />
            Uploading Files...{" "}
          </div>
        )}

        {uploadError && (
          <div className="mt-4 text-center text-red-500">{uploadError}</div>
        )}

        {selectedFiles.length > 0 && (
          <div className="mt-6 border-top py-4">
            <div className="flex justify-between items-center mb-2">
              <button
                onClick={prevFile}
                className=" text-white p-2 rounded-full hover:bg-gray-600 transition"
                disabled={currentFileIndex === 0}
              >
                <FcPrevious className="w-7 h-7" />
              </button>
              <span className="text-lg text-gray-700">
                File {currentFileIndex + 1} of {selectedFiles.length}
              </span>
              <button
                onClick={nextFile}
                className=" text-white p-2 rounded-full hover:bg-gray-600 transition"
                disabled={currentFileIndex === selectedFiles.length - 1}
              >
                <FcNext className="w-7 h-7" />
              </button>
            </div>

            <div className="flex justify-center items-center">
              {selectedFiles[currentFileIndex] && (
                <div className="text-center flex flex-col justify-center items-center">
                  {getFileIcon(selectedFiles[currentFileIndex])}
                  <p className="mt-2 text-sm text-gray-700 break-words">
                    {selectedFiles[currentFileIndex].name}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImageUpload;

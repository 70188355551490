import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import {
  FormInput,
  FormSelectInput,
  DotsLoader,
  Modal,
} from "../../../components";
import errorMessage from "../../../util/errorMessage";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { FaPaperPlane } from "react-icons/fa";

function AddUpdateAffiliate({
  onCancelForm,
  modalTitle,
  editingRecordId,
  leadCampaigns,
  reloadCampignListing,
  editCampaignRecord,
}) {
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneNumberFields, setShowPhoneNumberFields] = useState(false);
  const [isWebFormChecked, setIsWebFormChecked] = useState(false);
  const [isInboundCallChecked, setIsInboundCallChecked] = useState(false);
  const [isWebSource, setWebSource] = useState(false);
  const [showDomainFields, setShowDomainFields] = useState(false);
  const [bulkPhoneNumbers, setBulkPhoneNumbers] = useState([]);
  const [bulkDomains, setBulkDomains] = useState([]);

  let initialValues = {
    WebForm: false,
    phoneNumbers: [],
    inboundCall: false,
    webSourceCheck: false,
    webSource: [],
    lead_source_campaign_id: "",
  };

  if (editCampaignRecord) {
    const { _id, campaign_id, WebForm, webSource, inboundPhoneNumbers } =
      editCampaignRecord;
    initialValues = {
      id: _id,
      phoneNumbers: inboundPhoneNumbers?.length ? inboundPhoneNumbers : [],
      webSource: webSource?.length ? webSource : [],
      WebForm: WebForm,
      lead_source_campaign_id: campaign_id?._id,
    };
  }
  const validationSchema = Yup.object({
    lead_source_campaign_id: Yup.string().required("Campaign is required"),
    phoneNumbers: Yup.array().when("inboundCall", {
      is: true,
      then: Yup.array()
        .of(
          Yup.string()
            .matches(
              /^(\+?\d{1,4}[\s\-]?)?\(?\d{3}\)?[\s\-]?\d{3}[\s\-]?\d{4}$/,
              "Invalid phone number format"
            )
            .test("length", "Phone number must be 10 or 11 digits", (value) => {
              if (!value) return false;
              const cleanedValue = value.replace(/\D/g, "");
              return cleanedValue.length === 10 || cleanedValue.length === 11;
            })
            .required("Phone number is required")
        )
        .min(1, "At least one phone number is required"),
      otherwise: Yup.array().notRequired(),
    }),
    webSource: Yup.array().when("webSourceCheck", {
      is: true,
      then: Yup.array()
        .of(
          Yup.string()
            .matches(
              /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-]*)?$/,
              "Invalid domain URL format. Ensure it includes http://, https://, or is a valid domain."
            )
            .required("Domain URL is required")
        )
        .min(1, "At least one domain URL is required"),
      otherwise: Yup.array().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let payload = {
        ...(editCampaignRecord
          ? {}
          : {
              lead_source_id: editingRecordId,
              campaign_id: values?.lead_source_campaign_id,
            }),
        WebForm: values?.WebForm,
        inboundPhoneNumbers: values?.inboundCall ? values?.phoneNumbers : [],
        webSource: values?.webSourceCheck ? values?.webSource : [],
      };

      try {
        setIsLoading(true);
        let res;
        if (editCampaignRecord?._id) {
          res = await api.put(
            `api/lead_source/updateSettings/${editCampaignRecord._id}`,
            payload
          );
        } else {
          res = await api.post(`api/lead_source/add_settings`, payload);
        }

        if (res.status === 200 || res.status === 201) {
          toast.success(
            editCampaignRecord?._id
              ? "Lead source settings successfully updated."
              : "Lead source settings successfully created."
          );
          reloadCampignListing();
        }
      } catch (err) {
        console.error(err);
        const errorData = err?.response?.data;
        errorMessage({
          payload: errorData.error || errorData.message,
          setErrors: setErrors,
          action: "Lead Source",
          msg: "created",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (id === "web-form-checkbox") {
      setIsWebFormChecked(checked);
      formik.setFieldValue("WebForm", checked);
    } else if (id === "inbound-call-checkbox") {
      setIsInboundCallChecked(checked);
      setShowPhoneNumberFields(checked);
      if (checked) {
        addPhoneNumber();
      } else {
        formik.setFieldValue("phoneNumbers", []);
      }
      formik.setFieldValue("inboundCall", checked);
    } else if (id === "websource-form") {
      setWebSource(checked);
      setShowDomainFields(checked);
      if (checked) {
        addDomains();
      } else {
        formik.setFieldValue("webSource", []);
      }
      formik.setFieldValue("webSourceCheck", checked);
    }
  };

  // Add a new phone number
  const addPhoneNumber = () => {
    const updatedPhoneNumbers = [...formik.values.phoneNumbers, ""];
    formik.setFieldValue("phoneNumbers", updatedPhoneNumbers);
  };

  // Remove a phone number
  const removePhoneNumber = (index) => {
    const updatedPhoneNumbers = formik.values.phoneNumbers.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("phoneNumbers", updatedPhoneNumbers);
  };

  const handlePhoneNumberChange = (index, value) => {
    const updatedPhoneNumbers = [...formik.values.phoneNumbers];
    updatedPhoneNumbers[index] = value;
    formik.setFieldValue("phoneNumbers", updatedPhoneNumbers);
  };

  // Add a new domain
  const addDomains = () => {
    const updatedWebSource = [...formik.values.webSource, ""];
    formik.setFieldValue("webSource", updatedWebSource);
  };

  // Remove a domain
  const removeDomain = (index) => {
    const updatedWebSource = formik.values.webSource.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("webSource", updatedWebSource);
  };

  const handleDomainChange = (index, value) => {
    const updatedWebSource = [...formik.values.webSource];
    updatedWebSource[index] = value;
    formik.setFieldValue("webSource", updatedWebSource);
  };

  useEffect(() => {
    if (editCampaignRecord?.inboundPhoneNumbers?.length > 0) {
      formik.setFieldValue(
        "phoneNumbers",
        editCampaignRecord?.inboundPhoneNumbers
      );
      setIsInboundCallChecked(true);
      setShowPhoneNumberFields(true);
      formik.setFieldValue("inboundCall", true);
    } else {
      formik.setFieldValue("inboundCall", false);
    }
    // eslint-disable-next-line
  }, [editCampaignRecord?.inboundPhoneNumbers?.length]);

  useEffect(() => {
    if (editCampaignRecord?.webSource?.length > 0) {
      formik.setFieldValue("webSource", editCampaignRecord?.webSource);
      setWebSource(true);
      setShowDomainFields(true);
      formik.setFieldValue("webSourceCheck", true);
    } else {
      formik.setFieldValue("webSourceCheck", false);
    }
    // eslint-disable-next-line
  }, [editCampaignRecord?.webSource?.length]);

  const handleBulkNumber = () => {
    if (!bulkPhoneNumbers?.length) {
      toast.error("Please Add numbers, separated by commas or by pressing enter!");
      return;
    }
    if (bulkPhoneNumbers?.length) {
      const newPhoneNumbers = bulkPhoneNumbers.filter(
        (num) => num.trim() !== ""
      );
      const formikPhoneNumber = formik.values.phoneNumbers.filter(
        (num) => num.trim() !== ""
      );
      const updatedPhoneNumbers = [
        ...formikPhoneNumber.map((num) => num.replace(/\s+/g, "")),
        ...newPhoneNumbers.map((num) => num.replace(/\s+/g, "")),
      ];
      formik.setFieldValue("phoneNumbers", updatedPhoneNumbers);
      setBulkPhoneNumbers([]);
    }
  };

  const handleBulkDomain = () => {
    if (!bulkDomains?.length) {
      toast.error("Please add domains, separated by commas or by pressing enter!");
      return;
    }
    if (bulkDomains?.length) {
      const newBulkDomains = bulkDomains.filter((num) => num.trim() !== "");
      const formikwebSource = formik.values.webSource.filter(
        (num) => num.trim() !== ""
      );
      const updatedDomains = [
        ...formikwebSource.map((num) => num.replace(/\s+/g, "")),
        ...newBulkDomains.map((num) => num.replace(/\s+/g, "")),
      ];
      formik.setFieldValue("webSource", updatedDomains);
      setBulkDomains([]);
    }
  };

  return (
    <Modal
      onCancelModal={onCancelForm}
      title={modalTitle}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
      modalType={true}
    >
      {isLoading && <DotsLoader />}
      <div className="!py-5 !px-5 md:px-12 w-fit">
        <div className="grid md:grid-cols-1 gap-5 !mt-1">
          <div className="grid md:grid-cols-2 gap-5 !mt-1">
            <div className="mb-2">
              <FormSelectInput
                errors={errors}
                name="lead_source_campaign_id"
                label="Lead Campaign"
                formik={formik}
                options={leadCampaigns}
                valueProp="_id"
                labelProp="title"
                disabled={!!editCampaignRecord}
              />
            </div>
            <div className="flex flex-col gap-4 md:flex-row md:items-center">
              <div className="flex items-center">
                <input
                  id="web-form-checkbox"
                  type="checkbox"
                  checked={isWebFormChecked || formik.values.WebForm}
                  onChange={handleCheckboxChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="web-form-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Web Form
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="inbound-call-checkbox"
                  type="checkbox"
                  checked={isInboundCallChecked}
                  onChange={handleCheckboxChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="inbound-call-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Inbound Call
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="websource-form"
                  type="checkbox"
                  checked={isWebSource}
                  onChange={handleCheckboxChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="websource-form"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Web Source
                </label>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-1 gap-5 !mt-1">
            <div className="flex space-x-4">
              {showPhoneNumberFields && (
                <div
                  className={`w-full pr-4 ${
                    showPhoneNumberFields && showDomainFields
                      ? "border-r border-gray-300"
                      : ""
                  }`}
                >
                  <div className="!mb-2 w-full">
                    <div className="grid lg:grid-cols-1 gap-4 mb-2">
                      <div className="flex justify-start">
                        <h6 className="!mb-1 text-client-50  w-full border-b border-client-50 flex justify-between items-center">
                          Phone Numbers
                        </h6>
                      </div>

                      <div className="relative flex flex-col w-[300px]">
                        <div className="flex items-center">
                          <div className="overflow-y-auto max-h-40 flex-1">
                            <TagsInput
                              tags={bulkPhoneNumbers}
                              setTags={setBulkPhoneNumbers}
                              placeholder="Add Bulk Numbers"
                            />
                          </div>
                          <button
                            className="ml-2 p-2 bg-primary-100 text-white h-[35px] rouner-md"
                            onClick={handleBulkNumber}
                          >
                            <FaPaperPlane
                              className="text-md w-4 h-4"
                              title="Please add bulk numbers, separated by commas (e.g  1234567890, 1234567890)."
                            />
                          </button>
                        </div>
                        <div className=" text-sm text-yellow-800 rounded-lg">
                          <span className="font-medium">
                            ( add numbers, separated by commas or by pressing enter )
                          </span>
                        </div>
                      </div>

                      <span className="text-gray-500 font-semibold">OR</span>

                      {formik?.values?.phoneNumbers?.map(
                        (phoneNumber, index) => (
                          <div
                            key={index}
                            className="relative flex flex-col space-y-2 mb-2"
                          >
                            <div className="flex items-center space-x-2">
                              <FormInput
                                formik={formik}
                                type="number"
                                onChange={(event) =>
                                  handlePhoneNumberChange(
                                    index,
                                    event.target.value
                                  )
                                }
                                value={phoneNumber}
                                placeholder={`Phone Number ${index + 1}`}
                              />
                              {index === 0 && (
                                <AiFillPlusCircle
                                  className="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                  onClick={addPhoneNumber}
                                />
                              )}

                              {index !== 0 && (
                                <AiFillMinusCircle
                                  className="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                  onClick={() => removePhoneNumber(index)}
                                  title="Remove"
                                />
                              )}
                            </div>

                            {formik.errors.phoneNumbers?.[index] &&
                              formik.touched.phoneNumbers?.[index] && (
                                <div className="text-red-500 text-xs ml-2">
                                  {formik.errors.phoneNumbers[index]}
                                </div>
                              )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}

              {showDomainFields && (
                <div className="w-full pl-4">
                  <div className="!mb-2 w-full">
                    <div className="grid lg:grid-cols-1 gap-4 mb-2">
                      <div className="flex justify-start">
                        <h6 className="!mb-1 text-client-50  w-full border-b border-client-50 flex justify-between items-center">
                          Domains
                        </h6>
                      </div>
                      <div className="relative flex flex-col w-300px]">
                        <div className="flex items-center">
                          <div className="overflow-y-auto max-h-40 flex-1">
                            <TagsInput
                              tags={bulkDomains}
                              setTags={setBulkDomains}
                              placeholder="Add Bulk Domains"
                            />
                          </div>
                          <button
                            className="ml-2 p-2 bg-primary-100 text-white h-[35px] rouner-md"
                            onClick={handleBulkDomain}
                          >
                            <FaPaperPlane className="text-md w-4 h-4"  title="Please add bulk domains, separated by commas (e.g  abc.com, xyz.com)."/>
                          </button>
                        </div>
                        <div className=" text-sm text-yellow-800 rounded-lg">
                          <span className="font-medium">
                            ( add domains, separated by commas or by pressing enter )
                          </span>
                        </div>
                      </div>

                      <span className="text-gray-500 font-semibold">OR</span>
                      {formik?.values?.webSource?.map((domain, index) => (
                        <div
                          key={index}
                          className="relative flex flex-col space-y-2 mb-2"
                        >
                          <div className="flex items-center space-x-2">
                            <FormInput
                              formik={formik}
                              onChange={(event) =>
                                handleDomainChange(index, event.target.value)
                              }
                              value={domain}
                              placeholder={`Domain ${index + 1}`}
                            />
                            {index === 0 && (
                              <AiFillPlusCircle
                                className="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                onClick={addDomains}
                              />
                            )}
                            {index !== 0 && (
                              <AiFillMinusCircle
                                className="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                onClick={() => removeDomain(index)}
                                title="Remove"
                              />
                            )}
                          </div>

                          {formik.errors.webSource?.[index] &&
                            formik.touched.webSource?.[index] && (
                              <div className="text-red-500 text-xs ml-2">
                                {formik.errors.webSource[index]}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddUpdateAffiliate;

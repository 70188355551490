import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import PageHeader from "../../../components/molecules/PageHeader";
import AddUpdateForm from "./AddUpdateForm";
import { DotsLoader } from "../../../components";
import userPermission from "../../../util/userPermission";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  getLeadComapigns,
  deleteLeadCampaign,
} from "../../../services/leadCampaign";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
const Providers = () => {
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const reloadListing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    getRecords();
  };
  const getRecords = async () => {
    try {
      setIsLoading(true);
      const res = await getLeadComapigns();
      if (res.status === 200) {
        setRecords(res.data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRecords();
    // eslint-disable-next-line
  }, []);

  const listings = records?.filter(
    (data) =>
      data?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
      data?.status?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 200 },
    { headerName: "Title", field: "title", flex: 1 },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => (
        <span
          className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded ${
            params.row.status === "Active"
              ? "bg-indigo-100 text-indigo-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {params.row.status}
        </span>
      ),

      width: 150,
    },
    { headerName: "Created By", field: "createdBy", flex: 1 },
    {
      field: "actions",
      filterable: false,
      renderCell: (params) =>
        params.row.records?.status ? (
          <div>
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Lead campaign"
            />
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records)}
              className="my_navIcon"
              title="Delete DID"
            />
          </div>
        ) : null,
      width: 120,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };

  const deleteRecordHandler = (data) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await deleteLeadCampaign(data?._id);
        if (res?.status === 200) {
          toast.success("Lead campaign deleted Successfully");
          getRecords();
        } else {
          errorMessage({
            payload: res?.data?.message,
            action: "Lead campaign",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error?.response?.data?.message,
          action: "Lead campaign",
          msg: "deleted",
        });
      }
    }
  };
  return (
    <>
      {isLoading && <DotsLoader />}
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Lead Campaign"
          onCancelForm={cancelFormHandler}
          reloadListing={reloadListing}
        />
      )}
      <PageHeader
        heading="Lead Campaigns Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Lead Campaign")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={(listings || [])?.map((record, index) => {
            const { _id, title, status, createdBy } = record;
            const { first_name = "", last_name = "" } = createdBy;
            return {
              _id,
              counter: index + 1,
              records: { ...record, _id: _id },
              title,
              status: status === "active" ? "Active" : "InActive",
              createdBy: `${first_name} ${last_name}`,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={listings?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode={"client"}
        />
      </div>
    </>
  );
};

export default Providers;

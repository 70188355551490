import React, { useCallback, useState } from "react";
import {
  DotsLoader,
  FormSelectInput,
  Modal,
  FormTextArea,
} from "../../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import api from "../../../../services/api";
import * as Yup from "yup";
function ImportDIDsModal({ onCancelForm, modalTitle, reloadListing }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dids, setDids] = useState([]);

  let initialValues = {
    description: "",
    source: "",
  };
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setSelectedFile(files);
    handleFile(files);
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const validationSchema = Yup.object({
    source: Yup.string().required("Source is required"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters long"),
  });

  const handleSubmit = async (values) => {
    if (!dids?.length) {
      toast.error("Please attach a file or drop it, it`s mandatory!");
      return;
    }
    const payload = {
      dids: dids?.map((item) => item.did_pattern.toString()),
      description: values.description,
      source: values.source,
    };
    setLoader(true);
    try {
      await api.post("api/cc/dids/add_purchased_dids", payload);
      toast.success("DID Successfully Added");
      reloadListing();
    } catch (error) {
      toast.error(`DID couldn't be ${error.message}`);
    }

    setLoader(false);
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const targetDidString = "DID";
      if (file.name.endsWith(".xlsx")) {
        // Handle Excel file
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headerRow = excelData[0];

        // Find the index of the column with the heading "zip"
        const didColumnIndex = headerRow.findIndex(
          (column) => column.toLowerCase() === targetDidString.toLowerCase()
        );

        // Create an array with the data from the "zip" column
        if (didColumnIndex > -1) {
          // Create the array of objects
          const dataArray = excelData?.slice(1)?.map((item) => ({
            did_pattern: item[didColumnIndex],
          }));
          setDids(dataArray);
        } else {
          toast.error("DID column not found");
        }
      } else if (file.name.endsWith(".csv")) {
        reader.onload = (e) => {
          const fileData = e.target.result;
          if (file.name.endsWith(".csv")) {
            // Handle CSV file
            Papa.parse(fileData, {
              complete: (result) => {
                const headerRow = result.data[0];
                if (!result.data?.length)
                  return toast.error("No record in this file");
                // Create an array with the data from the "zip" column
                const didColumnIndex = headerRow?.findIndex(
                  (column) =>
                    column.toLowerCase() === targetDidString.toLowerCase()
                );
                if (didColumnIndex > -1) {
                  const dataArray = result.data?.slice(1)?.map((item) => ({
                    did_pattern: item[didColumnIndex],
                  }));
                  setDids(dataArray);
                  // Now dataArray contains the desired array of objects
                } else {
                  toast.error("DID column not found");
                }
              },
              // header: true,
            });
          }
        };

        reader.readAsText(file);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const generateRandomData = () => {
    const headers = ["DID"];
    const randomData = [headers];
    const numEntries = 10;

    for (let i = 0; i < numEntries; i++) {
      const entry = [`did_${i}`];
      randomData.push(entry);
    }
    return randomData;
  };

  const exportToExcel = () => {
    const data = generateRandomData();
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "random_data.xlsx");
  };

  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={loader}
      onClick={onCancelForm}
    >
      {loader ? <DotsLoader /> : null}
      <div className="grid md:grid-cols-3 gap-x-5 mt-3 ">
        <div className="text-base font-medium col-span-3">
          <span className="font-semibold text-lg font_pop text-red-600">
            Note:
          </span>
          <span className="font-semibold text-lg font_pop text-violet-600">
            ".xlsx"
          </span>
          and
          <span className="font-semibold text-lg font_pop text-violet-600">
            ".csv"
          </span>
          files can be imported here which must contain column headers named
          <span className="font-semibold text-lg font_pop text-violet-600">
            "DID"
          </span>
          <span
            className="font-normal text-normal font_pop text-blue-600 underline cursor-pointer ml-1"
            onClick={exportToExcel}
          >
            Sample
          </span>
        </div>
        <div className="col-span-3 !my-3">
          <div
            className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
              isDragActive ? "bg-gray-100" : "bg-white"
            }`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <label
              className={`flex justify-center w-full ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
              onClick={open}
            >
              {selectedFile ? (
                <span className="flex items-center space-x-2">
                  <div className="flex flex-col text-center">
                    <span className="font-medium text-gray-600">
                      File Name: {selectedFile?.name}
                    </span>
                    <span className="text-gray-600" onClick={open}>
                      Size:{" "}
                      {selectedFile?.size > 1000 && selectedFile.size < 1000000
                        ? (selectedFile.size / 1000).toFixed(2) + " KB"
                        : (selectedFile.size / 1000000).toFixed(2) + " MB"}
                    </span>
                    <span className="font-medium text-red-600">
                      Total DIDs added: {dids?.length}
                    </span>
                  </div>
                </span>
              ) : (
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Drop files to Attach, or
                    <span className="text-blue-600 underline ml-1">browse</span>
                  </span>
                </span>
              )}
            </label>
          </div>
        </div>
        <div className="col-span-1 !my-3">
          <div className="mb-1">
            <FormSelectInput
              name="source"
              label="Source"
              formik={formik}
              options={[
                { value: "Bulk Solutions", label: "Bulk Solutions" },
                { value: "Inteliquent", label: "Inteliquent" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        </div>
        <div className="col-span-2 !my-3">
          <div className="mb-3">
            <FormTextArea
              label="Description"
              rows={3}
              name="description"
              formik={formik}
              type="text"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ImportDIDsModal;

import dayjs from "dayjs";

export const getFullName = (record) => {
  const fullName = `${record?.first_name || ""} ${record?.last_name || ""}`;
  if (fullName && fullName !== " ") {
    return fullName;
  } else {
    return "";
  }
};

export const getFormattedDate = (date, format) => {
  if (!date) return "";
  return dayjs(date).format(format ? format : "ddd, MMM D, YYYY h:mm A");
};

export const getFormattedAddress = (shipping_address) => {
  const { address, city, state, zip } = shipping_address || {};
  const addressComponents = [address, city, state, zip].filter(Boolean);
  return addressComponents.join(", ");
};

export const generateYearOptions = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  // Ensure that startYear is not greater than currentYear
  if (startYear <= currentYear) {
    for (let year = startYear; year <= currentYear; year++) {
      years.push({ value: year, label: year });
    }
  }

  return years;
};

import React from "react";
import FormRadioGroup from "../../../../components/molecules/FormRadioGroup";
import FormInput from "../FormInput";
import FormSelectInput from "../FormSelectInput";

function PropertyOccupancy({ formik, isVertical = false }) {
  const occupantType = [
    { label: "Owner", value: "Owner" },
    {
      label: "Renter",
      value: "Renter",
    },
    { label: "Property Manager", value: "Property Manager" },
  ];
  const isOccupantTypeValid = occupantType.some(
    (item) => item.value === formik.values?.extra_fields?.occupant_type
  );
  const { extra_fields } = formik.values || {};
  const isRenter =
    extra_fields?.occupant_type && extra_fields?.occupant_type !== "Owner";
  return (
    <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
      <label className="font-semibold text-sm">Property Occupancy</label>
      <div className="pt-3">
        <div>
          <FormRadioGroup
            id="extra_fields.occupant_type"
            name="occupant_type"
            labelProp="label"
            valueProp="value"
            options={occupantType}
            isOnChange={(e) => {
              formik.setFieldValue("extra_fields.occupant_type", e);
            }}
            isCheck={formik.values?.extra_fields?.occupant_type || null}
            isRadio={true}
            isHorizontal={!isVertical}
            isBorder={!isVertical}
          />
          <div className={`mt-2 ${isRenter ? "grid grid-cols-2 gap-2" : ""}`}>
            <div>
              <label className="font-semibold text-xs text-gray-500 mb-1">
                Other:
              </label>
              <FormInput
                formik={formik}
                name="extra_fields.occupant_type"
                value={
                  !isOccupantTypeValid
                    ? formik.values?.extra_fields?.occupant_type
                    : null
                }
              />
            </div>
            {isRenter ? (
              <div>
                <label className="font-semibold text-xs text-gray-500 mb-1">
                  If renter who will pay?
                </label>
                <FormInput
                  formik={formik}
                  name="extra_fields.payer"
                  value={formik.values?.extra_fields?.payer}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-col w-full mt-2">
          <label className="font-semibold text-xs text-gray-500 mt-2 mb-1">
            Type of Property
          </label>
          <FormSelectInput
            name="extra_fields.property_type"
            options={[
              { value: "Residential", label: "Residential" },
              { value: "Commercial", label: "Commercial" },
            ]}
            valueProp="value"
            labelProp="label"
            formik={formik}
            value={formik.values?.extra_fields?.property_type}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}

export default PropertyOccupancy;

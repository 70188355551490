import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { get_dids } from "../../features/didSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import { DotsLoader } from "../../components";

import RouteDetailView from "../CallCenter/DIDs/RouteDetailView";
const DidsListing = ({
  affiliate,
  industryDrd,
  dialerUsers,
  merchants,
  inbound,
  callManu,
  campaignId,
  didListingData,
}) => {
  const dispatch = useDispatch();
  const [editingRecord, setEditingRecord] = useState(null);
  const { isLoading, record } = useSelector((state) => state.dids);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [affiliateData, setAffiliateData] = useState([]);

  useEffect(() => {
    const affiliateData = didListingData[campaignId];
    setAffiliateData(affiliateData);
  }, [didListingData, campaignId]);

  const initalData = {
    did_route: null,
    did_active: null,
    group_id: null,
    extension: null,
    menu_id: null,
    did_carrier_description: null,
    user: null,
    voicemail_ext: null,
    route_external: null,
    custom_one: null,
  };
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [selectedData, setSelectedData] = useState([]);
  const [selectedPayload, setSelectedPayload] = useState(initalData);
  const [sqlQuerry, setSqlQuerry] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsViewDetail(false);
  };
  const onCancel = () => {
    setSelectedPayload(initalData);
    setSelectedData([]);
    setIsSelectAll(false);
  };
  const didRoutesOption = [
    { value: "EXTEN", label: "External Number" },
    { value: "IN_GROUP", label: "Ring Group" },
    { value: "CALLMENU", label: "Call Menu" },
    { value: "AGENT", label: "Agent" },
  ];
  const didStatusOption = [
    { value: "Y", label: "Active" },
    { value: "N", label: "Not Active" },
  ];

  const handleBulkSave = async () => {
    const allValuesNull = Object.values(selectedPayload).every(
      (value) => value === null || value === ""
    );
    if (allValuesNull) {
      return toast.error("No any value selected");
    } else if (
      selectedPayload?.did_route &&
      !(
        selectedPayload?.extension ||
        selectedPayload?.group_id ||
        selectedPayload?.menu_id ||
        selectedPayload?.user
      )
    ) {
      return toast.error(
        `Selected DID Route is "${selectedPayload?.did_route}" but its value is empty`
      );
    }
    setIsLoader(true);
    const modifiedData = [];
    isSelectAll
      ? (() => {
          let payload = {
            did_id: -1,
          };
          if (
            selectedPayload?.did_route &&
            (selectedPayload?.extension ||
              selectedPayload?.group_id ||
              selectedPayload?.menu_id)
          ) {
            switch (selectedPayload?.did_route) {
              case "EXTEN":
                payload.extension = selectedPayload?.extension
                  ? user?.conexcs_settings?.dial_out_prefix +
                    selectedPayload?.extension
                  : "";
                break;
              case "IN_GROUP":
                payload.group_id = selectedPayload?.group_id;
                break;
              case "CALLMENU":
                payload.menu_id = selectedPayload?.menu_id;
                payload.route_external = selectedPayload?.route_external;
                break;
              case "AGENT":
                payload.user = selectedPayload?.user;
                payload.voicemail_ext = selectedPayload?.voicemail_ext;
                break;
              default:
                break;
            }
          }
          if (selectedPayload?.did_active) {
            payload.did_active = selectedPayload?.did_active;
          }
          if (selectedPayload?.did_carrier_description) {
            payload.did_carrier_description =
              selectedPayload?.did_carrier_description;
          }
          if (selectedPayload?.custom_one) {
            payload.custom_one = selectedPayload?.custom_one;
          }
          if (
            selectedPayload?.did_route &&
            (selectedPayload?.extension ||
              selectedPayload?.group_id ||
              selectedPayload?.menu_id)
          ) {
            payload.did_route = selectedPayload?.did_route;
          }
          modifiedData.push(payload);
        })()
      : selectedData.forEach((record) => {
          let payload = {
            did_id: record?.did_id,
          };
          switch (selectedPayload?.did_route) {
            case "EXTEN":
              payload.extension = selectedPayload?.extension
                ? user?.conexcs_settings?.dial_out_prefix +
                  selectedPayload?.extension
                : "";
              payload.custom_one = selectedPayload?.custom_one;
              break;
            case "IN_GROUP":
              payload.group_id = selectedPayload?.group_id;
              break;
            case "CALLMENU":
              payload.menu_id = selectedPayload?.menu_id;
              payload.route_external = selectedPayload?.route_external;
              break;
            case "AGENT":
              payload.user = selectedPayload?.user;
              payload.voicemail_ext = selectedPayload?.voicemail_ext;
              break;
            default:
              break;
          }
          if (selectedPayload?.did_active) {
            payload.did_active = selectedPayload?.did_active;
          }
          if (selectedPayload?.did_route) {
            payload.did_route = selectedPayload?.did_route;
          }
          if (selectedPayload?.did_carrier_description) {
            payload.did_carrier_description =
              selectedPayload?.did_carrier_description;
          }
          if (selectedPayload?.custom_one) {
            payload.custom_one = selectedPayload?.custom_one;
          }
          modifiedData.push(payload);
        });
    try {
      const res = await api.post("/api/cc/dids/bulk_update", {
        sqlwhere: sqlQuerry,
        did_data: modifiedData,
        did_cx: {
          cx: user?.conexcs_settings?.cx || "",
          destination_ip: user?.conexcs_settings?.destination_ip || "",
          dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
        },
      });
      if (res?.data?.status) {
        setSelectedData([]);
        setIsSelectAll(false);
        setSelectedPayload(initalData);
        toast.success(res?.data?.message);
        dispatch(
          get_dids({
            page: paginationModel?.page,
            size: paginationModel?.pageSize,
            sqlwhere: sqlQuerry,
          })
        );
      } else {
        toast.error("Data coludn't be updated");
      }
      setIsLoader(false);
    } catch (err) {
      toast.error("Data coludn't be updated");
      setIsLoader(false);
      console.log("🚀 ~ handleBulkSave ~ err:", err);
    }
  };

  const openViewDetailHandler = (record) => {
    setEditingRecord(record);
    setIsViewDetail(true);
  };

  const columnDefs = [
    { headerName: "#", field: "counter", width: 60, filterable: false },
    { headerName: "DID Number", field: "did_pattern", flex: 1, minWidth: 120 },
    { headerName: "Call Count", field: "total_count", flex: 1, minWidth: 100 },
    {
      headerName: "Merchant",
      field: "user_group",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      filterable: false,
      getOptionValue: (value) => value?.user_group_id?.user_group,
      getOptionLabel: (value) => value?.company_name,
      valueOptions: merchants?.filter((item) => !!item?.user_group_id),
      renderCell: (params) => params.value?.user_group,
      valueGetter: (params) => params.row?.user_group,
      valueFormatter: (params) => params?.value,
    },
    {
      headerName: "Affiliate Name",
      field: "custom_one",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Site Name",
      field: "did_description",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "did_carrier_description",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params?.value?.did_carrier_description,
      valueGetter: (params) => params.row.did_carrier_description,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "DID Route",
      field: "did_route",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: didRoutesOption,
      renderCell: (params) => params.value.did_route,
      valueGetter: (params) => params.row.did_route,
      valueFormatter: (params) => params.value,
      minWidth: 100,
    },
    {
      headerName: "Ring Group",
      field: "group_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      getOptionValue: (value) => value?.group_id,
      getOptionLabel: (value) => value.group_name,
      valueOptions: inbound?.record,
      renderCell: (params) =>
        params.row.inbound_group_name ? (
          <span
            className="hover:underline cursor-pointer"
            onClick={() => {
              const ingroup = inbound?.record?.find(
                ({ group_id }) => group_id === params.row.group_id
              );
              if (!ingroup) return toast.error("No record found");
              openViewDetailHandler({ ...ingroup, type: "Inbound Group" });
            }}
          >
            {params.row.inbound_group_name}
          </span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
      valueGetter: (params) => params.row.group_id || "",
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "External Number",
      field: "extension",
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        params.row.extension ? (
          <span>{params.row.extension}</span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
    },

    {
      headerName: "Call Menu",
      field: "menu_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      getOptionValue: (value) => value?.menu_id,
      getOptionLabel: (value) => value.menu_name,
      valueOptions: callManu,
      renderCell: (params) =>
        params.row.menu_name ? (
          <span
            className="hover:underline cursor-pointer"
            onClick={() => {
              const recordFound = callManu?.find(
                ({ menu_id }) => menu_id === params.row.menu_id
              );
              if (!recordFound) return toast.error("No record found");
              openViewDetailHandler({ ...recordFound, type: "Call Menu" });
            }}
          >
            {params.row.menu_name}
          </span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
      valueGetter: (params) => params.row.menu_id || "",
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "Agent",
      field: "user",
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        params.row.user ? (
          <span>{params.row.user}</span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
    },
    {
      headerName: "Status",
      field: "did_active",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: didStatusOption,
      renderCell: (params) => params.row.status || "",
      valueGetter: (params) => params.row.did_active || "",
      valueFormatter: (params) => params.value,
      minWidth: 100,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_dids({
        sqlwhere: sqlQuerry,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_dids({
          sqlwhere: "",
          page: 1,
          size: paginationModel.pageSize,
        })
      );
      return setSqlQuerry("");
    }
    let searchCondition = [];
    if (filterModel.items.length > 0) {
      filterModel?.items?.forEach((rule) => {
        switch (rule.operator) {
          case "contains":
            searchCondition.push(`${rule.field} LIKE '%${rule.value}%'`);
            break;
          case "startsWith":
            searchCondition.push(`${rule.field} LIKE '${rule.value}%'`);
            break;

          case "endsWith":
            searchCondition.push(`${rule.field} LIKE '%${rule.value}'`);
            break;
          case "equals":
            searchCondition.push(`${rule.field} = '${rule.value}'`);
            break;
          case "is":
            searchCondition.push(`${rule.field} = '${rule.value}'`);
            break;
          case "=":
            searchCondition.push(`${rule.field} = '${rule.value}'`);
            break;
          case "!=":
            searchCondition.push(`${rule.field} != '${rule.value}'`);
            break;
          case "not":
            searchCondition.push(`${rule.field} != '${rule.value}'`);
            break;
          case ">=":
            searchCondition.push(`${rule.field} >= '${rule.value}'`);
            break;
          case ">":
            searchCondition.push(`${rule.field} > '${rule.value}'`);
            break;
          case "<=":
            searchCondition.push(`${rule.field} <= '${rule.value}'`);
            break;
          case "<":
            searchCondition.push(`${rule.field} < '${rule.value}'`);
            break;
          case "isAnyOf":
            searchCondition.push(
              `${rule.field} IN (${rule?.value
                ?.map((d) => `"${d}"`)
                .join(",")})`
            );
            break;
          case "isEmpty":
            searchCondition.push(`${rule.field} IS NULL OR ${rule.field} = ""`);
            break;
          case "isNotEmpty":
            searchCondition.push(`${rule.field} IS NOT NULL`);
            break;
          case "yes":
            searchCondition.push(`${rule.field} = Y`);
            break;
          case "no":
            searchCondition.push(`${rule.field} = N`);
            break;
          default:
        }
      });
    }
    const searchStr =
      searchCondition.length > 0
        ? searchCondition.join(` ${filterModel?.logicOperator.toUpperCase()} `)
        : ``;
    setSqlQuerry(searchStr);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoader || isLoading ? <DotsLoader /> : null}
      {isViewDetail && (
        <RouteDetailView
          data={editingRecord}
          modalTitle={`${editingRecord?.type} Detail`}
          onCancelForm={cancelFormHandler}
          callManu={callManu}
        />
      )}
      <div className="bg-white my-1 rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={affiliateData?.map((record, index) => {
            let counter = index + 1;
            let status = record.did_active === "Y" ? "Active" : "InActive";
            const {
              extension,
              menu_name,
              did_id,
              inbound_group_name,
              did_route,
              did_description,
              did_pattern,
              group_id,
              menu_id,
              did_active,
              total_count,
              did_carrier_description,
              user_group,
              user,
              custom_one,
            } = record;
            return {
              counter,
              records: { ...record, _id: did_id },
              did_pattern,
              did_description,
              custom_one:
                affiliate?.find(({ _id }) => _id === custom_one)?.name || "",
              did_route:
                did_route === "IN_GROUP"
                  ? "Ring Group"
                  : did_route === "EXTEN"
                  ? "External Number"
                  : did_route === "CALLMENU"
                  ? "Call Menu"
                  : did_route,
              inbound_group_name:
                did_route === "IN_GROUP" ? inbound_group_name : "",
              group_id,
              status,
              total_count,
              extension:
                did_route === "EXTEN" && extension
                  ? extension?.replace(
                      user?.conexcs_settings?.dial_out_prefix,
                      ""
                    )
                  : "",
              user_group,
              menu_name: did_route === "CALLMENU" ? menu_name : "",
              menu_id,
              did_active,
              did_carrier_description: industryDrd?.find(
                ({ _id }) => _id === did_carrier_description
              )?.name,
              user: dialerUsers?.find(({ value }) => value === user)?.label,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          paginationMode="server"
          toolbar="No"
          displayCount="No"
          searchable="No"
          height="25vh"
          toolbarProps={{
            isHideColumnButton: true,
            isHideDensityButton: true,
            isBulkUpdate: true,
            isSelectAll: isSelectAll,
            setIsSelectAll: setIsSelectAll,
            setSelectedData: setSelectedData,
            showSelectAll: true,
            selectedItem: isSelectAll
              ? record?.totalItems
              : selectedData?.length,
            totalItems: record?.totalItems,
            selectedPayload: selectedPayload,
            handleBulkSave: handleBulkSave,
            onCancel: onCancel,
          }}
          hideFooter
        />
      </div>
    </>
  );
};

export default DidsListing;

import React, { useState, useRef, useEffect, useCallback } from "react";
import styles from "./style.module.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineBell } from "react-icons/ai";
import { FaUserCircle, FaBars, FaSearch } from "react-icons/fa";
import { get_roles } from "../../../features/rolesSlice";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdError, MdAttachFile } from "react-icons/md";
import {
  get_conversation,
  smsCenterReset,
  mark_as_read_new,
  resetConversation,
  send_message,
  setMessCurrentPage,
  setChatCurrentPage,
  get_recent_message_list_onload,
  setNewMessCurrentPage,
  updateSocketMessages,
  resetSocketMessages,
  removeSocketMessage,
} from "../../../features/smsCenterSlice.js";
import dayjs from "dayjs";
import ChatHeads from "./Chats";
import MsgContacts from "./Users";
import "intersection-observer";
import CustomMsgModal from "./CustomMsgModal";
import GroupChatHeads from "./GroupChatHeads";
import { throttle } from "lodash";
import NewMessage from "./NewMessage";
import SmsAutocomplete from "./SmsAutoComplete";
import socket from "../SMS/socket";
import ImageUpload from "./MultipleImageUpload.jsx";
import { FaFilePdf, FaFileWord, FaFileCode, FaFileAlt } from "react-icons/fa";
function SmsCenter(props) {
  const [userId, setUserId] = useState(null);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [msg, setMsg] = useState();
  const bottomRef = useRef();
  const chatWindowRef = useRef(null);
  const messageRef = useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoadChat, setIsLoadChat] = useState(false);
  const [isChatEnd, setIsChatEnd] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isUserPhone, setUserPhone] = useState("");
  const [isHiddenPhones, setHiddenPhones] = useState([]);
  const [isMarkasRead, setMarkasRead] = useState(false);
  const [imageupload, setImageUpload] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const {
    isLoading,
    recentSmsMsg,
    conversation,
    userList,
    currentChatPage,
    recentSmsMsgonLoad,
    socketMessages,
  } = useSelector((state) => state.smsCenter);
  const [isCustom, setIsCustom] = useState(false);
  const [newMsg, setNewMsg] = useState(false);
  const [msgType, setMsgType] = useState("Chats");
  const [userType, setUserType] = useState("Agent");
  const [chatFilter, setChatFilter] = useState("all");
  const [hideContacts, setHideContacts] = useState(true);
  const dispatch = useDispatch();
  const [multiArray, setMultiArray] = useState(recentSmsMsg?.data || []);
  const [newMessArray, setNewMessArray] = useState(recentSmsMsg?.data || []);
  const [isChatArray, setChatArray] = useState(conversation?.data || []);
  const [isSearch, setIsSearch] = useState(false);
  const [isChatSearch, setIsChatSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const showContacts = () => {
    setHideContacts(!hideContacts);
  };

  const eventName = user?.current_merchant_id + "_newmessage";
  useEffect(() => {
    // Ensure user is defined before trying to use current_merchant_id
    if (!user?.current_merchant_id) return;

    const handleNewMessage = (message) => {
      dispatch(updateSocketMessages(message));
    };

    // Connect only if not already connected
    if (!socket.connected) {
      socket.connect();
    }

    const setupEventListeners = () => {
      socket.emit("join", eventName);
      socket.on(eventName, handleNewMessage);
    };

    // Setup event listeners based on the connection status
    if (socket.connected) {
      setupEventListeners();
    } else {
      socket.on("connect", setupEventListeners);
    }

    return () => {
      socket.off(eventName, handleNewMessage);
      socket.off("connect", setupEventListeners);
      // Disconnect only if connected
      if (socket.connected) {
        socket.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.current_merchant_id]);

  const getData = async () => {
    try {
      await dispatch(get_roles());
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (recentSmsMsgonLoad?.length === 0) {
      setHiddenPhones([]);
      setUserPhone("");
      dispatch(get_recent_message_list_onload({ page: 1, user_type: "" }));
    }
    // eslint-disable-next-line
  }, []);

  const handleDefatulData = async (chatFilterSlug, user_phone = null) => {
    setSearchText("");
    let filter = {};
    setIsEnd(false);
    if (chatFilterSlug === "all") {
      filter = { user_type: "" };
    } else if (chatFilterSlug === "unread") {
      filter = { unread: true };
    } else if (chatFilterSlug === "vendor") {
      filter = { user_type: "Vendor" };
    } else if (chatFilterSlug === "client") {
      filter = { user_type: "Client" };
    }

    try {
      const res = await dispatch(
        get_recent_message_list_onload({
          page: 1,
          ...filter,
          ...(user_phone ? { user_phone: user_phone } : {}),
        })
      );

      if (res?.payload?.status === 200) {
        const data = res?.payload?.data?.data;
        if (data?.length) {
          setMultiArray(data);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
    }
  };

  const [lastMsgId, setLastMsgId] = useState(null);
  const unreadRef = useRef();

  const unreadMsg = (
    isChatArray?.filter((msg) => {
      if (msg?.createdBy?._id) {
        return msg?.createdBy?._id !== user?._id;
      }
      if (msg?.createdBy) {
        return msg?.createdBy !== user?._id;
      }
      return true;
    }) || []
  ).sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));

  const getReadByData = (unreadMsg, isUserPhone, user) => {
    const allMessagesMatch = unreadMsg.every(
      (msg) =>
        msg.user_phone === isUserPhone &&
        msg.read_by?.some((reader) => reader?.user_id?.id === user?._id)
    );

    if (allMessagesMatch) {
      return unreadMsg.map((msg) => msg.read_by || []);
    } else {
      return [];
    }
  };
  const readUserMess = getReadByData(unreadMsg, isUserPhone, user);
  React.useEffect(() => {
    const chatWindow = chatWindowRef.current;
    const isAtBottom =
      chatWindow.scrollTop ===
      chatWindow.scrollHeight - chatWindow.clientHeight;
    if (!isLoadChat && isAtBottom) {
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    } else {
      console.log("not at bottom of the window");
    }
    // eslint-disable-next-line
  }, [isChatArray?.length]);

  React.useEffect(() => {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    if (!isLoadChat) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [isChatArray?.length]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Call your API to mark the message as read using the messageId
            // onMarkAsRead(messageId); example api for read
          }
        });
      },
      { threshold: 0.5 }
    );

    const messageNodes =
      chatWindowRef.current.querySelectorAll("[data-message-id]");
    messageNodes.forEach((node) => observer.observe(node));

    const handleScroll = () => {
      observer.disconnect();
      messageNodes.forEach((node) => observer.observe(node));
    };

    const currentChatWindowRef = chatWindowRef.current;
    currentChatWindowRef.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      currentChatWindowRef.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const findMessageIndex = (conversation) => {
    if (!lastMsgId || !conversation) {
      return -1;
    }
    return isChatArray.findIndex((msg) => msg.createdAt === lastMsgId);
  };
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    const conversation = isChatArray || [];
    const index = findMessageIndex(conversation);
    if (index !== -1) {
      const messageElement = chatWindow.children[index];
      const messagePosition = messageElement.offsetTop;
      chatWindow.scrollTop = messagePosition;
    }
    // eslint-disable-next-line
  }, [isChatArray]);

  const handleLoadMoreChat = async () => {
    if (isLoader || isChatEnd) return;
    if (!userId?.user_phone) {
      console.log("No user phone, skipping API call");
      return;
    }

    setIsLoader(true);

    let filter = {};
    if (chatFilter === "all") {
      filter = { user_type: "" };
    } else if (chatFilter === "unread") {
      filter = { unread: true };
    } else if (chatFilter === "vendor") {
      filter = { user_type: "Vendor" };
    } else if (chatFilter === "client") {
      filter = { user_type: "Client" };
    }
    let conversationPayload = {
      user_phone: userId?.user_phone,
      ...(!newMsg ? { ...filter } : { user_type: userType }),
      page: currentChatPage,
    };

    try {
      const res = await dispatch(get_conversation(conversationPayload));
      if (res?.payload?.status === 200) {
        const data = res?.payload?.data?.data;

        if (data?.length) {
          setChatArray((prevData) => [...data, ...prevData]);
          await dispatch(setChatCurrentPage(currentChatPage + 1));
          const lastMsg = data[data.length - 1]?.createdAt;
          setLastMsgId(lastMsg);
          dispatch(removeSocketMessage(userId?.user_phone));
        } else {
          setIsChatEnd(true);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setIsLoader(false);
    }
  };

  const handleScroll = throttle(() => {
    const container = chatWindowRef.current;
    // Trigger load more if we're near the top of the container (for infinite scroll up)
    if (
      container.scrollTop === 0 &&
      !isChatEnd &&
      !isLoader &&
      isChatArray?.length
    ) {
      handleLoadMoreChat();
    }
  }, 300);

  // Effect to handle scroll event listener
  useEffect(() => {
    const container = chatWindowRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [isLoader, isChatEnd, currentChatPage]);

  useEffect(() => {
    if (userId?.user_phone) {
      setChatArray([]);
      dispatch(setChatCurrentPage(1));
      setIsChatEnd(false);
      handleLoadMoreChat();
    }
    // eslint-disable-next-line
  }, [userId?.user_phone]);
  useEffect(() => {
    async function readMsg() {
      if (unreadMsg?.length > 0) {
        unreadRef.current?.scrollIntoView({ behavior: "smooth" });

        const unReadMsgIds = unreadMsg.map((msg) => msg?._id);
        const action = msgType === "Chats" ? mark_as_read_new : null;
        if (action && !readUserMess?.length) {
          setMarkasRead(true);
          try {
            await dispatch(action({ message_ids: unReadMsgIds }));
            setUserPhones(isUserPhone);
            let filter = {};
            if (chatFilter === "all") {
              filter = { user_type: "" };
            } else if (chatFilter === "unread") {
              filter = { unread: true };
            } else if (chatFilter === "vendor") {
              filter = { user_type: "Vendor" };
            } else if (chatFilter === "client") {
              filter = { user_type: "Client" };
            }
            setTimeout(() => {
              reloadCurrentChat(filter);
              setMarkasRead(false);
            }, 500);
          } catch (err) {
            console.error("Error marking messages as read:", err);
          }
        }
      }
    }

    readMsg();
    // eslint-disable-next-line
  }, [unreadMsg?.length, msgType]);

  const setUserPhones = (phone) => {
    setHiddenPhones((prev) =>
      prev.includes(phone)
        ? prev.filter((id) => id !== phone)
        : [...prev, phone]
    );
  };

  const reloadCurrentChat = async (filter = null, searchText = null) => {
    setIsLoader(true);
    let conversationPayload = {
      user_phone: userId?.user_phone,
      ...(filter ? { ...filter } : { user_type: "Custom" }),
      ...(searchText ? { search_text: searchText } : {}),
      page: 1,
    };
    try {
      const res = await dispatch(get_conversation(conversationPayload));

      if (res?.payload?.status === 200) {
        const data = res?.payload?.data?.data;
        if (data?.length) {
          setChatArray(data);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setIsLoader(false);
    }
  };

  const handleMessage = async (event) => {
    event.preventDefault();
    if (userId?.user_type !== "Group") {
      try {
        const payload = {
          ...(!newMsg
            ? {
                user_id:
                  userId?.user_type === "User"
                    ? userId?.user_id?._id
                    : userId?.user_type === "Client"
                    ? userId?.client_id?._id
                    : userId?.user_type === "Vendor"
                    ? userId?.vendor_id?._id
                    : "",
                custom_phone: userId?.user_phone,
                user_type: userId?.user_type,
              }
            : {
                user_type: "Custom",
                custom_phone: userId?.user_phone,
                user_id: userId?._id,
              }),
        };
        const mediaUrls = filesData ? filesData.map((file) => file.path) : [];
        const res = await dispatch(
          send_message({
            message: msg,
            ...payload,
            ...(mediaUrls.length > 0 && { mediaUrls }),
          })
        );
        if (res.payload?.status === 201) {
          setMsg("");
          reloadCurrentChat();
          dispatch(setChatCurrentPage(2));
          setFilesData([]);
        }
      } catch (err) {}
    }
  };

  const [placement, setPlacement] = useState("top");
  const tooltipRef = useRef(null);
  const tooltipContent = useCallback((read_by) => {
    return (
      <div className="flex flex-col" style={{ minWidth: "200px" }}>
        <div className="flex items-center">
          <div className="font-bold mr-2 mb-1">READ BY {read_by?.length}</div>
        </div>
        <div className="mt-2 text-left">
          <ul className="text-sm">
            {read_by?.map((data, index) =>
              data?.user_id ? (
                <li key={data.user_id._id} className="flex items-center mb-2">
                  <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center mr-2">
                    <span className="text-white text-xs">
                      {data.user_id.first_name?.charAt(0).toUpperCase()}
                      {data.user_id.last_name?.charAt(0).toUpperCase()}
                    </span>
                  </div>
                  <span
                    className="text-white text-xs "
                    style={{ fontSize: "10px" }}
                  >
                    {data.user_id.first_name} {data.user_id.last_name} |{" "}
                    {dayjs(data?.read_at).format("h:mm A")}
                  </span>
                </li>
              ) : null
            )}
          </ul>
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    if (tooltipRef.current) {
      const contentWidth = tooltipRef.current.offsetWidth;
      if (contentWidth > 200) {
        // Adjust the threshold as needed
        setPlacement("top-start");
      } else {
        setPlacement("top-end");
      }
    }
  }, [tooltipContent]);

  const handleSelect = (selectedItem) => {
    let userTtype;
    if (chatFilter === "vendor") {
      userTtype = "vendor";
    } else if (chatFilter === "client") {
      userTtype = "client";
    } else {
      userTtype = "all";
    }
    setMultiArray([]);
    dispatch(smsCenterReset());
    dispatch(setMessCurrentPage(2));
    handleDefatulData(userTtype, selectedItem?.phone);
  };

  const handleChatSearch = () => {
    // dispatch(setChatCurrentPage(2));
    setUserPhone(userId?.user_phone);
    setIsLoadChat(false);
    setChatArray([]);
    setIsChatEnd(false);
    let filter = {};
    if (chatFilter === "all") {
      filter = { user_type: "" };
    } else if (chatFilter === "unread") {
      filter = { unread: true };
    } else if (chatFilter === "vendor") {
      filter = { user_type: "Vendor" };
    } else if (chatFilter === "client") {
      filter = { user_type: "Client" };
    }

    reloadCurrentChat(filter, searchText);
  };

  const clearChatConversation = async () => {
    setSearchText("");
    setIsChatSearch(false);
    setIsChatEnd(false);
  };

  const clearChatOnCancel = async () => {
    setChatArray([]);
    setSearchText("");
    let filter = {};
    if (chatFilter === "all") {
      filter = { user_type: "" };
    } else if (chatFilter === "unread") {
      filter = { unread: true };
    } else if (chatFilter === "vendor") {
      filter = { user_type: "Vendor" };
    } else if (chatFilter === "client") {
      filter = { user_type: "Client" };
    }
    reloadCurrentChat(filter);
  };

  //get socket message isUserPhone
  const matchedSocketArr = socketMessages?.filter(
    (item) => item?.user_phone === isUserPhone
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchText) {
        handleChatSearch();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [searchText]);

  const TextWithLinks = ({ text, searchText }) => {
    // Regular expression to match URLs
    const urlRegex = /https?:\/\/[^\s]+/g;

    // Function to highlight text
    const highlightText = (message, searchText) => {
      if (!searchText) return message;
      const regex = new RegExp(`(${searchText})`, "gi");
      return message?.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span key={index} className="bg-yellow-500 text-black rounded-sm">
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    // Split the text by URLs, and insert <a> tags where URLs are found
    const parts = text.split(urlRegex).map((part, index) => {
      // Check if the part matches a URL
      if (urlRegex.test(text.slice(text.indexOf(part)))) {
        // Extract the URL to create a clickable link
        const urlMatch = text.match(urlRegex)[index];
        return (
          <>
            {highlightText(part, searchText)}
            <a
              key={index}
              href={urlMatch}
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:text-yellow-200"
            >
              {highlightText(urlMatch, searchText)}
            </a>
          </>
        );
      }
      return highlightText(part, searchText);
    });

    return <p>{parts}</p>;
  };

  const imageHandler = () => {
    setImageUpload(true);
  };

  const handleUploadSuccess = (uploadedFilesData) => {
    setFilesData(uploadedFilesData.files);
  };

  const getFileIcon = (fileUrl) => {
    const fileExtension = fileUrl.split(".").pop().toLowerCase();
    const fullFileUrl = fileUrl.startsWith("http")
      ? fileUrl
      : `https://${fileUrl}`;
    if (
      fileExtension.startsWith("jpg") ||
      fileExtension.startsWith("jpeg") ||
      fileExtension.startsWith("png") ||
      fileExtension.startsWith("gif") ||
      fileExtension.startsWith("bmp")
    ) {
      return (
        <a href={fullFileUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={fullFileUrl}
            alt="preview"
            className="w-fit h-15 object-cover rounded-md"
          />
        </a>
      );
    }

    if (fileExtension === "pdf") {
      return (
        <a href={fullFileUrl} target="_blank" rel="noopener noreferrer">
          <FaFilePdf size={40} color="#E63946" />
        </a>
      );
    }

    if (fileExtension === "docx") {
      return (
        <a href={fullFileUrl} target="_blank" rel="noopener noreferrer">
          <FaFileWord size={40} color="#4CAF50" />
        </a>
      );
    }

    if (fileExtension === "json") {
      return (
        <a href={fullFileUrl} target="_blank" rel="noopener noreferrer">
          <FaFileCode size={40} color="#FF5722" />
        </a>
      );
    }
    return (
      <a href={fullFileUrl} target="_blank" rel="noopener noreferrer">
        <FaFileAlt size={40} color="#9E9E9E" />
      </a>
    );
  };

  return (
    <>
      <div className="fixed w-[90%] md:w-[1024px] z-[101] left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] max-h-[600px]">
        <div
          className="absolute -right-3.7 -top-3.7 w-[34px] h-[34px] bg-white rounded-full flex justify-center items-center cursor-pointer drop-shadow"
          onClick={() => {
            dispatch(setChatCurrentPage(1));
            dispatch(resetConversation());
            props.setIsSMSCenter(false);
            dispatch(setMessCurrentPage(2));
            dispatch(smsCenterReset([]));
            setUserPhone("");
            setHiddenPhones([]);
            dispatch(setNewMessCurrentPage(1));
            dispatch(resetSocketMessages());
          }}
        >
          X
        </div>
        <div className="max-w-[1024px] h-[600px] bg-[#E6EAEA] rounded">
          {isCustom && (
            <CustomMsgModal
              onClosemodal={() => setIsCustom(false)}
              newMsg={newMsg}
              setNewMsg={setNewMsg}
              setUserType={setUserType}
              setUserId={setUserId}
              setUserPhone={setUserPhone}
              setIsLoadChat={setIsLoadChat}
              setChatArray={setChatArray}
              setIsChatEnd={setIsChatEnd}
              clearChatConversation={clearChatConversation}
            />
          )}

          <div
            className={`float-left ${
              hideContacts ? "hidden" : ""
            }  md:block min-w-[280px] max-w-[350px] w-[45%] h-full !bg-white text-[#333] overflow-hidden relative border-r border-[#ccc]`}
          >
            <NewMessage
              setIsCustom={setIsCustom}
              newMsg={newMsg}
              setNewMsg={setNewMsg}
              msgType={msgType}
              userType={userType}
              setUserType={setUserType}
              setChatArray={setChatArray}
              setIsEnd={setIsEnd}
              newMessArray={newMessArray}
              setNewMessArray={setNewMessArray}
              setUserPhone={setUserPhone}
              setUserId={setUserId}
              isHiddenPhones={isHiddenPhones}
              isEnd={isEnd}
              setMultiArray={setMultiArray}
              setChatFilter={setChatFilter}
              handleDefatulData={handleDefatulData}
              socketMessages={socketMessages}
              reloadCurrentChat={reloadCurrentChat}
              chatFilter={chatFilter}
              setUserPhones={setUserPhones}
              setChatCurrentPage={setChatCurrentPage}
              setIsLoadChat={setIsLoadChat}
              setIsChatEnd={setIsChatEnd}
              clearChatConversation={clearChatConversation}
            />
            {!newMsg ? (
              <>
                {msgType === "Chats" ? (
                  <>
                    <div className="flex flex-row items-center w-full border-y border-1 bg-[#f7f8f8] p-[6px] pl-3.7 !pr-4">
                      {!isSearch ? (
                        <ul className="flex flex-row items-center w-full !mb-0 !pl-0">
                          <li
                            className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                              chatFilter === "all"
                                ? "border-b-2 border-primary-100 text-primary-100"
                                : ""
                            }`}
                            onClick={() => {
                              setMultiArray([]);
                              dispatch(smsCenterReset());
                              setChatFilter("all");
                              dispatch(setMessCurrentPage(2));
                              handleDefatulData("all");
                            }}
                          >
                            All
                          </li>
                          <li
                            className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                              chatFilter === "unread"
                                ? "border-b-2 border-primary-100 text-primary-100"
                                : ""
                            }`}
                            onClick={() => {
                              // setMultiArray([]);
                              handleDefatulData("unread");
                              dispatch(smsCenterReset());
                              setChatFilter("unread");
                              dispatch(setMessCurrentPage(2));
                            }}
                          >
                            Unread
                          </li>
                          <li
                            className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                              chatFilter === "vendor"
                                ? "border-b-2 border-primary-100 text-primary-100"
                                : ""
                            }`}
                            onClick={() => {
                              //setMultiArray([]);
                              dispatch(smsCenterReset());
                              setChatFilter("vendor");
                              dispatch(setMessCurrentPage(2));
                              handleDefatulData("vendor");
                            }}
                          >
                            Vendor
                          </li>
                          <li
                            className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                              chatFilter === "client"
                                ? "border-b-2 border-primary-100 text-primary-100"
                                : ""
                            }`}
                            onClick={() => {
                              // setMultiArray([]);
                              dispatch(smsCenterReset());
                              setChatFilter("client");
                              dispatch(setMessCurrentPage(2));
                              handleDefatulData("client");
                            }}
                          >
                            Client
                          </li>
                        </ul>
                      ) : (
                        <SmsAutocomplete
                          apiUrl="/api/message_center/drd_phone_list"
                          onSelect={handleSelect}
                          getLabel={(item) => item.name}
                          getValue={(item) => item.phone}
                          placeholder="Search Phone & Text"
                          debounceDelay={500}
                          chatFilter={chatFilter}
                        />
                      )}

                      <div
                        className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer flex justify-end`}
                        onClick={() => {
                          if (isSearch) {
                            let userTtype;
                            if (chatFilter === "vendor") {
                              userTtype = "vendor";
                            } else if (chatFilter === "client") {
                              userTtype = "client";
                            } else {
                              userTtype = "all";
                            }
                            setMultiArray([]);
                            dispatch(smsCenterReset());
                            dispatch(setMessCurrentPage(2));
                            handleDefatulData(userTtype);
                          }
                          setIsSearch(!isSearch);
                        }}
                      >
                        {!isSearch ? (
                          <FaSearch className="w-4 h-4 " />
                        ) : (
                          <button className="text-sm font-semibold">
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>

                    <ChatHeads
                      userId={userId}
                      setUserId={setUserId}
                      userType={userType}
                      chatFilter={chatFilter}
                      onClick={showContacts}
                      setIsLoadChat={setIsLoadChat}
                      setIsChatEnd={setIsChatEnd}
                      multiArray={multiArray}
                      setMultiArray={setMultiArray}
                      recentSmsMsgData={recentSmsMsg}
                      setChatArray={setChatArray}
                      setIsEnd={setIsEnd}
                      isEnd={isEnd}
                      setUserPhone={setUserPhone}
                      isHiddenPhones={isHiddenPhones}
                      isUserPhone={isUserPhone}
                      clearChatConversation={clearChatConversation}
                      isMarkasRead={isMarkasRead}
                    />
                  </>
                ) : msgType === "Groups" ? (
                  <GroupChatHeads
                    userId={userId}
                    setUserId={setUserId}
                    setIsLoadChat={setIsLoadChat}
                  />
                ) : null}
              </>
            ) : (
              <MsgContacts
                newMessArray={newMessArray}
                userId={userId}
                setUserId={setUserId}
                type={userType}
                setChatArray={setChatArray}
                setIsLoadChat={setIsLoadChat}
                setIsChatEnd={setIsChatEnd}
                setUserPhone={setUserPhone}
                setNewMessArray={setNewMessArray}
                isMarkasRead={isMarkasRead}
              />
            )}
          </div>

          <div className="z-[100] min-w-[calc(100%-300px)] w-[100%]">
            <div className="flex flex-row items-center bg-white p-[6px] h-[42px] border-b border-[#ccc] justify-between">
              <div className="flex items-center">
                <FaBars
                  className="block md:hidden cursor-pointer w-[24px]"
                  onClick={showContacts}
                />
                <span
                  className={`${
                    hideContacts ? "block" : "hidden"
                  } md:block px-[6px] border-r-2 border-black text-sm`}
                >
                  {userId?.user_type === "User"
                    ? userId?.user_id?.first_name
                    : userId?.user_type === "Vendor"
                    ? userId?.vendor_id?.first_name
                    : userId?.user_type === "Group"
                    ? userId?.vendor_id?.first_name
                    : userId?.client_id?.name}{" "}
                  {userId?.user_type === "User"
                    ? userId?.user_id?.last_name
                      ? userId?.user_id?.last_name
                      : ""
                    : userId?.client_id?.last_name
                    ? userId?.client_id?.last_name
                    : userId?.user_type === "Vendor"
                    ? userId?.vendor_id?.last_name
                      ? userId?.vendor_id?.last_name
                      : ""
                    : userId?.client_id?.last_name
                    ? userId?.client_id?.last_name
                    : userId?.user_type === "Group"
                    ? userId?.vendor_id?.last_name
                      ? userId?.vendor_id?.last_name
                      : ""
                    : userId?.client_id?.last_name
                    ? userId?.client_id?.last_name
                    : ""}
                </span>
                <span
                  className={`${
                    hideContacts ? "block" : "hidden"
                  } md:block px-[6px] border-r-2 border-black text-sm`}
                >
                  {userId?.user_phone}
                </span>
                <span
                  className={`${
                    hideContacts ? "block" : "hidden"
                  } md:block text-[#ccc] ml-1 text-sm`}
                >
                  {userId
                    ? userId?.user_type === "User"
                      ? userId?.role?.name
                      : userId?.user_type === "Client"
                      ? "Client"
                      : userId?.user_type === "Vendor"
                      ? "Vendor"
                      : userId?.user_type === "Group"
                      ? "Group"
                      : "Custom"
                    : ""}
                </span>
                {userId && userList?.length > 0 && (
                  <FaUserCircle
                    size={20}
                    className={`${
                      hideContacts ? "block" : "hidden"
                    } md:block ml-1 cursor-pointer`}
                    color="#999"
                  />
                )}
              </div>
              {isUserPhone ? (
                <>
                  {isChatSearch ? (
                    <div className="relative !my-2 ml-5 ">
                      <input
                        type="text"
                        className="border-b shadow-sm appearance-none   w-[250px] !py-2 !pr-2  text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm !pl-5 h-[30px]"
                        placeholder="Search messages..."
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleChatSearch();
                          }
                        }}
                      />
                    </div>
                  ) : null}

                  <div
                    className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer flex justify-end mr-5`}
                    onClick={() => setIsChatSearch(!isChatSearch)}
                  >
                    {!isChatSearch ? (
                      <FaSearch className="w-4 h-4 " />
                    ) : (
                      <button
                        onClick={() => {
                          if (!searchText) {
                            clearChatConversation();
                          } else {
                            clearChatOnCancel();
                          }
                        }}
                        className="text-sm font-semibold"
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </>
              ) : null}
            </div>

            <div
              className={`${
                hideContacts ? "block" : "hidden"
              } md:block !p-4 h-[488px] overflow-y-auto scrollbar-custom`}
              ref={chatWindowRef}
            >
              {isChatArray
                ?.slice()
                ?.reverse()
                ?.map((msg, index) => {
                  const sender_id = msg?.createdBy?._id;
                  const seenUserIds = new Set();
                  const filteredReadBy = msg?.read_by?.filter((item) => {
                    const userId = item.user_id._id;
                    if (userId === sender_id || userId === user._id) {
                      return false;
                    }
                    if (!seenUserIds.has(userId)) {
                      seenUserIds.add(userId);
                      return true;
                    }
                    return false;
                  });
                  return (
                    <div key={index}>
                      {msg?.message_type === "Received" &&
                      readUserMess?.length ? (
                        <>
                          <div
                            className="inline-block w-[calc(100%-25px)] !m-1"
                            ref={messageRef}
                            data-message-id={msg._id}
                          >
                            <p className="bg-menu text-white float-left max-w-[80%] w-[60%] rounded-t-2xl rounded-r-2xl !py-2 px-3.7">
                              <span className="flex justify-between !mb-2 font-bold text-base">
                                {msg?.user_type === "User"
                                  ? userId?.user?.first_name
                                  : msg?.user_type === "Client"
                                  ? userId?.client?.first_name
                                  : msg?.user_type === "Vendor"
                                  ? userId?.vendor?.first_name
                                  : ""}
                              </span>

                              <span className="text-sm !mb-2 break-words">
                                {msg?.message ? (
                                  <TextWithLinks
                                    text={msg.message}
                                    searchText={searchText}
                                  />
                                ) : null}
                              </span>
                              {filteredReadBy?.length ? (
                                <div className="relative flex items-center mt-2">
                                  <OverlayTrigger
                                    placement={placement}
                                    overlay={
                                      <Tooltip
                                        id="tooltip-custom"
                                        ref={tooltipRef}
                                        className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
                                      >
                                        {tooltipContent(filteredReadBy)}
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      id="badge-dismiss-yellow"
                                      className="inline-flex items-center px-0 py-0 me-2 text-xs font-medium text-yellow-300 dark:text-yellow-300 cursor-pointer"
                                    >
                                      read by +{filteredReadBy?.length}
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              ) : null}
                              {!msg?.mediaUrls?.length ? (
                                <div className="text-end mt-2 text-xs">
                                  {dayjs(msg.createdAt).format(
                                    "ddd, MMM D, YYYY h:mm A"
                                  )}
                                </div>
                              ) : null}
                            </p>
                            {msg?.mediaUrls?.length ? (
                              <div className="float-left max-w-[80%] w-[60%] mt-2">
                                <div
                                  className={`flex flex-wrap gap-4 ${
                                    msg?.mediaUrls?.length < 3
                                      ? "justify-end"
                                      : "justify-start"
                                  }`}
                                >
                                  {msg?.mediaUrls?.map((file, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="text-white w-1/4 rounded-t-xl rounded-l-xl py-2 px-3.7 border border-gray-950"
                                      >
                                        <div className="text-center flex flex-col justify-center items-center">
                                          {getFileIcon(file)}
                                          <p className="mt-2 text-xs text-gray-700 break-words">
                                            {file.name}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null}
                            {msg?.mediaUrls?.length ? (
                              <div className="text-end mt-2 text-xs float- max-w-[80%] w-[60%] font-semibold">
                                {dayjs(msg.createdAt).format(
                                  "ddd, MMM D, YYYY h:mm A"
                                )}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : msg?.message_type === "Sent" &&
                        (readUserMess?.length ||
                          msg?.createdBy === user?._id ||
                          msg?.createdBy?._id === user?._id) ? (
                        <div className="inline-block w-[calc(100%-25px)] !m-1">
                          <p className="bg-client-100 text-white float-right max-w-[80%] w-[60%] rounded-t-2xl rounded-l-2xl !py-2 px-3.7">
                            <span className="text-xs !mb-1 break-words block  text-yellow-300">
                              {msg?.createdBy?._id
                                ? `${msg?.createdBy?.first_name} ${msg?.createdBy?.last_name}`
                                : "BY SYSTEM"}
                            </span>
                            <span className="text-sm break-words">
                              {" "}
                              <TextWithLinks
                                text={msg.message}
                                searchText={searchText}
                              />{" "}
                            </span>

                            <div className="flex justify-between text-xs">
                              {filteredReadBy?.length ? (
                                <div className="relative flex items-center mt-2">
                                  <OverlayTrigger
                                    placement={placement}
                                    overlay={
                                      <Tooltip
                                        id="tooltip-custom"
                                        ref={tooltipRef}
                                        className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
                                      >
                                        {tooltipContent(filteredReadBy)}
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      id="badge-dismiss-yellow"
                                      className="inline-flex items-center px-0 py-0 me-2 text-xs font-medium text-yellow-300 dark:text-yellow-300 cursor-pointer"
                                    >
                                      read by +{filteredReadBy?.length}
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              ) : null}
                            </div>
                            {!msg?.mediaUrls?.length ? (
                              <div className="text-end mt-2 text-xs">
                                {dayjs(msg.createdAt).format(
                                  "ddd, MMM D, YYYY h:mm A"
                                )}
                              </div>
                            ) : null}
                          </p>
                          {msg?.mediaUrls?.length ? (
                            <div className="float-right max-w-[80%] w-[60%] mt-2">
                              <div
                                className={`flex flex-wrap gap-4 ${
                                  msg?.mediaUrls?.length < 3
                                    ? "justify-end"
                                    : "justify-start"
                                }`}
                              >
                                {msg?.mediaUrls?.map((file, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="text-white w-1/4 rounded-t-xl rounded-l-xl py-2 px-3.7 border border-gray-950"
                                    >
                                      <div className="text-center flex flex-col justify-center items-center">
                                        {getFileIcon(file)}
                                        <p className="mt-2 text-xs text-gray-700 break-words">
                                          {file.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                          {msg?.mediaUrls?.length ? (
                            <div className="text-end mt-2 text-xs float-right max-w-[80%] w-[60%] font-semibold">
                              {dayjs(msg.createdAt).format(
                                "ddd, MMM D, YYYY h:mm A"
                              )}
                            </div>
                          ) : null}
                          {msg?.sentStatus && msg?.sentStatus === "Failed" ? (
                            <span className="flex justify-end w-full">
                              <MdError
                                className="text-red-500 w-5 h-5"
                                title="Message delivery failed!"
                              />
                            </span>
                          ) : null}
                        </div>
                      ) : null}

                      <div ref={bottomRef} />
                    </div>
                  );
                })}

              {unreadMsg?.length ? (
                <div id="chat-container">
                  {!readUserMess?.length ? (
                    <div className="text-center mb-2 flex items-center justify-center space-x-2 mt-5">
                      <div className="flex-grow border-t border-blue-500" />
                      <span
                        className="px-4"
                        style={{ color: "#2d4665" }}
                        ref={unreadRef}
                      >
                        {" "}
                        {unreadMsg?.length} Unread Messages
                      </span>
                      <div className="flex-grow border-t border-blue-500" />
                    </div>
                  ) : null}

                  {unreadMsg?.map((msg, index) => {
                    const sender_id = msg?.createdBy?._id;
                    const seenUserIds = new Set();
                    const filteredReadBy = msg?.read_by?.filter((item) => {
                      const userId = item.user_id._id;
                      if (userId === sender_id || userId === user._id) {
                        return false;
                      }
                      if (!seenUserIds.has(userId)) {
                        seenUserIds.add(userId);
                        return true;
                      }
                      return false;
                    });
                    return (
                      <>
                        {msg?.message_type === "Received" &&
                        !readUserMess?.length ? (
                          <>
                            <div
                              className="inline-block w-[calc(100%-25px)] !m-1"
                              ref={messageRef}
                              data-message-id={msg._id}
                            >
                              <p className="bg-menu text-white float-left max-w-[80%] w-[60%] rounded-t-2xl rounded-r-2xl !py-2 px-3.7">
                                <span className="flex justify-between !mb-2 font-bold text-base">
                                  {msg?.user_type === "User"
                                    ? userId?.user?.first_name
                                    : msg?.user_type === "Client"
                                    ? userId?.client?.first_name
                                    : msg?.user_type === "Vendor"
                                    ? userId?.vendor?.first_name
                                    : ""}
                                </span>
                                <span className="text-sm !mb-2 break-words">
                                  <TextWithLinks
                                    text={msg.message}
                                    searchText={searchText}
                                  />{" "}
                                </span>
                                {filteredReadBy?.length ? (
                                  <div className="relative flex items-center mt-2">
                                    <OverlayTrigger
                                      placement={placement}
                                      overlay={
                                        <Tooltip
                                          id="tooltip-custom"
                                          ref={tooltipRef}
                                          className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
                                        >
                                          {tooltipContent(filteredReadBy)}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        id="badge-dismiss-yellow"
                                        className="inline-flex items-center px-0 py-0 me-2 text-xs font-medium text-yellow-300 dark:text-yellow-300 cursor-pointer"
                                      >
                                        read by +{filteredReadBy?.length}
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                ) : null}
                                <div className="text-xs text-end">
                                  {dayjs(msg.createdAt).format(
                                    "ddd, MMM D, YYYY h:mm A"
                                  )}
                                </div>
                              </p>
                            </div>
                          </>
                        ) : msg?.message_type === "Sent" &&
                          !readUserMess?.length ? (
                          <div className="inline-block w-[calc(100%-25px)] !m-1">
                            <p className="bg-client-100 text-white float-right max-w-[80%] w-[60%] rounded-t-2xl rounded-l-2xl !py-2 px-3.7">
                              <span className="text-xs !mb-1 break-words block  text-yellow-300">
                                {msg?.createdBy?._id
                                  ? `${msg?.createdBy?.first_name} ${msg?.createdBy?.last_name}`
                                  : "BY SYSTEM"}
                              </span>
                              <span className="text-sm break-words">
                                <TextWithLinks
                                  text={msg.message}
                                  searchText={searchText}
                                />{" "}
                              </span>
                              <div className="flex justify-between text-xs">
                                {filteredReadBy?.length ? (
                                  <div className="relative flex items-center mt-2">
                                    <OverlayTrigger
                                      placement={placement}
                                      overlay={
                                        <Tooltip
                                          id="tooltip-custom"
                                          ref={tooltipRef}
                                          className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
                                        >
                                          {tooltipContent(filteredReadBy)}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        id="badge-dismiss-yellow"
                                        className="inline-flex items-center px-0 py-0 me-2 text-xs font-medium text-yellow-300 dark:text-yellow-300 cursor-pointer"
                                      >
                                        read by +{filteredReadBy?.length}
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                ) : null}
                              </div>
                              <div className="text-end mt-2 text-xs">
                                {dayjs(msg.createdAt).format(
                                  "ddd, MMM D, YYYY h:mm A"
                                )}
                              </div>
                            </p>
                            {msg?.sentStatus && msg?.sentStatus === "Failed" ? (
                              <span className="flex justify-end w-full">
                                <MdError
                                  className="text-red-500 w-5 h-5"
                                  title="Message delivery failed!"
                                />
                              </span>
                            ) : null}
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              ) : null}

              {matchedSocketArr?.length ? (
                <div className="fixed bottom-4 right-6 z-50 mb-8">
                  <button
                    className="py-4 px-2 relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out"
                    aria-label="Notifications"
                  >
                    <AiOutlineBell className="h-6 w-6" />
                    <span
                      className="absolute inset-0 object-right-top -mr-6 mt-2 cursor-pointer"
                      onClick={() => {
                        setUserPhones(isUserPhone);
                        dispatch(removeSocketMessage(isUserPhone));
                        let filter = {};
                        if (chatFilter === "all") {
                          filter = { user_type: "" };
                        } else if (chatFilter === "unread") {
                          filter = { unread: true };
                        } else if (chatFilter === "vendor") {
                          filter = { user_type: "Vendor" };
                        } else if (chatFilter === "client") {
                          filter = { user_type: "Client" };
                        }
                        reloadCurrentChat(filter);
                      }}
                    >
                      <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                        {matchedSocketArr?.length}
                      </div>
                    </span>
                  </button>
                </div>
              ) : null}
            </div>
            <form
              onSubmit={handleMessage}
              className="flex flex-row justify-between w-full md:w-[calc(100%-350px)] bottom-0 bg-[#f8f8f8] border-t border-[#ccc] p-3.7 float-right "
            >
              <input
                className="border w-11/12 py-1.5 !px-3 rounded !focus:outline-none"
                onChange={(e) => setMsg(e.target.value)}
                value={msg}
              />
              {imageupload ? (
                <ImageUpload
                  modalTitle={"Send Files"}
                  onCancelForm={() => {
                    setImageUpload(false);
                  }}
                  onUploadSuccess={handleUploadSuccess}
                />
              ) : null}
              <div className="relative">
                <MdAttachFile
                  className="w-6 h-6 ml-2 mr-2 mt-2 cursor-pointer"
                  onClick={() => imageHandler()}
                  title="upload attachments"
                />
                {filesData?.length ? (
                  <span className="absolute top-0 right-0 text-xs text-white bg-red-500 rounded-full px-1 ml-1 mr-2">
                    {filesData?.length}
                  </span>
                ) : null}
              </div>
              <button
                className="float-right bg-[#32465a] w-[40px] !py-1"
                type="submit"
              >
                <RiSendPlaneFill
                  color={"white"}
                  size={20}
                  style={{ color: "#fff" }}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        className={[styles.backdrop, props.open ? "open" : ""].join(" ")}
        onClick={() => {
          dispatch(resetConversation());
          props.setIsSMSCenter(false);
        }}
      ></div>
    </>
  );
}

export default SmsCenter;

import React from "react";
import dayjs from "dayjs";
import { Modal } from "../../../components";
import { FaDownload } from "react-icons/fa";
const EmailDetail = ({ onCancelForm, selectedEmail }) => {
  const attachments = selectedEmail?.attachments ?? [];
  function decodeBase64UrlSafe(base64String) {
    let normalizedBase64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
    while (normalizedBase64.length % 4 !== 0) {
      normalizedBase64 += "=";
    }
    try {
      const byteArray = Uint8Array.from(atob(normalizedBase64), (c) =>
        c.charCodeAt(0)
      );
      const finalResult = new TextDecoder("utf-8").decode(byteArray);
      return finalResult;
    } catch (e) {
      console.error("Invalid Base64 input:", e);
      return null;
    }
  }
  function downloadBase64File(contentType, base64Data, fileName) {
    let linkSource = `data:${contentType};base64,${base64Data}`;
    linkSource = linkSource.replace(/-/g, "+").replace(/_/g, "/");
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  return (
    <>
      <Modal
        onCancelModal={onCancelForm}
        onClick={onCancelForm}
        modalClass="md:min-w-[80vw] !max-h-[calc(100vh-90px)]"
        modalType={true}
        hideHeader
        hideButtons={true}
      >
        <div className="mt- !max-h-[calc(100vh-150px)] overflow-y-scroll">
          <div className="h-full bg-neutral-100">
            <div className="flex justify-between items-center">
              <h5 className="font-bold">
                {selectedEmail && selectedEmail.subject
                  ? selectedEmail.subject
                  : ""}
              </h5>
              <div className="flex gap-2">
                <span>
                  {selectedEmail && selectedEmail.time
                    ? dayjs(selectedEmail.time).format(
                        "ddd, MMM D, YYYY h:mm A"
                      )
                    : ""}
                </span>
              </div>
            </div>
            <div className="flex gap-3 mt-1">
              <span className="font-lg font-semibold">
                {selectedEmail && selectedEmail.from ? selectedEmail.from : ""}
              </span>
            </div>
            <div className="p-2 mt-3">
              <div className="">
                <iframe
                  srcDoc={decodeBase64UrlSafe(selectedEmail?.mailcontent || "")}
                  className="w-full h-[60vh] border-none"
                  sandbox="allow-same-origin allow-scripts allow-popups"
                  title="Email Content"
                />
              </div>
            </div>
            {attachments.length ? (
              <div className="border-t-2 border-dotted border-black pt-2">
                <label className="font-bold">Attachments</label>
                {attachments.map((value, index) => {
                  return (
                    <div className="flex items-center">
                      <p className="text-[14px]">
                        {index + 1}. {value?.filename}
                      </p>
                      <FaDownload
                        className="ml-2 cursor-pointer"
                        onClick={() => {
                          downloadBase64File(
                            value.mimeType,
                            value.data,
                            value.filename
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ):null}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EmailDetail;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getOutboundGroup } from "../services/outboundGroupService";
const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  outboundDrd: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get outbound Group Data
export const get_outbound_group = createAsyncThunk(
  "get_outbound_group",
  async (data, thunkAPI) => {
    try {
      return await getOutboundGroup(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const outboundGroupSlice = createSlice({
  name: "outboundGroup",
  initialState,
  reducers: {
    outboundReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.outboundDrd = [];
      state.message = "";
    },
    update_outbound: (state, action) => {
      state.outboundDrd.data = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_outbound_group.pending, (state) => {
        state.isLoading = true;
        state.outboundDrd = [];
      })
      .addCase(get_outbound_group.fulfilled, (state, action) => {
        state.isLoading = false;
        state.outboundDrd = action.payload?.data;
      })
      .addCase(get_outbound_group.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.outboundDrd = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});

export const { outboundReset, update_outbound } = outboundGroupSlice.actions;
export default outboundGroupSlice.reducer;

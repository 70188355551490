import React, { useState } from "react";

import { MdOutlineMarkEmailRead, MdSaveAs } from "react-icons/md";
import { FaBusinessTime, FaSms } from "react-icons/fa";
import { Button, Loader } from "../../../components";
const ConfirmationModal = ({ onClose, handleCreateJob }) => {
  const [isLoader] = useState(false);
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <h2 className="text-lg font-semibold mb-4 text-center">What you want to do?</h2>
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <div className="flex flex-col w-full justify-between">
              <div className="flex gap-x-3 items-center justify-center mb-4">
                <button
                  title="Notify by Email"
                  className="flex gap-x-1 !py-3 items-center hover:bg-primary-100 hover:text-white px-2 rounded-md border  font-semibold"
                  type="button"
                  onClick={() => handleCreateJob("data")}
                >
                  <MdSaveAs />
                  Save Data
                </button>
                <h2 className="text-lg font-semibold mb-">OR</h2>
                <button
                  title="Create Job"
                  className="flex gap-x-1 !py-3 items-center hover:bg-primary-100 hover:text-white px-2 rounded-md border  font-semibold"
                  type="button"
                  onClick={() => handleCreateJob("job")}
                >
                  <FaBusinessTime />
                  Create Job
                </button>
              </div>
              <Button text="Close" variant="btn_cancel" onClick={onClose} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

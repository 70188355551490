import dayjs from "dayjs";
import React from "react";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";

const LeadByTimeListings = ({ records }) => {
  const columnDefs = [
    {
      headerName: "Lead #",
      field: "lead_num",
      minWidth: 130,
      renderCell: (params) => (
        <div>
          {params.row.lead_num ? (
            <a
              href={`/jobs/update/preview/${params.row.id}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:underline cursor-pointer"
            >
              {`${params.row.lead_num}`}
            </a>
          ) : null}
        </div>
      ),
    },
    { headerName: "Name", field: "name", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 120 },
    { headerName: "Job Amount", field: "goa_price", flex: 1, minWidth: 120 },
    {
      headerName: "Job status",
      field: "job_status",
      flex: 1,
      minWidth: 120,
      filterable: false,
      renderCell: (params) => (
        <div>
          {params?.row?.system_job_status && (
            <span className="bg-blue-100 text-blue-800 text-xs font-medium px-1.5 py-0.5 rounded border w-fit">
              {params?.row?.system_job_status}
            </span>
          )}
          {params?.row?.job_status && (
            <span className="mt-2 block px-1.5 py-0.5">
              {params?.row?.job_status}
            </span>
          )}
        </div>
      ),
    },
    {
      headerName: "Customer City",
      field: "pickup_city",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Customer State",
      field: "pickup_state",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Customer Zip Code",
      field: "pickup_zip",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Status",
      field: "job_created",
      minWidth: 150,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: [
        { value: false, label: "Job not created" },
        { value: true, label: "Job created" },
      ],
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        job_created: value?.value,
      }),
      valueFormatter: (params) => params.value?.job_created,
      renderCell: (params) => (
        <div>
          {`${params.row.job_created}${params.row.job_number ? " | " : ""}`}
          {params.row.job_number ? (
            <a
              href={`/jobs/update/preview/${params.row._id}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:underline cursor-pointer"
            >
              {`${params.row.job_number}`}
            </a>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "Created",
      field: "createdAt",
      minWidth: 200,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
  ];

  const getRowHeight = (params) => {
    const rowHeight = 10; // minimum height of the row
    const data = params?.model;
    const numberOfLines = data?.system_job_status
      ? Math.ceil(data?.system_job_status?.length / 40)
      : 0;
    const totalHeights = rowHeight + numberOfLines * 30 + 20;
    return totalHeights;
  };

  return (
    <>
      <div className="bg-white my-1 rounded">
        <MUIDataTable
          items={records?.map((item, index) => {
            const {
              _id,
              lead_num,
              name,
              job_created,
              phone,
              createdAt,
              job_number,
              is_declined,
              pickup_city,
              pickup_state,
              pickup_zip,
              goa_price,
              job_status_id,
              system_job_status_id,
            } = item;
            return {
              id: _id,
              _id,
              name,
              counter: index + 1,
              records: item,
              lead_num,
              is_declined,
              job_created: `${
                is_declined ? "Declined" : job_created ? "Job Created" : "Open"
              }`,
              job_number,
              phone,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              pickup_city,
              pickup_state,
              pickup_zip,
              goa_price,
              job_status: job_status_id?.name || "",
              system_job_status: system_job_status_id?.name || "",
            };
          })}
          columnDefs={columnDefs}
          searchable="No"
          showCount="No"
          totalItems={records?.length}
          toolbar="No"
          pagination="No"
          height="36vh"
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};
export default LeadByTimeListings;

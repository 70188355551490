import { FormControl, InputLabel, Select } from "@mui/material";

const FormSelectInput = ({
  name,
  label,
  formik,
  options = [],
  valueProp,
  labelProp,
  convertor,
  disabled,
  onChange,
  errors,
  nullOption,
  labelProp2,
  value,
  required,
  onFocus,
  onClick,
  alternatelabelProp,
}) => {
  return (
    <FormControl
      sx={{
        mt: 0,
        borderColor: "#a9a9a9",
        background: "transparent",
      }}
      variant="outlined"
    >
      <InputLabel htmlFor={label}>{required ? `${label} *` : label}</InputLabel>
      <Select
        sx={{
          borderColor: "#a9a9a9",
          mt: 0,
          background: "#fff",
        }}
        defaultValue=""
        id={name}
        label={label}
        size="small"
        fullWidth
        native
        name={name}
        variant="outlined"
        value={value ? value : value === "" ? value : formik?.values[name]}
        onChange={({ target: { value } }) => {
          if (convertor) {
            value = convertor(value);
          }
          if (formik && !onChange) {
            formik?.setFieldValue(name, value);
          }
          if (onChange && formik) {
            onChange(value);
          }
          if (onChange && !formik) {
            onChange(value);
          }
        }}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={formik?.handleBlur}
        disabled={disabled}
        error={formik?.errors[name] && formik?.touched[name] ? true : false}
      >
        {nullOption !== "No" ? <option value="" label=""></option> : null}

        {options.length > 0 &&
          options.map((option) => (
            <option key={option[valueProp]} value={option[valueProp]}>
              {option[labelProp]
                ? `${option[labelProp]} ${
                    labelProp2 ? " | " + option[labelProp2] : ""
                  }`
                : option[valueProp]}
            </option>
          ))}
      </Select>
      {formik?.errors[name] && formik?.touched[name] && (
        <small
          style={{
            fontSize: 12,
            marginTop: 0,
            paddingLeft: 16,
            color: "#D32F2F",
          }}
        >
          {formik?.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: 0,
                    paddingLeft: 16,
                    color: "#D32F2F",
                    background: "transparent",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}

      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </FormControl>
  );
};

export default FormSelectInput;

import dayjs from "dayjs";
import React from "react";
import { BiMap } from "react-icons/bi";
import { CiCalendarDate } from "react-icons/ci";
import { AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_calendar_events,
  setAddUpdateEvent,
  setSelectedEvent,
  setShowEventModal,
} from "../../../../features/calendarEventSlice";
import { NoteAltOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import userPermission from "../../../../util/userPermission";
import { BsCardHeading, BsTrash } from "react-icons/bs";

export default function EventModal({ getLeadsByFilter }) {
  const { selectedEvent } = useSelector((state) => state?.calendarEvents);
  const dispatch = useDispatch();
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      dispatch(setShowEventModal(false));
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      dispatch(setShowEventModal(false));
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const deleteEvent = async (id) => {
    const c = window.confirm("Are you sure want to delete this event?");
    if (!c) return;
    try {
      const res = await dispatch(delete_calendar_events(id));
      if (res.payload.status === 200) {
        toast.success("Event deleted successfully");
        dispatch(setShowEventModal(false));
        getLeadsByFilter();
      } else {
        toast.error("Event couldn't be deleted");
      }
    } catch (err) {
      toast.error("Event couldn't be deleted");
      console.log("👊 ~ deleteEvent ~ err:", err);
    }
  };
  function formatReminders(data) {
    return data.map((item) => {
      if (item.before_minutes < 60) {
        return {
          type: "minutes",
          minutes: item.before_minutes.toString(),
        };
      } else {
        const hours = item.before_minutes / 60;
        return {
          type: "hours",
          hours: hours.toString(),
        };
      }
    });
  }
  const formattedReminders = formatReminders(
    selectedEvent?.main_data?.reminders
  );
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center z-[1000]">
      <div
        className="bg-white rounded-lg shadow-2xl md:w-1/3 border "
        ref={optionsRef}
      >
        <header
          className={`bg-[#f7f7f7] px-4 py-2 flex justify-between items-center border-b border-[#bbb2b2]`}
        >
          <span className="material-icons-outlined text-black font-semibold">
            Event Detail
          </span>
          <div className="flex gap-x-2">
            {userPermission("Delete Calendar") ? (
              <button
                onClick={() => deleteEvent(selectedEvent?.main_data?._id)}
              >
                <span className="material-icons-outlined text-menu">
                  <BsTrash size={22} />
                </span>
              </button>
            ) : null}
            {userPermission("Edit Calendar") ? (
              <button
                onClick={() => {
                  dispatch(setSelectedEvent(selectedEvent));
                  dispatch(setAddUpdateEvent(true));
                  dispatch(setShowEventModal(false));
                }}
              >
                <span className="material-icons-outlined text-menu">
                  <AiOutlineEdit size={25} />
                </span>
              </button>
            ) : null}

            <button onClick={() => dispatch(setShowEventModal(false))}>
              <span className="material-icons-outlined text-menu">
                <AiOutlineCloseCircle size={25} />
              </span>
            </button>
          </div>
        </header>
        <div className="p-3">
          <div
            className={"min-w-[350px]"}
            // ref={optionsRef}
          >
            <ul className="pl-2 ml-0">
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <BsCardHeading className="mr-2" size={20} />
                {selectedEvent?.summary || ""}
              </li>
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <CiCalendarDate className="mr-2" size={20} />
                {selectedEvent?.start?.dateTime
                  ? dayjs(selectedEvent?.start?.dateTime).format(
                      "ddd, MMM D, YYYY h:mm A"
                    )
                  : "No date selected"}
              </li>
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <BiMap className="mr-2 min-w-[20px] min-h-[20px]" size={20} />
                {selectedEvent?.location || "There is no shipping address."}
              </li>
              <li className="mb-1.25 pt-1.25 pb-2.5 border-b border-[#f0f0f0] flex items-center">
                <NoteAltOutlined className="mr-2" size={20} />
                {selectedEvent?.description}
              </li>
            </ul>
            <div className="border-b !mt-3">
              <span className="font-semibold">Reminders</span>
            </div>
            <div className="flex flex-col gap-y-1 pl-2">
              {formattedReminders?.map(({ minutes, type }, index) => {
                return (
                  <span>{`${
                    index + 1
                  }. Before ${minutes} minutes, ${type}`}</span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

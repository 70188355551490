import React from "react";

function Weeks() {
  return (
    <div className="flex flex-1 w-100 h-100 justify-center items-center text-xl font-bold">
      Comming Soon...
    </div>
  );
}

export default Weeks;

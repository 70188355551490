import FormRadioGroup from "../../../../components/molecules/FormRadioGroup";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
const CustomSelect = ({
  name,
  formik,
  label,
  options,
  valueProp,
  labelProp,
  icon,
  placeholder,
  isSelect,
  isRadio,
  isHorizontal,
  isBorder,
  isInput,
  head,
  value,
  isDisable,
  inputType,
  isOnChange,
  isCheck,
  isPhoneInput,
  errors,
  isCustomDistance,
  customDistance,
  setCustomDistance,
  setIsCustomDistance,
  calculateRoute,
  isCustomComponent,
  onBlur,
}) => {
  const style = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      height: "40px",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };
  const error = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      height: "40px",
      borderRadius: 0,
      boxShadow: "none",
      borderColor: "red",
    }),
  };

  return (
    <div className="mb-3 col-12 px-0 form-group">
      {head && !isRadio ? (
        <label htmlFor={name} className="small fw-700">
          {head}
        </label>
      ) : null}

      <div className="flex flex-row ">
        {icon && (
          <div
            className={`bg-client-100 flex w-[60px] justify-center items-center rounded-l ${
              isRadio ? "h-[60px]" : "h-10"
            }`}
          >
            <span
              className="w-6 h-6 bg-center bg-no-repeat bg-contain"
              style={{ backgroundImage: `url(${icon})` }}
            />
          </div>
        )}

        {isSelect && (
          <Select
            styles={formik.errors[name] ? error : style}
            placeholder={placeholder}
            options={options}
            onChange={(e) => formik.setFieldValue([name], e.value)}
            onBlur={formik.handleBlur}
            name={name}
            defaultValue={
              formik?.values[name]
                ? {
                    label: formik?.values[name] ? formik?.values[name] : null,
                    value: formik?.values[name] ? formik?.values[name] : null,
                  }
                : null
            }
          />
        )}
        {isRadio && (
          <div
            className={`w-full ${
              isBorder ? "border border-[rgb(204,_204,_204)]" : ""
            }`}
          >
            <FormRadioGroup
              errors={errors}
              name={name}
              label={head}
              labelProp={labelProp}
              valueProp={valueProp}
              options={options}
              convertor={(value) => value === "true"}
              formik={formik}
              type="text"
              isHorizontal={isHorizontal}
              isOnChange={isOnChange}
              isCheck={isCheck}
              isFloat={false}
            />
          </div>
        )}
        {isInput && (
          <input
            className={[
              "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
              `rounded-l-none`,
              formik.errors[name] && formik.touched[name] ? "invalid" : "",
            ].join(" ")}
            name={name}
            placeholder={
              formik.errors[name] && formik.touched[name]
                ? formik.errors[name]
                : placeholder
            }
            value={value}
            disabled={isDisable}
            onChange={formik.handleChange}
            onBlur={onBlur ? onBlur : formik.handleBlur}
            type={inputType}
          />
        )}
        {isPhoneInput && (
          <PhoneInput
            defaultCountry="US"
            className={[
              "shadow appearance-none border rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
              `rounded-l-none`,
              formik.errors[name] && formik.touched[name] ? "invalid" : "",
            ].join(" ")}
            onChange={isOnChange}
            value={value}
            placeholder={placeholder}
            initialValueFormat="national"
          />
        )}
      </div>
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] ? (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, placeholder)
                    .replaceAll(`${name}`, placeholder)}
                </small>
              ) : null}
            </>
          );
        })}
      {!errors?.some((error) => error[name]) && (
        <>
          {isCustomComponent ? (
            <div className="flex justify-between">
              <small className="font-pop">{label}</small>
              {isCustomDistance ? (
                <div className="flex flex-row gap-x-2 items-centr justify-cente">
                  <input
                    className="bg-transparent border-b border-black"
                    value={customDistance}
                    onChange={(e) => setCustomDistance(e.target.value)}
                    // onChange={formik.handleChange}
                    name="distance"
                  />
                  <div className="flex flex-row gap-x-2 items-end">
                    <button
                      onClick={() => {
                        formik.setFieldValue("distance", customDistance);
                        setCustomDistance("");
                        setIsCustomDistance(false);
                      }}
                      className="text-sm text-blue-500"
                    >
                      Done
                    </button>
                    <button
                      onClick={() => {
                        // calculateRoute();
                        setCustomDistance("");
                        setIsCustomDistance(false);
                      }}
                      className="text-sm text-red-500"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row gap-x-2">
                  <button onClick={calculateRoute} type="button">
                    Calculate?
                  </button>
                  <button
                    onClick={() => setIsCustomDistance(true)}
                    type="button"
                    className="text-blue-500"
                  >
                    Want to change the distance?
                  </button>
                </div>
              )}
            </div>
          ) : (
            <small className="font-pop">{label}</small>
          )}
        </>
      )}
    </div>
  );
};
export default CustomSelect;

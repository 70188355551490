import React, { useEffect, useState } from "react";
import { FaEye, FaTrashAlt, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../components/molecules/PageHeader";
import userPermission from "../../../util/userPermission";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import {
  delete_batch,
  get_batch,
  get_data_for_export2,
  purchase_domains,
} from "../../../features/domainCenterSlice";
import { Button, DotsLoader } from "../../../components";
import { useNavigate } from "react-router-dom";
import errorMessage from "../../../util/errorMessage";
import { Checkbox, Button as MUIButton, TablePagination } from "@mui/material";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import {
  GridFooterContainer,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { get_countries } from "../../../features/countriesSlice";
import { get_industry_drd } from "../../../features/IndustrySlice";
const BatchReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.countries);
  const { industryDrd } = useSelector((state) => state.industry);
  const { isLoading, batches, export_Data } = useSelector(
    (state) => state.domainCenter
  );
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const initialFilters = {
    purchase_by: "BATCH",
    ids: [],
    note: "",
    description: "",
  };
  const [filterData, setfilterData] = useState(initialFilters);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  useEffect(() => {
    const getBatch = async () => {
      setLoader(true);
      try {
        let payload = {
          page: 1,
          size: paginationModel.pageSize,
        };
        if (queryOptions.groupOp && queryOptions.rules.length > 0) {
          payload.filters = queryOptions;
        }
        const res = await dispatch(get_batch({ ...payload }));
        if (res?.payload?.status === 200) {
          setLoader(false);
        }
      } catch (err) {
        setLoader(false);
        console.error("🚀 ~ file: index.jsx:40 ~ getBatch ~ err:", err);
      }
    };
    getBatch();
    dispatch(get_countries());
    dispatch(get_industry_drd({ type: "all" }));
    // eslint-disable-next-line
  }, []);

  /*   React.useEffect(() => {
    const timerID = setInterval(() => {
      // Check if the array contains an object with the desired status
        const hasDesiredStatus = batches.some(
        (obj) =>
          obj.status === "domain_generation_start" || obj.status === "pending"
      );

      if (hasDesiredStatus) {
        // Perform the rendering or dispatch the necessary action
        dispatch(get_batch());
      } else {
        clearInterval(timerID);
      } 
    }, 5000);

    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line
  }, [batches]); */

  const vendors = batches?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setLoader(true);
      try {
        const res = await dispatch(delete_batch(record_id));
        if (res?.payload?.status === 200) {
          let payload = {
            page: 1,
            size: paginationModel.pageSize,
          };
          if (queryOptions.groupOp && queryOptions.rules.length > 0) {
            payload.filters = queryOptions;
          }
          await dispatch(get_batch({ ...payload }));
          toast.success("Batch deleted");
          setLoader(false);
        } else {
          errorMessage({
            payload: res.payload,
            action: "Batch",
            msg: "deleted",
          });
          setLoader(false);
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Batch", msg: "deleted" });
        setLoader(false);
      }
    }
  };
  const isCheckboxEnabled = (available_domain_count) => {
    return available_domain_count > 0;
  };
  const selectAll = () => {
    let id = [];
    if (
      filterData?.ids?.length !==
      batches?.records.filter(
        (obj) => obj.available_domain_count !== 0 && obj.id !== "totals"
      )?.length
    ) {
      // eslint-disable-next-line
      batches?.records?.map((val) => {
        if (val?.available_domain_count > 0 && val?.id !== "totals") {
          id = [...id, val.id];
        } else {
          return id;
        }
      });
      setfilterData({ ...filterData, ids: id });
    } else if (
      filterData?.ids?.length ===
      batches.records.filter(
        (obj) => obj.available_domain_count !== 0 && obj.id !== "totals"
      )?.length
    ) {
      setfilterData({ ...filterData, ids: [] });
    }
  };

  const isIdChecked = (id) => {
    return filterData?.ids?.includes(id);
  };
  const onSingleselect = (val) => {
    if (filterData?.ids?.length > 0) {
      if (filterData?.ids?.filter((id) => id === val).length > 0) {
        const arr = filterData?.ids?.filter((id) => id !== val);
        setfilterData({ ...filterData, ids: arr });
      } else {
        setfilterData({ ...filterData, ids: [...filterData?.ids, val] });
      }
    } else {
      setfilterData({ ...filterData, ids: [...filterData?.ids, val] });
    }
  };
  const columnDefs = [
    /*  {
      field: "checkbox",
      headerName: "Checkbox",
      renderHeader: (params) => (
        <Checkbox
          onChange={selectAll}
             checked={
            batches?.records.length > 0 &&
            filterData?.ids?.length ===
              batches.records.filter((obj) => obj.available_domain_count !== 0)?.length
          }
          disabled={
            batches.records.filter((obj) => obj.available_domain_count !== 0)?.length === 0
          }  
        />
      ),
      sortable: false,
      disableColumnMenu: true,
       renderCell: (params) => {
        const rowId = params.row.records._id;
        const available_domain_count = params.row.available_domain_count;
        const isDisabled = !isCheckboxEnabled(available_domain_count);
        return (
          <>
            {rowId !== "totals" && (
              <Checkbox
                onChange={() => onSingleselect(rowId)}
                checked={isIdChecked(rowId)}
                disabled={isDisabled}
              />
            )}
          </>
        );
      },

      width: 50,
    }, */
    { headerName: "#", field: "counter", width: 10},
    { headerName: "Notes", field: "note",  width: 150 },
    { headerName: "Keywords", field: "keywords", width: 150 },
    { headerName: "TLD", field: "tlds", flex: 1 },
    {
      headerName: "Industry",
      field: "industry_id",
      minWidth: 180,
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => params?.row?.industry_id,
      valueFormatter: (params) => {
        const industry = industryDrd.find(ind => ind._id === params.value);
        return industry ? industry.name : '';
      },
    },
    {
      headerName: "Country",
      field: "country_id",
      minWidth: 120,
      type: "singleSelect",
      getOptionValue: (option) => option?._id,
      getOptionLabel: (option) => option.name,
      valueOptions: countries,
      renderCell: (params) =>
        params?.row?.country_id ? params?.row?.country_id?.name : "N/A",
    },
    {
      headerName: "Domain Count",
      field: "domains_count",
      minWidth: 100,
      type: "number",
    },
    {
      headerName: "Available To Purchase",
      field: "available_domain_count",
      minWidth: 100,
      type: "number",
    },
    {
      headerName: "Purchased",
      field: "purchased_count",
      minWidth: 100,
      type: "number",
    },
    {
      headerName: "In Queue",
      field: "purchase_queue_count",
      minWidth: 100,
      type: "number",
    },
    {
      headerName: "Created At",
      field: "created_at",
      minWidth: 180,
      cellClassName: "multiline-cell",
      type: "date",
      valueGetter: (params) => new Date(params.row.created_at),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.created_at;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      minWidth: 180,
      cellClassName: "multiline-cell",
      type: "date",
      valueGetter: (params) => new Date(params.row.updated_at),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.updated_at;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      field: "actions",
      filterable: false,
      renderCell: (params) =>
        params.row.records.id !== "totals" ? (
          <div>
            <FaEye
              onClick={() =>
                navigate(
                  `/domain_center/batch_report/${params.row.records?._id}`
                )
              }
              className="my_navIcon"
              title="View Batch"
            />
            {/* {!(params.row.purchase_processing > 0 || params.row.purchased) ? ( */}
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete Batch"
            />
            {/* ) : null} */}
          </div>
        ) : null,
      width: 120,
    },
  ];

  const CustomFooter = ({ data = [], columns = [] }) => {
    const totals = data?.reduce(
      (acc, row) => {
        acc.domains_count += row.domains_count || 0;
        acc.available_domain_count += row.available_domain_count || 0;
        acc.purchase_queue_count += row.purchase_queue_count || 0;
        acc.purchased_count += row.purchased_count || 0;
        return acc;
      },
      {
        domains_count: 0,
        available_domain_count: 0,
        purchase_queue_count: 0,
        purchased_count: 0,
      }
    );

    return (
      <GridFooterContainer>
        <div style={{ display: "flex", width: "100%" }}>
          {columns?.map((column) =>
            loader || isLoading ? null : (
              <div
                key={column.field}
                style={{
                  flex: column.flex || 1,
                  minWidth: column.width || 100,
                  textAlign: "center",
                  padding: "8px",
                  borderTop: "1px solid #e0e0e0",
                  fontWeight: 700,
                }}
                className="font-semibold"
              >
                {(() => {
                  switch (column.field) {
                    case "industry":
                      return "Total:";
                    case "domains_count":
                      return totals.domains_count;
                    case "available_domain_count":
                      return totals.available_domain_count;
                    case "purchase_queue_count":
                      return totals.purchase_queue_count;
                    case "purchased_count":
                      return totals.purchased_count;
                    default:
                      return "";
                  }
                })()}
              </div>
            )
          )}
        </div>
      </GridFooterContainer>
    );
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    let payload = {
      page: +params.page + 1,
      size: params.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    dispatch(get_batch({ ...payload }));
  };

  const handlePurchase = async () => {
    if (filterData?.note) {
      try {
        const res = await dispatch(purchase_domains(filterData));
        if (res?.payload?.status === 200) {
          toast.success("Domain purchasing start successfully");
          window.location.reload();
        }
      } catch (err) {
        toast.success("Domain purchasing couldn't be started");
        console.error("🚀 ~ file: index.jsx:146 ~ handlePurchase ~ err:", err);
      }
    } else {
      toast.error("Note is required");
    }
  };

  const getColumnTotal = (field) => {
    // return batches.records.reduce((sum, row) => sum + row[field], 0);
  };

  const totalsRow = {
    id: "totals",
    note: "Totals",
    keywords: null,
    tlds: null,
    domain_count: getColumnTotal("domains_count"),
    //available_domain_count: getColumnTotal("available_domain_count"),
    //in_purchase_queue: getColumnTotal("in_purchase_queue"),
    //purchased: getColumnTotal("purchased"),
    //purchase_error: getColumnTotal("purchase_error"),
  };
  //vendors = [...vendors, totalsRow];
  const exportData = async () => {
    setIsLoader(true);
    try {
      const ids = batches.records.map((item) => {
        return item?.id;
      });
      const res = await dispatch(get_data_for_export2(ids));
      if (res?.payload?.status === 200) {
        const data = res?.payload.data ? res.payload.data : export_Data;
        const currentDate = new Date();

        const rows = [
          [
            "Export Date:",
            dayjs(currentDate).format("ddd, MMM D, YYYY h:mm A"),
          ],
          [
            "Domains",
            "City",
            "State",
            "domains_count",
            "Zip",
            "Zip Population",
            "Area Codes",
          ], // Add headings
        ];

        data.forEach((item) => {
          const { available_domains, city, state, area_code, domains_count } =
            item;
          const total_population = item?.total_population[0]?.total;
          const zip = item?.zip?.toString();
          if (available_domains.length === 0) {
            rows.push(["", city, state, domains_count, zip, total_population]);
          } else {
            const domains = available_domains
              .map((domain) => domain.domain)
              .join(", ");

            const areaCodes = area_code?.toString()?.split(",");

            const row = [
              domains,
              city,
              state,
              domains_count,
              zip,
              total_population,
            ];
            areaCodes.forEach((code) => {
              row.push(code.trim());
            });

            rows.push(row);
          }
        });

        const worksheet = XLSX.utils.aoa_to_sheet(rows);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const fileName = "data.xlsx";

        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // For IE browser
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          // For other browsers
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 100);
        }
        setIsLoader(false);
      }
    } catch (err) {
      setIsLoader(false);
      console.error("🚀 ~ file: ViewBatch.jsx:322 ~ exportData ~ err:", err);
    }
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_batch({
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    let payload = {
      page: 1,
      size: paginationModel.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    dispatch(get_batch({ ...payload }));
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    let payload = {
      page: +page + 1,
      size: paginationModel.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    dispatch(get_batch({ ...payload }));
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    let payload = {
      page: 1,
      size: newPageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    dispatch(get_batch({ ...payload }));
  };
  const LineOne = () => {
    return (
      <TablePagination
        color="primary"
        shape="rounded"
        size="medium"
        showFirstButton
        showLastButton
        count={
          batches?.totalItems
            ? batches?.totalItems
            : batches?.records?.length || 0
        }
        page={paginationModel.page - 1 || 0}
        onPageChange={(e, page) => handleTopPageChange(page)}
        rowsPerPage={paginationModel.pageSize || 0}
        onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
        component="div"
        rowsPerPageOptions={[
          25,
          50,
          75,
          100,
          250,
          500,
          1000,
          1500,
          2000,
          batches?.totalItems
            ? batches?.totalItems
            : batches?.records?.length || 0,
        ]}
      />
    );
  };
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  return (
    <>
      <PageHeader
        route="Setting > Batches"
        heading="Batches Listing"
        isAllowed={true}
        onClick={() => navigate("/domain_center/domain_location_report")}
        buttonTitle="Create batch"
      />
      {isLoading && batches?.records?.length === 0 && <DotsLoader />}
      {isLoader && <DotsLoader />}
      {/*    {filterData?.ids?.length > 0 && (
        <div className="flex flex-row gap-x-2 items-center mb-2">
          <div className="span-col-2 w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Purchase Note"}
            </label>
            <input
              className="form-control"
              placeholder="Note"
              onChange={(e) =>
                setfilterData({
                  ...filterData,
                  note: e.target.value,
                })
              }
              value={filterData?.note}
            />
          </div>
          <div className="span-col-2 w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Purchase Description"}
            </label>
            <textarea
              className="form-control"
              placeholder="Description"
              onChange={(e) =>
                setfilterData({
                  ...filterData,
                  description: e.target.value,
                })
              }
              value={filterData?.description}
            />
          </div>
          <div className="">
            <label className="inputLabel" htmlFor="industries">
              {"Purchase Registrar"}
            </label>
            <Dropdown
              value={filterData.purchase_credentials}
              onChange={(e) =>
                setfilterData({
                  ...filterData,
                  purchase_credentials: e.value,
                })
              }
              options={record}
              optionLabel="name"
              optionValue="credential"
              placeholder="Select Purchase Registrar"
              className="h-[38px] min-w-[255px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              showClear
            />
          </div>
          <div className="col-span-1 w-[50%]">
            <div className="float-right">
              <Button
                text={"Purchase"}
                className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                onClick={handlePurchase}
              />
              <Button
                text="Clear"
                className="py-1.5 px-3 align-middle bg-menu text-white"
                onClick={() => {
                  // dispatch(resetBuyingDomains())
                  setfilterData({ ...filterData, description: "", note: "" });
                }}
              />
            </div>
          </div>
        </div>
      )} */}

      <div className="bg-white my-1 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={vendors?.map((record, index) => {
            let counter = record?._id === "totals" ? "" : offset + index + 1;
            let id = record?._id;
            let note = record?.note;
            let keywords = record?.keywords;
            let tlds = record?.tlds;
            let industry_id = record?.industry_id;
            let available_domain_count = record?.available_domain_count;
            let purchase_queue_count = record?.purchase_queue_count;
            let purchased_count = record?.purchased_count;
            let domains_count = record?.domains_count
              ? record?.domains_count
              : 0;
            let status = 0;
            let country_id = record?.country_id;
            let created_at =
              record._id !== "totals"
                ? dayjs(record.createdAt).format("ddd, MMM D, YYYY h:mm A")
                : null;
            let updated_at =
              record._id !== "totals"
                ? dayjs(record.updatedAt).format("ddd, MMM D, YYYY h:mm A")
                : null;
            return {
              counter,
              records: { ...record, _id: id },
              note,
              keywords,
              tlds,
              industry_id,
              available_domain_count,
              purchase_queue_count,
              purchased_count,
              status,
              domains_count,
              created_at,
              country_id,
              updated_at,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={batches?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={loader || isLoading}
          paginationMode="server"
          showColumnFooter={true}
          isFooterItems={true}
          density="standard"
          onFilterModelChange={onFilterChange}
          exportButton={exportData}
          CustomToolbar={CustomToolbar}
          filterMode="server"
          CustomFooter={CustomFooter}
          foterProps={{ data: vendors, columns: columnDefs }}
          LineOne={LineOne}
          displayClass={"!my-0 !py-0"}
        />
      </div>
    </>
  );
};

export default BatchReport;

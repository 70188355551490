import React, { useEffect } from "react";
import GoogleMails from "./Emails";
import CalendarEvents from "./GoogleCalendar";
import GoogleTask from "./GoogleTask";
import Voicemail from "./Voicemail";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSyncWithGoogleStatus } from "../../features/calendarEventSlice";

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryStatus = new URLSearchParams(location.search).get("status");
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const isSynced =
    (user?.sync_with_google && user?.sync_with_google === true) ||
    user?.sync_with_google === "true";
    
  useEffect(() => {
    if (!isSynced) {
      toast.error("Your account is not synced with Google.");
      navigate("/dashboard");
    }
  }, [isSynced, navigate]);

  const defaultTabs = ["Email", "My Calendar", "My Tasks"];
  const tabs = user?.dialer_data?.dialer_phone
    ? [...defaultTabs, "Voicemail"]
    : defaultTabs;
  const [activeTab, setActiveTab] = React.useState("Email");
  const [emailCount, setEmailCount] = React.useState(0);
  const [voicemailCount, setVoicemailCount] = React.useState(0);

  useEffect(() => {
    if (queryStatus === "synced") {
      dispatch(setSyncWithGoogleStatus(true));
    }
  }, [dispatch, queryStatus]);

  return (
    <div className="!my-4">
      <ul className="grid grid-cols-6 gap-x-2">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`p-2 rounded-t-md hover:bg text-center cursor-pointer ${
              activeTab === tab ? "bg-primary-100 text-white" : "bg-neutral-100"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {`${tab} ${
              tab === "Email" && emailCount.length > 0 ? `(${emailCount})` : ""
            }`}
          </li>
        ))}
      </ul>
      {activeTab === "Email" ? (
        <GoogleMails setEmailCount={setEmailCount} emailCount={emailCount} />
      ) : activeTab === "My Calendar" ? (
        <div className="max-h-[calc(100vh-150px)] overflow-y-auto">
          <CalendarEvents />
        </div>
      ) : activeTab === "My Tasks" ? (
        <GoogleTask />
      ) : activeTab === "Voicemail" ? (
        <Voicemail
          setVoicemailCount={setVoicemailCount}
          voicemailCount={voicemailCount}
        />
      ) : null}
    </div>
  );
}

export default Dashboard;

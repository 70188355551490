import api from "./api";

export const getLeads = async (data) => {
  return await api.get("/api/leads",{
    params:data,
  });
};
export const getLeadsById = async (id) => {
  return await api.get(`/api/leads/${id}`);
};
export const createLead = async (values) => {
  return await api.post("/api/leads", values);
};

export const updateLead = async (values) => {
  return await api.put("/api/leads", values);
};

export const sendSms = async (id) => {
  return await api.post("/api/sms/send_agentless_form", {lead_id:id});
};

export const getPhoneNo = async (id) => {
  return await api.get(`/api/sms/agentless?token=${id}`);
};

export const leadCreateJob = async (value) => {
  return await api.post(`/api/sms/create_lead`, value);
};
export const singleFormCreateJob = async (value) => {
  return await api.post(`/api/jobs/createJobNonTowing`, value);
};
export const singleFormSaveData = async (value) => {
  return await api.put(`/api/leads/update`, value);
};
export const leadDecline = async (value) => {
  return await api.put(`/api/leads/decline`, value);
};
export const createLeadInitial = async (values) => {
  return await api.post("/api/leads/create_lead_initial", values);
};

export const updateVicidialId = async (values) => {
  return await api.post("api/leads/add_vicidial_id_to_lead", values);
};

export const apiCheckServiceDirect = async (data) => {
  return await api.post(`/api/leads/check_service_direct`, data);
};

export const sellLeadToServiceDirect = async (data) => {
  return await api.post(`/api/leads/sell_lead_to_service_direct`, data);
};
export const sellLeadToServiceDirect2 = async (data) => {
  return await api.post(`/api/leads/sell_lead_to_service_direct2`, data);
};
import React, { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import { MdOutlineAddCircleOutline, MdOutlineAddTask } from "react-icons/md";
import { FaCheck, FaEdit, FaSync, FaTimes, FaTrashAlt } from "react-icons/fa";
import { IoMdCalendar } from "react-icons/io";
import dayjs from "dayjs";
import { DotsLoader } from "../../../components";
import AddupdateTaskList from "./AddupdateTaskListForm";
import AddUpdateTaskModal from "./AddTaskModal";

function GoogleTask() {
  const optionsRef = React.useRef();
  const [taskLists, setTaskLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTask, setIsTask] = useState(false);
  const [payload, setPayload] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [childTaskLists, setChildTaskLists] = useState([]);
  const taskRef = useRef();
  const fetchTaskLists = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/api/google_task/task_list`);
      setTaskLists(response.data);
    } catch (error) {
      console.error("Error fetching task lists:", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchTaskLists();
  }, []);
  const [showMenu, setShowMenu] = useState(null);
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      return setShowMenu(null);
    }
  };
  useEffect(() => {
    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        if (showMenu) {
          return setShowMenu(null); // Close the menu
        }
      }
    };

    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);
  const deleteRecordHandler = async (id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setIsLoading(true);
      try {
        const res = await api.delete(`/api/google_task/task_list/${id}`);
        if (res?.status === 200 || res?.status === 201) {
          setTaskLists((prevData) => ({
            ...prevData,
            taskLists: prevData.taskLists.filter((task) => task.id !== id), // Remove the task by id
            totalTaskLists: prevData.totalTaskLists - 1, // Decrement the totalTaskLists count
          }));
          toast.success("Tasklist deleted successfully");
        } else {
          errorMessage({
            payload: res.data?.message,
            action: "Tasklist",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Tasklist",
          msg: "deleted",
        });
      }
      setIsLoading(false);
    }
  };
  const deleteTask = async ({ taskListId, taskId }) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setIsLoading(true);
      try {
        const res = await api.delete(
          `/api/google_task/task/${taskListId}/${taskId}`
        );
        if (res?.status === 200 || res?.status === 201) {
          await fetchChildsList({ task_id: taskListId });
          toast.success("Tasklist deleted successfully");
        } else {
          errorMessage({
            payload: res.data?.message,
            action: "Tasklist",
            msg: "deleted",
          });
        }
      } catch (error) {
        console.log("👊 ~ deleteTask ~ error:", error);
        errorMessage({
          payload: error,
          action: "Tasklist",
          msg: "deleted",
        });
      }
      setIsLoading(false);
    }
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsTask(false);
  };
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const testMenu = [
    {
      label: "Edit",
      action: (e) => {
        openFormHandler(e);
        setShowMenu(null);
      },
    },
    {
      label: "Delete",
      action: (e) => {
        deleteRecordHandler(e?.id);
        setShowMenu(null);
      },
    },
  ];
  const handleNewTask = ({
    task_list_id,
    task_id = null,
    title = null,
    notes = null,
    dueDate = null,
  }) => {
    const newTask = {
      task_list_id,
      task_id,
      title,
      notes,
      dueDate,
    };
    setEditingRecord(newTask);
    setIsTask(true);
  };
  const fetchChildsList = async ({ task_id }) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/api/google_task/task/${task_id}`);
      setChildTaskLists((prevTaskLists) => ({
        ...prevTaskLists,
        [task_id]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching tasks for taskListId ${task_id}:`, error);
    }
    setIsLoading(false);
  };
  function getRelativeDateWithDayjs(date) {
    const now = dayjs();
    const inputDate = dayjs(date);
    const dayDifference = inputDate.diff(now, "day");

    if (dayDifference === 0) {
      return "Today";
    } else if (dayDifference === 1) {
      return "Tomorrow";
    } else if (dayDifference === -1) {
      return "Yesterday";
    } else if (Math.abs(dayDifference) < 7) {
      return `${Math.abs(dayDifference)} days ${
        dayDifference > 0 ? "from now" : "ago"
      }`;
    } else {
      const weekDifference = inputDate.diff(now, "week");
      return `${Math.abs(weekDifference)} weeks ${
        weekDifference > 0 ? "from now" : "ago"
      }`;
    }
  }
  function getDateLabel(date) {
    const inputDate = dayjs(date).startOf("day");
    const today = dayjs().startOf("day");
    const tomorrow = dayjs().add(1, "day").startOf("day");
    const currentYear = dayjs().year();

    if (inputDate.isSame(today)) {
      return "Today";
    } else if (inputDate.isSame(tomorrow)) {
      return "Tomorrow";
    } else {
      // If the year of the input date is the current year
      if (inputDate.year() === currentYear) {
        return inputDate.format("ddd, MMM D"); // e.g., "Mon, Dec 28"
      } else {
        return inputDate.format("MMMM D, YYYY"); // e.g., "December 28, 2024"
      }
    }
  }
  const markAsCompleted = async ({ task_list_id, task_id, status }) => {
    setIsLoading(true);
    try {
      const response = await api.post(
        `/api/google_task/task/${task_list_id}/${task_id}`,
        { status }
      );
      if (response?.status === 200 || response?.status === 201) {
        await fetchChildsList({ task_id: task_list_id });
        setPayload(null);
        toast.success(`Task status changed successfully`);
        cancelFormHandler();
      } else {
        toast.error(response?.data?.message || `Failed to change the status`);
      }
    } catch (error) {
      console.error("Error markAsCompleted task:", error);
      toast.error(
        error?.response?.data?.message || `Failed to change the status`
      );
    }
    setIsLoading(false);
  };
  return (
    <div className="mt-3">
      {isEditing ? (
        <AddupdateTaskList
          editingRecord={editingRecord}
          cancelFormHandler={cancelFormHandler}
          setTaskLists={setTaskLists}
          setIsLoading={setIsLoading}
        />
      ) : null}
      {isTask ? (
        <AddUpdateTaskModal
          editingRecord={editingRecord}
          cancelFormHandler={cancelFormHandler}
          fetchChildsList={fetchChildsList}
          setIsLoading={setIsLoading}
          taskList={taskLists?.taskLists}
        />
      ) : null}
      {isLoading ? <DotsLoader /> : null}
      <header className="px-2 py-2 flex items-center justify-between">
        <div className="flex items-center gap-x-3">
          <button
            className="flex items-center border rounded gap-x-1 px-2 shadow hover:shadow-lg"
            onClick={() => openFormHandler(null)}
          >
            <MdOutlineAddCircleOutline /> Create new list
          </button>
          <button
            className="flex items-center border rounded gap-x-1 px-2 shadow bg-gray-600 text-white hover:shadow-lg"
            onClick={() => handleNewTask({ task_list_id: null })}
          >
            <MdOutlineAddTask /> Create new task
          </button>
        </div>
      </header>
      <div className="grid grid-cols-4 gap-2">
        {taskLists?.taskLists?.map((list, index) => {
          return (
            <div
              className="border rounded-lg shadow-md relative pb-3"
              key={index}
            >
              <div className="p-2 w-full border-b">
                <div className="flex justify-between items-center px-">
                  <h2 className="text-lg font-semibold text-gray-700 mb-2">
                    {list.title}
                  </h2>
                  <div className="relative">
                    <BsThreeDotsVertical
                      size={18}
                      className="cursor-pointer hover:text-primary-100"
                      onClick={() => setShowMenu(list?.id)}
                    />
                    {showMenu === list?.id ? (
                      <div
                        ref={optionsRef}
                        className="absolute w-auto right-0 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
                      >
                        <ul className="!pl-0 !mb-0">
                          {testMenu?.map((option, index) => {
                            return (
                              <li
                                key={index}
                                className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                                onClick={() => option.action(list)}
                              >
                                {option.label}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex">
                  <button
                    onClick={() => handleNewTask({ task_list_id: list?.id })}
                    className="flex w-full items-center gap-2 px-2 hover:bg-[#0b57d014] text-[#0b57d0] h-10 rounded-full"
                  >
                    <MdOutlineAddTask size={20} />
                    <span>Add a task</span>
                  </button>
                </div>
              </div>
              <ul className="mt-2">
                {list?.id === payload?.task_list_id && !payload?.task_id ? (
                  <li
                    className={`flex items-cente gap-x-2 justify-betwee pl-2 !pr-3 py-2 bg-gray-50 rounded-md`}
                    ref={taskRef}
                  >
                    <input type="radio" className="w-5 h-5" />
                    <div className="flex flex-col w-full text-sm bg-transparent gap-y-1">
                      <input
                        value={payload?.title}
                        onChange={(e) => {
                          setPayload({ ...payload, title: e.target.value });
                        }}
                        placeholder="Title"
                        className="bg-transparent placeholder-black focus:placeholder-gray-500 font-pop"
                      />
                      <input
                        value={payload?.notes}
                        onChange={(e) => {
                          setPayload({ ...payload, notes: e.target.value });
                        }}
                        placeholder="Details"
                        className="bg-transparent placeholder-black focus:placeholder-gray-500 font-pop text-xs"
                      />
                      {payload?.dueDate ? (
                        <div className="flex items-center gap-x-0.5 border rounded-full !w-fit bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm">
                          <span className="">
                            {getDateLabel(payload?.dueDate)}
                          </span>
                          <FaTimes
                            className="w-3 h-3 cursor-pointer hover:text-gray-500"
                            onClick={() =>
                              setPayload({ ...payload, dueDate: null })
                            }
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row gap-x-2">
                          <button
                            onClick={() =>
                              setPayload({ ...payload, dueDate: new Date() })
                            }
                            className="border rounded-full bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm"
                          >
                            Today
                          </button>
                          <button
                            onClick={() => {
                              const tomorrow = dayjs().add(1, "day").toDate();
                              setPayload({ ...payload, dueDate: tomorrow });
                            }}
                            className="border rounded-full bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm"
                          >
                            Tomorrow
                          </button>
                          <button className="border rounded-full bg-white !px-2.5 py-0.5 hover:!bg-gray-50 hover:shadow-sm">
                            <IoMdCalendar size={18} />
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                ) : null}
              </ul>
              <ul className="mt-2">
                {childTaskLists &&
                  childTaskLists[list?.id] &&
                  childTaskLists[list?.id]?.items
                    ?.filter(({ status }) => status !== "completed")
                    ?.map((task, index) => {
                      const { title, notes, due } = task;
                      return payload?.task_id === task.id ? (
                        <li
                          className={`flex items-cente gap-x-2 justify-betwee pl-2 !pr-3 py-2 bg-gray-50 rounded-md`}
                          ref={taskRef}
                          key={index}
                        >
                          <input type="radio" className="w-5 h-5" />
                          <div className="flex flex-col w-full text-sm bg-transparent gap-y-1">
                            <input
                              value={payload?.title}
                              onChange={(e) => {
                                setPayload({
                                  ...payload,
                                  title: e.target.value,
                                });
                              }}
                              placeholder="Title"
                              className="bg-transparent placeholder-black focus:placeholder-gray-500 font-pop"
                            />
                            <input
                              value={payload?.notes}
                              onChange={(e) => {
                                setPayload({
                                  ...payload,
                                  notes: e.target.value,
                                });
                              }}
                              placeholder="Details"
                              className="bg-transparent placeholder-black focus:placeholder-gray-500 font-pop text-xs"
                            />
                            {payload?.dueDate ? (
                              <div className="flex items-center gap-x-0.5 border rounded-full !w-fit bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm">
                                <span className="">
                                  {getDateLabel(payload?.dueDate)}
                                </span>
                                <FaTimes
                                  className="w-3 h-3 cursor-pointer hover:text-gray-500"
                                  onClick={() =>
                                    setPayload({ ...payload, dueDate: null })
                                  }
                                />
                              </div>
                            ) : (
                              <div className="flex flex-row gap-x-2">
                                <button
                                  onClick={() =>
                                    setPayload({
                                      ...payload,
                                      dueDate: new Date(),
                                    })
                                  }
                                  className="border rounded-full bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm"
                                >
                                  Today
                                </button>
                                <button
                                  onClick={() => {
                                    const tomorrow = dayjs()
                                      .add(1, "day")
                                      .toDate();
                                    setPayload({
                                      ...payload,
                                      dueDate: tomorrow,
                                    });
                                  }}
                                  className="border rounded-full bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm"
                                >
                                  Tomorrow
                                </button>
                                <button className="border rounded-full bg-white !px-2.5 py-0.5 hover:!bg-gray-50 hover:shadow-sm">
                                  <IoMdCalendar size={18} />
                                </button>
                              </div>
                            )}
                          </div>
                        </li>
                      ) : (
                        <li
                          className={`flex items-cente gap-x-2 justify-between pl-2 !pr-3 py-2 cursor-pointer hover:bg-gray- rounded-md mt-2`}
                        >
                          <div className="flex gap-x-2">
                            <button
                              title="Mark as completed"
                              onClick={() => {
                                markAsCompleted({
                                  task_list_id: list.id,
                                  task_id: task.id,
                                  status: "completed",
                                });
                              }}
                              className="w-4 h-4 flex border-2 !border-black hover:!border-gray-500 items-center justify-center rounded-full"
                            />
                            <button
                              className="flex flex-col"
                              onClick={() =>
                                handleNewTask({
                                  task_list_id: list?.id,
                                  task_id: task?.id,
                                  title,
                                  notes,
                                  dueDate: due,
                                })
                              }
                            >
                              <span className="font-pop text-sm">{title}</span>
                              <span className="font-pop text-xs">{notes}</span>
                              {due ? (
                                <span className="border rounded-full bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm">
                                  {getRelativeDateWithDayjs(due)}
                                </span>
                              ) : null}
                            </button>
                          </div>
                          <div className="flex items-center gap-x-1">
                            <button
                              onClick={() =>
                                handleNewTask({
                                  task_list_id: list?.id,
                                  task_id: task?.id,
                                  title,
                                  notes,
                                  dueDate: due,
                                })
                              }
                              className="text-gray-700 hover:text-gray-500"
                            >
                              <FaEdit className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() =>
                                deleteTask({
                                  taskListId: list?.id,
                                  taskId: task?.id,
                                })
                              }
                              className="text-red-500 hover:text-red-700"
                            >
                              <FaTrashAlt className="w-4 h-4" />
                            </button>
                          </div>
                        </li>
                      );
                    })}
              </ul>
              {childTaskLists &&
              childTaskLists[list?.id] &&
              childTaskLists[list?.id]?.items?.filter(
                ({ status }) => status === "completed"
              )?.length ? (
                <ul>
                  <li className="mt-2 px-2 text-sm font-bold">Completed</li>
                  {childTaskLists[list?.id]?.items
                    ?.filter(({ status }) => status === "completed")
                    ?.map((task, index) => {
                      const { title, notes, due } = task;
                      return (
                        <li
                          className={`flex items-center gap-x-2 justify-between pl-2 !pr-3 py-2 cursor-pointer rounded-md`}
                          key={index}
                        >
                          <div className="flex gap-x-2">
                            <button className="hover:bg-gray-100 w-6 h-6 flex items-center justify-center rounded-full">
                              <FaCheck
                                className="text-blue-500"
                                onClick={() => {
                                  markAsCompleted({
                                    task_list_id: list.id,
                                    task_id: task.id,
                                    status: "needsAction",
                                  });
                                }}
                              />
                            </button>
                            <button
                              className="flex flex-col"
                              onClick={() =>
                                handleNewTask({
                                  task_list_id: list?.id,
                                  task_id: task?.id,
                                  title,
                                  notes,
                                  dueDate: due,
                                })
                              }
                            >
                              <span className="font-pop text-sm">{title}</span>
                              <span className="font-pop text-xs">{notes}</span>
                              {due ? (
                                <span className="border rounded-full bg-white !px-2.5 text-xs py-0.5 hover:!bg-gray-50 hover:shadow-sm">
                                  {getRelativeDateWithDayjs(due)}
                                </span>
                              ) : null}
                            </button>
                          </div>
                          <div className="flex items-center gap-x-1">
                            <button
                              onClick={() =>
                                handleNewTask({
                                  task_list_id: list?.id,
                                  task_id: task?.id,
                                  title,
                                  notes,
                                  dueDate: due,
                                })
                              }
                              className="text-gray-700 hover:text-gray-500"
                            >
                              <FaEdit className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() =>
                                deleteTask({
                                  taskListId: list?.id,
                                  taskId: task?.id,
                                })
                              }
                              className="text-red-500 hover:text-red-700"
                            >
                              <FaTrashAlt className="w-4 h-4" />
                            </button>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              ) : null}
              {childTaskLists[list?.id] ? null : (
                <div className="flex w-full items-center justify-center absolute bottom-0">
                  <button
                    onClick={() => fetchChildsList({ task_id: list?.id })}
                    className="flex items-center w-full justify-center gap-1 rounded-b-lg px-2 bg-gray-200 hover:bg-gray-300 text-black rounded-fu"
                  >
                    <FaSync size={11} />
                    <small>Load Tasks</small>
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GoogleTask;

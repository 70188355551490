import React from "react";
import FormInput from "../Common/FormInput";
import { FormTextArea } from "../../../components";
import AdditionalNotes from "../Components/AdditionalNotes";
import LocationSection from "../Common/Sections/LocationSection";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import FormCheckbox from "../../../components/molecules/FormCheckbox";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";
import TechNote from "../Common/Sections/TechNote";

const CarpetCleaningSection = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceDirect,
  checkServiceMess,
}) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-3">
        <PropertyOccupancy formik={formik} fieldsToShow={[]} />
        <div>
          <LocationSection
            setIsLoader={setIsLoader}
            formik={formik}
            callSellLeadToServiceDirect={callSellLeadToServiceDirect}
            checkServiceMess={checkServiceMess}
            checkServiceDirect={checkServiceDirect}
          />
          <SchedulerSection formik={formik} />
        </div>

        <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
          <label className="font-semibold text-sm">Areas to Clean</label>
          <div className="pt-3 grid grid-cols-1 gap-2">
            <FormInput
              name="extra_fields.area_to_clean.no_of_rooms"
              label={"No. of Rooms"}
              type="text"
              formik={formik}
              value={formik.values?.extra_fields?.area_to_clean?.no_of_rooms}
            />
            <FormInput
              name="extra_fields.area_to_clean.no_of_stairs"
              label={"No. of Stair Ways"}
              type="text"
              formik={formik}
              value={formik.values?.extra_fields?.area_to_clean?.no_of_stairs}
            />
          </div>
        </div>
        <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
          <label className="font-semibold text-sm">Upholstery cleaning</label>
          <div className="!pt-3 gap-2">
            <div className="flex gap-3">
              <FormCheckbox
                id="extra_fields.upholstery_cleaning.couch"
                name="extra_fields.upholstery_cleaning.couch"
                labelText="Couch"
                formik={formik}
                onChange={() =>
                  formik.setFieldValue(
                    "extra_fields.upholstery_cleaning.couch",
                    !formik?.values?.extra_fields?.upholstery_cleaning?.couch
                  )
                }
                checked={
                  formik?.values?.extra_fields?.upholstery_cleaning?.couch
                }
              />
              <FormCheckbox
                id="extra_fields.upholstery_cleaning.chair"
                name="extra_fields.upholstery_cleaning.chair"
                labelText="Chair"
                formik={formik}
                onChange={() =>
                  formik.setFieldValue(
                    "extra_fields.upholstery_cleaning.chair",
                    !formik?.values?.extra_fields?.upholstery_cleaning?.chair
                  )
                }
                checked={
                  formik?.values?.extra_fields?.upholstery_cleaning?.chair
                }
              />
              <FormCheckbox
                id="extra_fields.upholstery_cleaning.other"
                name="extra_fields.upholstery_cleaning.other"
                labelText="Other (please describe)"
                formik={formik}
                onChange={() => {
                  formik.setFieldValue(
                    "extra_fields.upholstery_cleaning.other",
                    !formik?.values?.extra_fields?.upholstery_cleaning?.other
                  );
                  formik.setFieldValue(
                    "extra_fields.upholstery_cleaning.other_description",
                    ""
                  );
                }}
                checked={
                  formik?.values?.extra_fields?.upholstery_cleaning?.other
                }
              />
            </div>
            {formik.values?.extra_fields?.upholstery_cleaning?.other ? (
              <div className="mt-1">
                <FormInput
                  id="extra_fields.upholstery_cleaning.other_description"
                  formik={formik}
                  name="extra_fields.upholstery_cleaning.other_description"
                  type="text"
                  value={
                    formik?.values?.extra_fields?.upholstery_cleaning
                      ?.other_description
                  }
                  placeholder={"Describe here..."}
                />
              </div>
            ) : null}
            <div className="mt-2">
              <FormInput
                name="extra_fields.upholstery_cleaning.no_of_seats"
                label={"No. of Seats"}
                type="text"
                formik={formik}
                value={
                  formik?.values?.extra_fields?.upholstery_cleaning?.no_of_seats
                }
              />
            </div>
          </div>
        </div>
        <div className="border border-[#F0F0F0]">
          <div className=" bg-[#FAFAFA] p-3" id={"rug_cleaning"}>
            <label className="font-semibold text-sm">Rug cleaning</label>
            <div className="!pt-3 gap-2">
              <div className="flex gap-3">
                <FormCheckbox
                  id="extra_fields.rug_cleaning.rug_in_ft"
                  name="extra_fields.rug_cleaning.rug_in_ft"
                  labelText="Rug size in ft"
                  formik={formik}
                  onChange={() =>
                    formik.setFieldValue(
                      "extra_fields.rug_cleaning.rug_in_ft",
                      !formik?.values?.extra_fields?.rug_cleaning?.rug_in_ft
                    )
                  }
                  checked={
                    formik?.values?.extra_fields?.rug_cleaning?.rug_in_ft
                  }
                />
                <FormCheckbox
                  id="extra_fields.rug_cleaning.ad"
                  name="extra_fields.rug_cleaning.ad"
                  labelText="Ad"
                  formik={formik}
                  onChange={() =>
                    formik.setFieldValue(
                      "extra_fields.rug_cleaning.ad",
                      !formik?.values?.extra_fields?.rug_cleaning?.ad
                    )
                  }
                  checked={formik?.values?.extra_fields?.rug_cleaning?.ad}
                />
              </div>
              <div className="mt-2">
                <FormInput
                  name="extra_fields.rug_cleaning.size_in_ft"
                  label={"Size in ft"}
                  type="text"
                  formik={formik}
                  value={formik?.values?.extra_fields?.rug_cleaning?.size_in_ft}
                />
              </div>
            </div>
          </div>
          {/* <div className="bg-[#80869A] !-2 p-3">
            <p className="text-white font-bold text-[16px]">
              RUG PRICE IS 4$ PER SQ FT
            </p>
            <p className="text-white text-[14px]">
              FT X FT =SQ FOOTAGE X 4$ = PRICE
            </p>
          </div> */}
        </div>

        <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
          <label className="pb-2 text-[15px] text-[#052A42]">
            Are there any specific spots that need special attention?
          </label>
          <FormRadioGroup
            id="extra_fields.specific_spot"
            name="extra_fields.specific_spot"
            labelProp="label"
            valueProp="value"
            options={[
              { value: true, label: "Yes (describe below)" },
              { value: false, label: "No" },
            ]}
            isOnChange={(e) => {
              const value = e === "true";
              formik.setFieldValue("extra_fields.specific_spot", value);
            }}
            isCheck={formik.values?.extra_fields?.specific_spot || false}
            isRadio={true}
            isHorizontal={true}
            // isBorder={true}
          />
          {formik.values?.extra_fields?.specific_spot ? (
            <div className="mt-2">
              <FormTextArea
                rows={6}
                name="extra_fields.specific_spot_description"
                formik={formik}
                type="text"
                label="Description:"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-span-2">
        <TechNote formik={formik} />
      </div>
    </div>
  );
};

export default CarpetCleaningSection;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  agentCreateLead,
  agentlessCreateLead,
} from "../services/agentlessFormService";
import {
  createLead,
  createLeadInitial,
  getLeads,
  getLeadsById,
  leadCreateJob,
  leadDecline,
  sendSms,
  singleFormCreateJob,
  singleFormSaveData,
  updateLead,
  updateVicidialId,
} from "../services/leads";

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  message: "",
  quote: null,
  insuranceDetail: null,  
};
const getErrorMessage = (error) => {
  return (
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error?.response?.data?.error ||
    error.toString()
  );
};
// Get Leads Data
export const getLeadsData = createAsyncThunk(
  "/api/get_leads",
  async (data, thunkAPI) => {
    try {
      return await getLeads(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Lead
export const createNewLead = createAsyncThunk(
  "/api/create_lead",
  async (data, thunkAPI) => {
    try {
      return await createLead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const create_lead_initial = createAsyncThunk(
  "/api/create_lead_initial",
  async (data, thunkAPI) => {
    try {
      return await createLeadInitial(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_vicidial_id = createAsyncThunk(
  "/api/update_vicidial_id",
  async (data, thunkAPI) => {
    try {
      return await updateVicidialId(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_new_quote = createAsyncThunk(
  "get_new_quote",
  async (data, thunkAPI) => {
    try {
      return await agentlessCreateLead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const agent_get_new_qoute = createAsyncThunk(
  "agent_get_new_qoute",
  async (data, thunkAPI) => {
    try {
      return await agentCreateLead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Job types
export const updateLeads = createAsyncThunk(
  "/api/update_lead",
  async (data, thunkAPI) => {
    try {
      return await updateLead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Send SMS
export const send_sms = createAsyncThunk("send_sms", async (data, thunkAPI) => {
  try {
    return await sendSms(data);
  } catch (error) {
    const message = getErrorMessage(error);
    return thunkAPI.rejectWithValue(message);
  }
});

// Lead Create Job
export const lead_create_job = createAsyncThunk(
  "lead_create_job",
  async (data, thunkAPI) => {
    try {
      return await leadCreateJob(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Single Form Save Data
export const single_form_save_data = createAsyncThunk(
  "single_form_save_data",
  async (data, thunkAPI) => {
    try {
      return await singleFormSaveData(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Single Form Create Job
export const single_form_create_job = createAsyncThunk(
  "single_form_create_job",
  async (data, thunkAPI) => {
    try {
      return await singleFormCreateJob(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Lead Decline Job
export const lead_decline = createAsyncThunk(
  "lead_decline",
  async (data, thunkAPI) => {
    try {
      return await leadDecline(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_leads_by_id = createAsyncThunk(
  "get_leads_by_id",
  async (id, thunkAPI) => {
    try {
      return await getLeadsById(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const LeadsSlice = createSlice({
  name: "getLeads",
  initialState,
  reducers: {
    leadsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
    resetInsuranceDetail: (state) => {
      state.insuranceDetail = null;
    },
    reset_quote: (state) => {
      state.quote = null;
    },
    setRecording: (state, action) => {
      const result = state.record?.records.findIndex(
        ({ vicidial_id }) => vicidial_id === action?.payload?.vicidial_id
      );
      if (result > -1) {
        state.record.records[result] = {
          ...state.record.records[result],
          recording: action.payload.url,
        };
      }
    },
  },
    
  extraReducers: (builder) => {
    builder
      .addCase(getLeadsData.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(getLeadsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(getLeadsData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createNewLead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.records.push(action.payload.data);
      })
      .addCase(createNewLead.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(lead_create_job.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lead_create_job.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.record.records.push(action.payload.data.data);
      })
      .addCase(lead_create_job.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_new_quote.pending, (state) => {
        state.isLoading = true;
        state.quote = null;
      })
      .addCase(get_new_quote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.quote = action.payload.data;
      })
      .addCase(get_new_quote.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(agent_get_new_qoute.pending, (state) => {
        state.isLoading = true;
        state.quote = null;
      })
      .addCase(agent_get_new_qoute.fulfilled, (state, action) => {
        state.isLoading = false;
        state.quote = action.payload.data;
      })
      .addCase(agent_get_new_qoute.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateLeads.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record.records[result],
          ...action.payload.data,
        };
      })
      .addCase(updateLeads.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(send_sms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(send_sms.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(send_sms.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(lead_decline.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lead_decline.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(lead_decline.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_leads_by_id.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_leads_by_id.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_leads_by_id.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(single_form_create_job.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(single_form_create_job.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(single_form_create_job.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(single_form_save_data.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(single_form_save_data.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(single_form_save_data.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_lead_initial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_lead_initial.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.record.records.push(action.payload.data);
      })
      .addCase(create_lead_initial.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_vicidial_id.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(update_vicidial_id.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_vicidial_id.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const { leadsReset, resetInsuranceDetail,setRecording, reset_quote } = LeadsSlice.actions;
export default LeadsSlice.reducer;

import React, { useState, useEffect } from "react";
import ReportHead from "../../../reports/Components/Header";
import Picker from "../../../reports/Components/Picker";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import api from "../../../services/api";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Button } from "../../../components";
import { MultiSelect } from "primereact/multiselect";
import { GridFooterContainer } from "@mui/x-data-grid-pro";
import { getLeadComapigns } from "../../../services/leadCampaign";
import { TablePagination } from "@mui/material";
import { IoEyeSharp } from "react-icons/io5";
import LeadsDataModal from "./LeadsDataModal";
const LeadSourcReport = () => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadCampaigns, setLeadCampaigns] = useState([]);
  const [leadsData, setLeadsData] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });

  const currentDate = new Date();
  const initialFilters = {
    from_date: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    )
      .toISOString()
      .split("T")[0],
    to_date: new Date().toISOString().split("T")[0],
    size: paginationModel.pageSize,
    page: paginationModel.page,
    lead_source_id: [],
    campaign_id: [],
  };

  const [filters, setFilters] = useState(initialFilters);

  const getReports = async (filter) => {
    const updatedPayload = {
      ...filter,
      startDate: filter.from_date,
      endDate: filter.to_date,
      limit: filter.size,
    };
    delete updatedPayload.from_date;
    delete updatedPayload.to_date;
    delete updatedPayload.size;
    setIsLoading(true);
    let payload = { ...updatedPayload };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post(`api/lead_source/lead_source_report`, payload);
      if (res.status === 200) {
        setRecords(res.data);
      }
    } catch (err) {
      toast.error("Error fetching lead sources");
      console.error("Error fetching lead sources:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReports({
      ...paginationModel,
      size: paginationModel.pageSize,
      ...filters,
    });
    // eslint-disable-next-line
  }, []);

  const columnDefs = [
    {
      field: "counter",
      filterable: false,
      headerName: "Sr.",
      width: 100,
    },
    {
      field: "reportDate",
      type: "date",
      headerName: "By Date",
      width: 200,
      valueGetter: (params) => new Date(params.row.reportDate),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.reportDate;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Total Lead",
      field: "totalLeads",
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <span className="flex flex-row">{params.row.totalLeads} leads</span>
      ),
    },
    {
      headerName: "30 Seconds",
      field: "lessThan30SecTotalSecs",
      flex: 1,
      width: 150,
      renderCell: (params) => {
        const thirtySecondPercentage =
          params.row.totalLeads > 0 && params.row.lessThan30SecLeads > 0
            ? Math.round(
                (params.row.lessThan30SecLeads * 100) / params.row.totalLeads
              )
            : 0;
        return (
          <span className="flex flex-row">
            {params.row.lessThan30SecLeads} ({thirtySecondPercentage}%)
          </span>
        );
      },
    },
    {
      headerName: "60 Seconds",
      field: "lessThan60SecTotalSecs",
      flex: 1,
      width: 200,
      renderCell: (params) => {
        const sixtySecondPercentage =
          params.row.totalLeads > 0 && params.row.lessThan60SecLeads > 0
            ? Math.round(
                (params.row.lessThan60SecLeads * 100) / params.row.totalLeads
              )
            : 0;
        return (
          <span className="flex flex-row">
            {params.row.lessThan60SecLeads} ({sixtySecondPercentage}%)
          </span>
        );
      },
    },
    {
      headerName: "More then 60 Seconds",
      field: "moreThan60SecTotalSecs",
      width: 130,
      renderCell: (params) => {
        const lessSixtySecondPercentage =
          params.row.totalLeads > 0 && params.row.moreThan60SecLeads > 0
            ? Math.round(
                (params.row.moreThan60SecLeads * 100) / params.row.totalLeads
              )
            : 0;
        return (
          <span className="flex flex-row">
            {params.row.moreThan60SecLeads} ({lessSixtySecondPercentage}%)
          </span>
        );
      },
    },
    {
      headerName: "Show Leads",
      field: "",
      align: "center",
      filterable: false,
      renderCell: (params) =>
        params?.row?.reportDate ? (
          <div class="flex justify-center items-center h-screen">
            <button
              onClick={() => setLeadsData(params.row.records)}
              className="w-full inline-flex items-center justify-center space-x-2"
            >
              <IoEyeSharp className="w-5 h-5" />
            </button>
          </div>
        ) : (
          <span>0</span>
        ),
      disableColumnMenu: true,
      minWidth: 80,
    },
  ];

  const getRowHeight = (params) => {
    const data = params?.model;
    const rowHeight = data?.callDate ? 60 : 40;
    const cellContentHeight = 0;
    const addressHeight = rowHeight + 10;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20;
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getReports({
      ...filters,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const getLeadCampaigns = async () => {
    try {
      setIsLoading(true);
      const res = await getLeadComapigns();
      if (res.status === 200) {
        setLeadCampaigns(res.data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const getLeadSourceListing = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("api/lead_source", payload);
      if (res.status === 200) {
        setLeadSource(res.data);
      }
    } catch (err) {
      console.error("Error fetching lead sources:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLeadSourceListing({
      ...paginationModel,
      size: paginationModel.pageSize,
    });
    getLeadCampaigns();
    // eslint-disable-next-line
  }, []);

  const CustomFooter = ({ data = [], columns = [] }) => {
    const totals = data?.reduce(
      (acc, row) => {
        acc.totalLeads += row.totalLeads || 0;
        acc.lessThan30SecLeads += row.lessThan30SecLeads || 0;
        acc.lessThan60SecLeads += row.lessThan60SecLeads || 0;
        acc.moreThan60SecLeads += row.moreThan60SecLeads || 0;
        return acc;
      },
      {
        totalLeads: 0,
        lessThan30SecLeads: 0,
        lessThan60SecLeads: 0,
        moreThan60SecLeads: 0,
      }
    );
    return (
      <GridFooterContainer>
        <div style={{ display: "flex", width: "100%" }}>
          {columns?.map((column, index) =>
            isLoading || index === 0 ? null : (
              <div
                key={column.field}
                style={{
                  flex: column.flex || 1,
                  width: column.width || 100,
                  textAlign: "center",
                  padding: "8px",
                  borderTop: "1px solid #e0e0e0",
                  fontWeight: 700,
                }}
                className="font-semibold"
              >
                {(() => {
                  switch (column.field) {
                    case "counter":
                      return "";
                    case "reportDate":
                      return "Total:";
                    case "totalLeads":
                      return totals.totalLeads;
                    case "lessThan30SecTotalSecs":
                      return totals.lessThan30SecLeads;
                    case "lessThan60SecTotalSecs":
                      return totals.lessThan60SecLeads;
                    case "moreThan60SecTotalSecs":
                      return totals.moreThan60SecLeads;
                    default:
                      return "";
                  }
                })()}
              </div>
            )
          )}
        </div>
      </GridFooterContainer>
    );
  };

  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getReports({
      ...filters,
      page: +page + 1,
      size: paginationModel.pageSize,
    });
  };

  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getReports({
      ...filters,
      page: 1,
      size: newPageSize,
    });
  };

  const LineOne = () => {
    return (
      <TablePagination
        color="primary"
        shape="rounded"
        size="medium"
        showFirstButton
        showLastButton
        count={
          records?.totalRecords
            ? records?.totalRecords
            : records?.dailyStats?.length || 0
        }
        page={paginationModel.page - 1 || 0}
        onPageChange={(e, page) => handleTopPageChange(page)}
        rowsPerPage={paginationModel.pageSize || 0}
        onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
        component="div"
        rowsPerPageOptions={[
          25,
          50,
          75,
          100,
          250,
          500,
          1000,
          1500,
          2000,
          records?.totalRecords
            ? records?.totalRecords
            : records?.dailyStats?.length || 0,
        ]}
      />
    );
  };
  console.log("leadsData", leadsData);
  return (
    <div className="container-fluid">
      <ReportHead route="Reports > Commission Report" heading="Login IP" />
      <div className="grid grid-cols-1 gap-2 md:grid-cols-12 mb-3">
        <div className="md:col-span-9">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
            <div className="md:col-span-3 !px-0.5 !mb-3">
              <MultiSelect
                placeholder="Select Lead Source"
                options={leadSource?.records}
                optionLabel="full_name"
                optionValue="_id"
                filter
                maxSelectedLabels={2}
                value={filters.lead_source_id}
                onChange={(e) =>
                  setFilters({ ...filters, lead_source_id: e.value })
                }
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>

            <div className="md:col-span-3 !px-0.5 !mb-3">
              <MultiSelect
                placeholder="Select Campaign"
                options={leadCampaigns}
                optionLabel="title"
                optionValue="_id"
                filter
                maxSelectedLabels={2}
                value={filters.campaign_id}
                onChange={(e) =>
                  setFilters({ ...filters, campaign_id: e.value })
                }
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>

            <div className="md:col-span-3 !px-0.5">
              <Button
                text="Search"
                variant="btn_submit"
                onClick={() => {
                  getReports({
                    ...paginationModel,
                    size: paginationModel.pageSize,
                    ...filters,
                  });
                }}
              />
              <Button
                className="ml-2"
                text="Reset"
                variant="btn_cancel"
                onClick={() => {
                  getReports({
                    ...paginationModel,
                    size: paginationModel.pageSize,
                    ...filters,
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="md:col-span-3 !px-0.5">
          <Picker
            filterData={filters}
            setFiltersData={setFilters}
            isDropdown="No"
          />
        </div>
      </div>
      {Object.keys(leadsData).length ? (
        <LeadsDataModal
          onCancelForm={() => setLeadsData({})}
          data={leadsData}
          filters={filters}
        />
      ) : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={records?.dailyStats?.map((record, index) => {
            const {
              date,
              totalLeads,
              lessThan30SecLeads,
              lessThan30SecTotalSecs,
              lessThan60SecLeads,
              lessThan60SecTotalSecs,
              moreThan60SecLeads,
              moreThan60SecTotalSecs,
            } = record;

            return {
              _id: index + 1,
              counter: index + offset + 1,
              records: { ...record, _id: index + 1 },
              totalLeads,
              lessThan30SecLeads,
              lessThan30SecTotalSecs,
              lessThan60SecLeads,
              lessThan60SecTotalSecs,
              moreThan60SecLeads,
              moreThan60SecTotalSecs,
              reportDate: dayjs(date).format("ddd, MMM D, YYYY"),
            };
          })}
          totalItems={records?.totalRecords}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          gridOptions={getRowHeight}
          searchable="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          LineOne={LineOne}
          CustomFooter={CustomFooter}
          foterProps={{ data: records?.dailyStats, columns: columnDefs }}
        />
      </div>
      <div className="d-none"></div>
    </div>
  );
};

export default LeadSourcReport;

import React from "react";
import { FormTextArea } from "../../../../components";

function TechNote({ formik }) {
  return (
    <>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 font-semibold text-sm">
          Additional Notes(any specific details for the tech):
        </label>
        <FormTextArea
          id="extra_fields.tech_note"
          rows={5}
          name="extra_fields.tech_note"
          formik={formik}
          type="text"
          value={formik.values?.extra_fields?.tech_note}
        />
      </div>
    </>
  );
}

export default TechNote;

import React from "react";
import { FaRegCalendarAlt, FaUsers } from "react-icons/fa";
import dayjs from "dayjs";
import Select from "react-select";
import {
  change_industry,
  set_job_status,
} from "../../../../features/jobsSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import errorMessage from "../../../../util/errorMessage";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AiOutlineGlobal } from "react-icons/ai";
import { MdCall } from "react-icons/md";
function JobHeader(props) {
  const { formik, tech } = props;
  const dispatch = useDispatch();
  const jobStatus = useSelector((state) => state.jobStatus);
  const industry = useSelector((state) => state.industry);
  const [scheduled_on, setScheduled_on] = React.useState(false);
  const scheduledOn = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setScheduled_on(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  const style = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      minWidth: "220px",
      height: "31px",
    }),
    control: (provided) => ({
      ...provided,
      height: "31px",
      minHeight: "31px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "31px",
    }),
  };
  const setJobStatus = async (e) => {
    const c = window.confirm("Are you sure want to change the status?");
    if (!c) return;
    try {
      const res = await dispatch(
        set_job_status({ job_status_id: e._id, id: formik.values.id })
      );
      if (res?.payload?.status === 200) {
        toast.success("Job Status Update Successfully");
        formik.setFieldValue("job_status_id", res.payload.data.job_status_id);
      } else {
        errorMessage({
          payload: res.payload,
          action: "Job Status",
          msg: "updated",
        });
      }
    } catch (err) {
      errorMessage({ payload: err, action: "Job Status", msg: "updated" });
    }
  };
  const changeIndustry = async (e) => {
    const c = window.confirm("Are you sure want to change the industry?");
    if (c) {
      try {
        const res = await dispatch(
          change_industry({ industry_id: e._id, lead_id: formik.values.id })
        );
        if (res?.payload?.status === 200) {
          toast.success("Industry Update Successfully");
          const industry_id = res.payload.data.industry_id;
          const industry_name = industry?.industryDrd?.find(
            ({ _id }) => _id === industry_id
          )?.name;
          formik.setFieldValue("industry_id", {
            _id: res.payload.data.industry_id,
            name: industry_name,
          });
        } else {
          errorMessage({
            payload: res.payload,
            action: "Industry",
            msg: "updated",
          });
        }
      } catch (err) {
        errorMessage({ payload: err, action: "Industry", msg: "updated" });
      }
    }
  };
  const activeStatus = jobStatus?.record?.records?.filter(
    (status) => status?.active
  );
  return (
    <>
      {/* <div className="mt-3 flex flex-wrap flex-col md:flex-row md:items-center bg-white gap-3 md:gap-4 px-4.5 z-[99]"> */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-2 gap-y-2 sm:gap-y-3 md:gap-y-5 lg:gap-y-6 mt-3 px-2">
        <div className="md:ml- flex flex-col md:-mt-5">
          <small>
            <FaRegCalendarAlt className="md:!ml-1 mr-1" size={15} />
            Scheduled on
          </small>
          {!scheduled_on ? (
            <small
              onClick={() => setScheduled_on(!scheduled_on)}
              className="flex items-center h-[31px] min-w-[220p !border !border-[#cccccc] rounded-[4px] px-2 cursor-pointer"
            >
              {formik.values.scheduled_on
                ? dayjs(formik.values.scheduled_on).format(
                    "ddd, MMM D, YYYY h:mm A"
                  )
                : "Select Scheduled on"}
            </small>
          ) : (
            <div className="flex flex-col justify-center">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  ref={scheduledOn}
                  open={true}
                  // label="Schdule On Date"
                  value={formik?.values?.scheduled_on}
                  onChange={(val) => {
                    formik?.setFieldValue("scheduled_on", val.toString());
                    // setScheduled_on(false)
                  }}
                  onClose={() => setScheduled_on(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      sx={{
                        "& label.Mui-focused": {
                          color: "#000",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",

                          "& fieldset": {
                            borderColor: "#a9a9a9",
                          },
                          "&:hover fieldset": {
                            borderColor: "#a9a9a9",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#a9a9a9",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "0.75em", // Adjust height here
                          padding: "10px", // Optionally adjust padding
                        },
                        "&  .MuiFormHelperText-root.Mui-error": {
                          backgroundColor: "transparent ",
                          margin: 0,
                          paddingLeft: 2,
                        },
                        mt: 0,
                      }}
                      size="small"
                    />
                  )}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [75, 0], // [horizontal, vertical] adjustment
                        },
                      },
                    ],
                  }}
                  PaperProps={{
                    sx: {
                      mt: 0, // Adds spacing between input and calendar
                      boxShadow: 3, // Optional: Adds shadow for better visibility
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>


        {formik.values.vendor_id && (
          <div className="md:ml- flex flex-col md:-mt-5">
            <small>
              <FaUsers className="md:!ml-1 mr-1" size={15} />
              Tech
            </small>
            <span className="flex items-center h-[31px] min-w-[220px] !border !border-[#cccccc] rounded-[4px] px-2">
              {tech?.first_name || ""}
            </span>
          </div>
        )}
        <div className="flex flex-col md:-mt-5">
          <small>
            <i className="md:ml-3 mr-1 fa fa-refresh" />
            Status
          </small>
          <div className="md:ml- mt-">
            <Select
              styles={style}
              onChange={(e) => setJobStatus(e)}
              onBlur={formik.handleBlur}
              name="job_status_id"
              className="w-"
              options={activeStatus}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
              placeholder="Select Job Status"
              value={{
                _id: formik.values.job_status_id?._id,
                name: formik.values.job_status_id?.name,
              }}
            />
          </div>
        </div>
        <div className="flex flex-col md:-mt-5">
          <small>
            <i className="md:ml-3 mr-1 fa fa-industry" />
            Industry
          </small>
          <div className="md:ml- mt-">
            <Select
              styles={style}
              onChange={(e) => changeIndustry(e)}
              onBlur={formik.handleBlur}
              name="industry_id"
              className="w-"
              options={industry?.industryDrd}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
              placeholder="Select Industry"
              value={{
                _id: formik.values.industry_id?._id
                  ? formik.values.industry_id?._id
                  : formik.values.industry_id,
                name: formik.values.industry_id?.name
                  ? formik.values.industry_id?.name
                  : industry?.industryDrd?.find(
                      ({ _id }) => _id === formik.values.industry_id
                    )?.name,
              }}
            />
          </div>
        </div>
        <div className="md:ml- flex flex-col md:-mt-5">
          <small>
            <MdCall className="md:!ml-1 mr-1" size={15} />
            Call Type
          </small>
          <span className="flex items-center h-[31px] min-w-[220px] !border !border-[#cccccc] rounded-[4px] px-2">
            {formik.values?.call_type || ""}
          </span>
        </div>
        <div className="md:ml- flex flex-col md:-mt-5">
          {/* {formik.values?.call_type === "Incoming" ? (
            <small>
              <AiOutlineGlobal className="md:!ml-2 mr-1" />
              Site Name
            </small>
          ) : (
            <small>
              <MdCall className="md:!ml-2 mr-1" size={20} />
              Call Type
            </small>
          )} */}
          <small>
            <AiOutlineGlobal className="md:!ml-2 mr-1" />
            Site Name
          </small>
          <input
            name="call_payload_domain"
            onChange={(e) =>
              formik.setFieldValue("call_payload_domain", e.target.value)
            }
            type="text"
            value={formik?.values?.call_payload_domain}
            readOnly={formik?.values?.call_payload}
            className="h-[31px] min-w-[220px] !border !border-[#cccccc] rounded-[4px] px-2"
          />
          {/* <span className="flex items-center h-[31px] min-w-[220px] !border !border-[#cccccc] rounded-[4px] px-2">
            {formik.values.call_payload
              ? formik.values.call_payload[0]?.did_group_id
              : formik.values?.call_type
              ? formik.values?.call_type
              : ""}
          </span> */}
        </div>
      </div>
    </>
  );
}

export default JobHeader;

import React from "react";
import { FormTextArea } from "../../../components";
import TechNote from "../Common/Sections/TechNote";
import LocationSection from "../Common/Sections/LocationSection";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";

const GarageDoorSection = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceMess,
  checkServiceDirect,
}) => {
  return (
    <div className="grid grid-cols-2 gap-3">
      <PropertyOccupancy formik={formik} fieldsToShow={[]} />
      <div>
        <LocationSection
          formik={formik}
          setIsLoader={setIsLoader}
          callSellLeadToServiceDirect={callSellLeadToServiceDirect}
          checkServiceMess={checkServiceMess}
          checkServiceDirect={checkServiceDirect}
        />
        <SchedulerSection formik={formik} />
      </div>

      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          What type of issue are you experiencing?
        </label>
        <FormRadioGroup
          id="extra_fields.issue"
          name="extra_fields.issue"
          labelProp="label"
          valueProp="value"
          options={[
            {
              value: "Door won’t open or close",
              label: "Door won’t open or close",
            },
            {
              value: "Broken or worn springs",
              label: "Broken or worn springs",
            },
            { value: "Door opener issue", label: "Door opener issue" },
            {
              value: "Loud or unusual noises",
              label: "Loud or unusual noises",
            },
            {
              value: "Door opens or closes partially",
              label: "Door opens or closes partially",
            },
            { value: "Door off-track", label: "Door off-track" },

            { value: "Other", label: "Other (please describe)" },
          ]}
          isOnChange={(e) => {
            formik.setFieldValue("extra_fields.issue", e);
          }}
          isCheck={formik.values?.extra_fields?.issue || null}
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
        {formik.values?.extra_fields?.issue === "Other" ? (
          <div className="mt-2">
            <FormTextArea
              rows={3}
              name="extra_fields.issue_description"
              formik={formik}
              type="text"
              label="Description:"
            />
          </div>
        ) : null}
      </div>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          What type of garage door do you have?
        </label>
        <FormRadioGroup
          id="extra_fields.garage_door_type"
          name="extra_fields.garage_door_type"
          labelProp="label"
          valueProp="value"
          options={[
            { value: "Single door", label: "Single door" },
            { value: "Double door", label: "Double door" },
            { value: "Multiple doors", label: "Multiple doors" },
            { value: "Barn door", label: "Barn door" },
            { value: "Commercial size", label: "Commercial size" },
          ]}
          isOnChange={(e) => {
            formik.setFieldValue("extra_fields.garage_door_type", e);
          }}
          isCheck={formik.values?.extra_fields?.garage_door_type || null}
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
      </div>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          Any visible damage to the gate, frame, or opener?
        </label>
        <FormRadioGroup
          id="extra_fields.damage"
          name="extra_fields.damage"
          labelProp="label"
          valueProp="value"
          options={[
            { value: true, label: "Yes (describe below)" },
            { value: false, label: "No" },
          ]}
          isOnChange={(e) => {
            const value = e === "true";
            formik.setFieldValue("extra_fields.damage", value);
          }}
          isCheck={
            formik.values?.extra_fields?.damage ||
            formik.values?.extra_fields?.damage === false
              ? formik.values?.extra_fields?.damage
              : null
          }
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
        {formik.values?.extra_fields?.damage ? (
          <div className="mt-2">
            <FormTextArea
              rows={3}
              name="extra_fields.damage_description"
              formik={formik}
              type="text"
              label="Description:"
            />
          </div>
        ) : null}
      </div>
      <div>
        <TechNote formik={formik} />
      </div>
    </div>
  );
};

export default GarageDoorSection;

const FormTextArea = ({
  id = null,
  rows = 16,
  name,
  label,
  formik,
  type,
  errors,
  value,
}) => {
  return (
    <div>
      <textarea
        id={id}
        name={name}
        rows={rows}
        type={type}
        value={value || formik.values[name]}
        placeholder={label}
        className={[
          "placeholder:text-[rgba(0,_0,_0,_0.6)] placeholder:font-normal placeholder:text-base placeholder:font-[Roboto,_Helvetica,_Arial,_sans-serif]",
          "text-sm rounded px-2 border border-[#ced4da] w-full",
          formik.errors[name] && formik.touched[name] ? "invalid" : "",
        ].join(" ")}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.errors[name] && formik.touched[name] && (
        <small className="form-text" style={{ color: "#D32F2F" }}>
          {formik.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </div>
  );
};

export default FormTextArea;

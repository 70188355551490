import React, { useState } from "react";
import FormInput from "../FormInput";
import { useSelector } from "react-redux";
import { TbUserDollar } from "react-icons/tb";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { FaEye, FaTimes } from "react-icons/fa";

function TransferCall({
  formik,
  checkServiceDirect,
  checkServiceMess,
  callSellLeadToServiceDirect,
  checkServiceMsg,
  availableVendors,
}) {
  const { isTransfer } = useSelector((state) => state.dialer);
  const [showVendors, setShowVendors] = useState(false);
  const columnDefs = [
    { field: "name", headerName: "Name", minWidth: 150, flex: 1 },
    { field: "vendor_type", headerName: "Type", minWidth: 100, flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/settings/vendors/update/${params.row._id}`}
          className="text-[#343434] hover:text-[#34343460] cursor-pointer"
          title="Show the vendor detail"
        >
          <FaEye />
        </a>
      ),
    },
  ];
  const VendorTable = () => {
    const items = availableVendors?.map(({ vendor_id }) => ({
      name: `${vendor_id?.first_name || ""} ${vendor_id?.last_name || ""}`,
      vendor_type: vendor_id?.vendor_type,
      _id: vendor_id?._id,
    }));
    return (
      <div className="absolute top-full left-0 bg-white w-full border z-100">
        <div className="flex justify-between w-full items-center px-2">
          <label className="font-semibold">Available Vendors</label>
          <button
            className="hover:bg-gray-200 rounded-full w-7 h-7 flex items-center justify-center"
            onClick={() => {
              setShowVendors(false);
            }}
          >
            <FaTimes />
          </button>
        </div>
        <div
          style={{
            height: items?.length > 1 ? "auto" : "100px",
            width: "100%",
            zIndex: 1000,
          }}
          className="p-2"
        >
          <DataGridPro
            density={"compact"}
            rows={items?.length ? items : []}
            columns={columnDefs}
            getRowId={(row) => row?._id}
            sx={{
              zIndex: 1200,
              position: "relative",
              background: "white",
            }}
            hideFooter={true}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="flex">
      {showVendors ? <VendorTable /> : null}
      <div>
        <FormInput
          name={`pickup_zip`}
          type="text"
          //   label={"Zip"}
          placeholder="Zip"
          formik={formik}
          onBlur={(e) => {
            checkServiceDirect(e?.target?.value, formik.values.industry_id);
          }}
        />
      </div>
      <div className="flex mt-1 w-full">
        {!isTransfer && checkServiceMess ? (
          <div
            id="alert-2"
            className="flex items-center mb-0 text-green-800 rounded-lg dark:text-green-400 !w-full"
            role="alert"
          >
            <TbUserDollar className="flex-shrink-0 w-5 h-5 text-black" />
            <div className="ms-1 text-sm font-medium flex items-center">
              <span className="text-red-600">Out of area, No vendor found</span>

              <span
                className="text-blue-500 no-underline cursor-pointer text-sm ml-2"
                onClick={() =>
                  callSellLeadToServiceDirect(
                    formik?.values,
                    "PARK_CUSTOMER_DIAL"
                  )
                }
              >
                Transfer Call
              </span>
              {/* <span className="text-black ml-1">(Beta)</span> */}
            </div>
          </div>
        ) : null}
        {checkServiceMsg && !availableVendors?.length ? (
          <span className="text-red-500 no-underline text-sm ml-2 mt-1">
            {checkServiceMsg}
          </span>
        ) : availableVendors?.length ? (
          <button
            onClick={() => {
              setShowVendors(true);
            }}
            className="text-green-900 font-semibold no-underline text-sm ml-2 "
          >
            {`In area, ${availableVendors?.length} vendors found`}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default TransferCall;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  changeVendorMerchant,
  createGeoVendors,
  deleteGeoVendors,
  deleteVendorIndustriesLocations,
  getChildVendors,
  getCities,
  getGeoParentVendorsDrd,
  getGeoVendors,
  getStates,
  getVendorIndustriesLocations,
  getVendorsDrd,
  getVendorsDrdNew,
  getZips,
  updateGeoVendors,
  updateSingleCell,
} from "../services/GeoVendorsService";
const initialState = {
  isError: false,
  isLoading: false,
  updateLoader: false,
  isSuccess: false,
  record: [],
  vendorDrd: [],
  states: [],
  cities: [],
  zips: [],
  message: "",
  child: [],
  geoVendorDrd: [],
  parentVendorDrd: [],
  vandorIndustryLoc: {},
  allVendors: [],
  isAllRecordLoading: false,
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_geo_states = createAsyncThunk(
  "get_states",
  async (data, thunkAPI) => {
    try {
      return await getStates(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_cities = createAsyncThunk(
  "get_cities",
  async (data, thunkAPI) => {
    try {
      return await getCities(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_zips = createAsyncThunk(
  "get_zips",
  async (city_id, thunkAPI) => {
    try {
      return await getZips(city_id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_geo_vendors = createAsyncThunk(
  "get_geo_vendors",
  async (data, thunkAPI) => {
    try {
      return await getGeoVendors(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Industry Data
export const get_all_geo_vendors = createAsyncThunk(
  "get_all_geo_vendors",
  async (data, thunkAPI) => {
    let payload = { ...data };
    if (!payload.filters?.rules?.length) {
      delete payload.filters;
    }
    try {
      return await getGeoVendors(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_geo_parent_vendors = createAsyncThunk(
  "get_geo_parent_vendors",
  async (data, thunkAPI) => {
    try {
      return await getGeoParentVendorsDrd(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_vendors_drd = createAsyncThunk(
  "get_vendors_drd",
  async (data, thunkAPI) => {
    try {
      return await getVendorsDrd(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_geo_vendors_new_drd = createAsyncThunk(
  "get_geo_vendors_new_drd",
  async (data, thunkAPI) => {
    try {
      return await getVendorsDrdNew(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_child_geo_vendors = createAsyncThunk(
  "get_child_geo_vendors",
  async (id, thunkAPI) => {
    try {
      return await getChildVendors(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const get_vendor_industries_locations = createAsyncThunk(
  "get_vendor_industries_locations",
  async (id, thunkAPI) => {
    try {
      return await getVendorIndustriesLocations(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industry Data
export const delete_industies_locations = createAsyncThunk(
  "delete_industies_locations",
  async (id, thunkAPI) => {
    try {
      return await deleteVendorIndustriesLocations(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Industry
export const create_geo_vendors = createAsyncThunk(
  "create_geo_vendors",
  async (data, thunkAPI) => {
    try {
      return await createGeoVendors(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_geo_vendors = createAsyncThunk(
  "update_geo_vendors",
  async (data, thunkAPI) => {
    try {
      return await updateGeoVendors(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Industry
export const delete_geo_vendors = createAsyncThunk(
  "delete_geo_vendors",
  async (id, thunkAPI) => {
    try {
      return await deleteGeoVendors(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Industry
export const update_single_cell = createAsyncThunk(
  "update_single_cell",
  async (data, thunkAPI) => {
    try {
      return await updateSingleCell(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const geoVendorsSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    geoVendorsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.record = [];
      state.vendorDrd = [];
      state.states = [];
      state.cities = [];
      state.zips = [];
      state.message = "";
      state.child = [];
      state.geoVendorDrd = [];
      state.parentVendorDrd = [];
      state.vandorIndustryLoc = {};
    },
    clearCities: (state) => {
      state.cities = [];
    },
    clearStates: (state) => {
      state.states = [];
    },
    clearZips: (state) => {
      state.zips = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_geo_vendors.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_geo_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_geo_vendors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_vendors_drd.pending, (state) => {
        state.isLoading = true;
        state.vendorDrd = [];
      })
      .addCase(get_vendors_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendorDrd = action.payload?.data;
      })
      .addCase(get_vendors_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_geo_vendors_new_drd.pending, (state) => {
        state.isLoading = true;
        state.geoVendorDrd = [];
      })
      .addCase(get_geo_vendors_new_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.geoVendorDrd = action.payload?.data;
      })
      .addCase(get_geo_vendors_new_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_geo_states.pending, (state) => {
        state.isLoading = true;
        state.states = [];
      })
      .addCase(get_geo_states.fulfilled, (state, action) => {
        state.isLoading = false;
        state.states = action.payload?.data;
      })
      .addCase(get_geo_states.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_cities.pending, (state) => {
        state.isLoading = true;
        state.cities = [];
      })
      .addCase(get_cities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cities = action.payload?.data;
      })
      .addCase(get_cities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_zips.pending, (state) => {
        state.isLoading = true;
        state.zips = [];
      })
      .addCase(get_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.zips = action.payload?.data;
      })
      .addCase(get_zips.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_geo_vendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_geo_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.record?.records?.length > 0) {
          state.record?.records?.push(action.payload.data[0]);
          state.allVendors?.records?.push(action.payload.data[0]);
        }
      })
      .addCase(create_geo_vendors.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_geo_vendors.pending, (state) => {
        state.updateLoader = true;
      })
      .addCase(update_geo_vendors.fulfilled, (state, action) => {
        state.updateLoader = false;
        if (state.record?.records?.length > 0) {
          const result = state.record?.records.findIndex(
            ({ id }) => id === action?.payload?.data[0]?.id
          );
          state.record.records[result] = {
            ...state.record?.records[result],
            ...action.payload.data[0],
          };
          const result2 = state.allVendors.records.findIndex(
            ({ id }) => id === action?.payload?.data[0]?.id
          );
          state.allVendors.records[result2] = {
            ...state.allVendors.records[result2],
            ...action.payload.data[0],
          };
        }
      })
      .addCase(update_geo_vendors.rejected, (state, action) => {
        state.updateLoader = false;
      })
      .addCase(delete_geo_vendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_geo_vendors.fulfilled, (state, action) => {
        state.allVendors.records = state.allVendors?.records.filter(
          ({ _id }) => _id !== action?.payload?.data
        );
        state.isLoading = false;
      })
      .addCase(delete_geo_vendors.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_child_geo_vendors.pending, (state) => {
        state.isLoading = true;
        state.child = [];
      })
      .addCase(get_child_geo_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.child = action.payload?.data;
      })
      .addCase(get_child_geo_vendors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_geo_parent_vendors.pending, (state) => {
        state.isLoading = true;
        state.parentVendorDrd = [];
      })
      .addCase(get_geo_parent_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.parentVendorDrd = action.payload?.data;
      })
      .addCase(get_geo_parent_vendors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_vendor_industries_locations.pending, (state) => {
        state.isLoading = true;
        state.vandorIndustryLoc = {};
      })
      .addCase(get_vendor_industries_locations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vandorIndustryLoc = action.payload?.data;
      })
      .addCase(get_vendor_industries_locations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(delete_industies_locations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_industies_locations.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_industies_locations.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_all_geo_vendors.pending, (state) => {
        state.isAllRecordLoading = true;
        state.allVendors = [];
      })
      .addCase(get_all_geo_vendors.fulfilled, (state, action) => {
        state.isAllRecordLoading = false;
        state.allVendors = action.payload?.data;
      })
      .addCase(get_all_geo_vendors.rejected, (state, action) => {
        state.isAllRecordLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(update_single_cell.pending, (state) => {
        // state.isAllRecordLoading = true;
      })
      .addCase(update_single_cell.fulfilled, (state, action) => {
        // state.isAllRecordLoading = false;
      })
      .addCase(update_single_cell.rejected, (state, action) => {
        // state.isAllRecordLoading = false;
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});
export const { reset, clearCities, clearStates, clearZips, geoVendorsReset } =
  geoVendorsSlice.actions;
export default geoVendorsSlice.reducer;

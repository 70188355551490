import React, { useState } from "react";
import { DotsLoader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import {
  get_lead_source_web_query,
  get_leadsource_drd,
} from "../../features/reportsSlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import dayjs from "dayjs";

import PageHeader from "../../components/molecules/PageHeader";

import { NumericFormat } from "react-number-format";
import { useNavigate, useLocation } from "react-router-dom";

import { get_industry_drd } from "../../features/IndustrySlice";

const LeadWebQueryReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [queryOptions, setQueryOptions] = useState({
    groupOp: "AND",
    rules: [
      ...(queryParams.get("lead_id")
        ? [
            {
              field: "createdBy",
              op: "eq",
              data: queryParams.get("lead_id"),
            },
          ]
        : []),
    ],
  });
  const { isLoading, webQueryReport, leadSourceDrd } = useSelector(
    (state) => state.reports
  );
  const { industryDrd } = useSelector((state) => state.industry);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_lead_source_web_query({
        ...sortingModel,
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    dispatch(
      get_lead_source_web_query({
        filters: queryOptions,
        page: paginationModel.page,
        size: paginationModel.pageSize,
        sort_field: params[0]?.field,
        sort_by: params[0]?.sort || "default",
      })
    );
  };

  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  React.useEffect(() => {
    const payload = {
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    dispatch(get_lead_source_web_query(payload));
    dispatch(get_industry_drd());
    dispatch(get_leadsource_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer ml-2"
              onClick={() => setSelectedData([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }

  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, width: 60 },
    {
      headerName: "Contact Name",
      field: "contact_name",
      flex: 1,
      filterable: true,
      minWidth: 180,
    },
    { headerName: "Email", field: "email", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 150 },
    {
      headerName: "Location",
      field: "location",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const { zip = null, state = null, city = null } = params.row.records;
        return (
          <div className="flex flex-col">
            {zip && <div>Zip: {zip}</div>}
            {city && <div>City: {city}</div>}
            {state && state !== "undefined" && <div>State: {state}</div>}
          </div>
        );
      },
    },
    {
      headerName: "Industry",
      field: "industry_name",
      flex: 1,
      type: "singleSelect",
      minWidth: 150,
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => params.row.industry_name,
      valueFormatter: (params) => params.value.industry_name,
    },
    {
      headerName: "Message",
      field: "message",
      minWidth: 200,
      wrap: true,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.message?.length ? params.row.message : ""}</span>
          </div>
        );
      },
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Created By",
      field: "createdBy",
      flex: 1,
      minWidth: 120,
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.full_name,
      valueOptions: leadSourceDrd,
      valueGetter: (params) => params?.row?.industry_id,
      valueFormatter: (params) => params.value,
      renderCell: (params) => (
        <span className="font-bol">{params?.row?.createdBy}</span>
      ),
    },
    {
      headerName: "Message Date",
      field: "message_date",
      flex: 1,
      minWidth: 120,
      type: "date",
      valueGetter: (params) => new Date(params.row.messageDate),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.messageDate;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      align: "center",
      minWidth: 180,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
  ];
  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not" && !isSelect && isNumber
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };

  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });

  const processFilters = (filters) => {
    return (filters || [])?.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        filter.value?.length &&
        filter.value[0]?.includes(",")
      ) {
        return {
          ...filter,
          value: filter.value[0].split(",").map((item) => item.trim()),
        };
      }
      return filter;
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    const item = processFilters(filterModel?.items || []);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      setPaginationModel({ ...paginationModel, page: 1 });
      dispatch(
        get_lead_source_web_query({
          ...sortingModel,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    processFilters(filterModel?.items)?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel?.logicOperator?.toUpperCase() || "AND",
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    const payload = {
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    };

    dispatch(get_lead_source_web_query(payload));
  };

  const filterIP = webQueryReport?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const data = params?.model;
    const substring = data?.message?.substring(0, 100);
    const numberOfLines = substring ? Math.ceil(substring?.length / 30) : 0;
    const messageHeight = rowHeight + numberOfLines * 30 + 0;
    return messageHeight;
  };

  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Web Form Report"
        onClick={() => navigate("/lead_source/add")}
      />
      {/* Loader */}
      {isLoading && <DotsLoader />}
      {/* Count */}
      <div className="pt-3">
        <div className="grid grid-cols-3 gap-4">
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 p-4 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Count</span>
            <span className="text-base font-medium font-pop">
              {webQueryReport?.summary?.form_count && (
                <NumericFormat
                  displayType="text"
                  value={webQueryReport?.summary?.form_count}
                  thousandSeparator={true}
                />
              )}
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 p-4 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Lead Count</span>
            <span className="text-base font-medium font-pop">
              {webQueryReport?.summary?.lead_count && (
                <NumericFormat
                  displayType="text"
                  value={webQueryReport?.summary?.lead_count}
                  thousandSeparator={true}
                />
              )}
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 p-4 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Job Count</span>
            <span className="text-base font-medium font-pop">
              {webQueryReport?.summary?.job_count && (
                <NumericFormat
                  displayType="text"
                  value={webQueryReport?.summary?.job_count}
                  thousandSeparator={true}
                />
              )}
            </span>
          </div>
        </div>
      </div>

      {/* List */}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterIP?.map((record, index) => {
            const {
              contact_name,
              email,
              phone,
              message,
              createdAt,
              industry_name,
              message_date,
              createdBy,
            } = record;
            let counter = index + 1;
            return {
              records: record,
              counter,
              industry_name,
              contact_name,
              email,
              phone,
              message,
              createdBy: createdBy?.title || "",
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              messageDate: message_date
                ? dayjs(message_date).format("ddd, MMM D, YYYY h:mm A")
                : "N/A",
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          totalItems={webQueryReport?.totalItems}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          filterModel={filterModel}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default LeadWebQueryReport;

import api from "./api";
export const getJobs = async (data) => {
  return await api.get(`/api/jobs`, {
    params: data,
  });
};
export const getJobsById = async (id) => {
  return await api.get(`/api/jobs/${id}`);
};
export const handleTech = async ({ tech_user_id, id }) => {
  return await api.post(`/api/jobs/${id}/vendor`, { tech_user_id });
};
export const updateJob = async (data) => {
  return await api.put("/api/jobs", data);
};
export const closeJob = async (data) => {
  return await api.post(`api/jobs/${data?.id}/close_job`, data);
};
export const setJobStatus = async (data) => {
  return await api.put("/api/jobs/job_status", data);
};
export const changeIndustry = async (data) => {
  return await api.put("/api/jobs/industry_change", data);
};
export const addImages = async (data) => {
  return await api.post("/api/jobs/upload_imgs", data);
};
export const jobCallLogs = async (data) => {
  return await api.post("/api/jobs/job_call", data);
};
export const updateCost = async (data) => {
  return await api.put("/api/jobs/service_cost", data);
};
export const jobPaymentMethod = async (value) => {
  return await api.post(`/api/leads/add_card_detail`, value);
};

export const getJobStatusWithCount = async () => {
  return await api.get(`/api/jobs/get_job_status_with_job_count`);
};
export const getNewJobStatusWithCount = async () => {
  return await api.get(`/api/jobstatus/system_job_status/job_status`);
};
export const migrateJobStatus = async (data) => {
  return await api.post(`/api/jobs/migrate_job_status`, data);
};
export const addNote = async (id, note) => {
  return await api.post(`/api/jobs/${id}/notes`, { note: note });
};
export const updateInvoiceDetail = async ({ id, data }) => {
  return await api.post(`/api/jobs/${id}/update_invoice_details`, data);
};

export const getNotes = async (id) => {
  return await api.get(`/api/jobs/${id}/notes`);
};

export const sendImageForm = async (id) => {
  return await api.get(`/api/sms/send_img_upload_link/${id}`);
};
export const sendLocationForm = async (id) => {
  return await api.post(`/api/leads/send_location_link_sms`, { id });
};
export const jobPaymentCharged = async ({
  job_id,
  payment_gateway_id,
  amount,
  auth_only,
}) => {
  return await api.post(`/api/jobs/${job_id}/payments`, {
    payment_gateway_id,
    amount,
    auth_only,
  });
};
export const getAmountTransactions = async ({ id, type }) => {
  return await api.get(`/api/jobs/${id}/payments?type=${type}`);
};

export const jobPaymentRefund = async ({ job_id, job_payment_id, amount }) => {
  return await api.post(`/api/jobs/${job_id}/refund/${job_payment_id}`, {
    amount,
  });
};
export const addLeadTags = async (data) => {
  return await api.post(`/api/lead_tags`, data);
};
export const getLeadTags = async (id) => {
  return await api.get(`/api/lead_tags?lead_id=${id}`);
};

export const getHeaderSearch = async (data) => {
  return await api.get(`/api/jobs/header_search`, { params: data });
};
export const deleteLeadTags = async (data) => {
  return await api.delete(
    `/api/lead_tags`,
    { data: data },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};
export const notifyTech = async ({
  job_id,
  notify_by,
  extra_feilds_text = "",
}) => {
  return await api.post(`/api/jobs/${job_id}/notify_tech`, {
    notify_by: notify_by,
    extra_feilds_text,
    extra_fields_text:extra_feilds_text
  });
};

export const getFullCardNumber = async (job_id) => {
  return await api.post(`/api/jobs/get_virtual_card_number/${job_id}`);
};
import React, { useState, useEffect } from "react";
import { getMonth } from "./util";
import CalendarHeader from "./components/CalendarHeader";
import Sidebar from "./components/Sidebar";
import Month from "./components/Month";
import EventModal from "./components/EventModal";
import { useDispatch, useSelector } from "react-redux";
import { DotsLoader } from "../../../components";
import JobModal from "./components/jobsModal";
import Days from "./components/Days";
import Weeks from "./components/Weeks";
import { get_google_calendar_events } from "../../../features/calendarEventSlice";
import AddUpdateGoogleEventModal from "./components/AddGoogleEventModal";
function CalendarEvents() {
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const {
    monthIndex,
    viewType,
    showEventModal,
    showJobModal,
    isLoading,
    calendarEvents,
    addUpdateEvent,
  } = useSelector((state) => state?.calendarEvents || {});
  const start_date = currenMonth[0][0].toISOString();
  const end_date = currenMonth[4][6].toISOString();
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);
  useEffect(() => {
    getLeadsByFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenMonth]);
  const getLeadsByFilter = () => {
    dispatch(
      get_google_calendar_events({
        startDate: start_date,
        endDate: end_date,
        get_from_google: false,
      })
    );
  };
  // const onReset = () => {
  //   dispatch(
  //     get_google_calendar_events({ startDate: start_date, endDate: end_date })
  //   );
  // };

  function transformData(item) {
    return {
      main_data: {
        _id: item._id,
        user_id: item.user_id,
        startDateTime: item.startDateTime,
        endDateTime: item.endDateTime,
        reminders: item.reminders,
      },
      ...item.event,
    };
  }
  const transformedData = calendarEvents?.map(transformData);
  return (
    <>
      {showEventModal && <EventModal getLeadsByFilter={getLeadsByFilter} />}
      {showJobModal && <JobModal />}
      {addUpdateEvent && (
        <AddUpdateGoogleEventModal getLeadsByFilter={getLeadsByFilter} />
      )}
      {isLoading && <DotsLoader />}
      <div className="h-screen flex flex-col bg-white">
        <CalendarHeader />
        <div className="flex-cols md:flex flex-1">
          <Sidebar />
          {viewType === "month" ? (
            <Month month={currenMonth} />
          ) : viewType === "week" ? (
            <Weeks events={calendarEvents} />
          ) : viewType === "day" ? (
            <Days events={transformedData} />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default CalendarEvents;

import React, { useCallback, useRef, useState } from "react";
import FormInput from "../Common/FormInput";
import PhoneFormInput from "../../../components/molecules/PhoneFormInput";
import { useDropzone } from "react-dropzone";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { DotsLoader } from "../../../components";
import { useSelector } from "react-redux";

function CustomerInformation({ formik, errors }) {
  const { industryDrd } = useSelector((state) => state.industry);
  const [isLoader, setIsLoader] = useState(false);
  const checkAttachments = () => {
    const attachments = formik.values.attachments;
    if (!Array.isArray(attachments) || attachments.length === 0) {
      return { hasAttachments: false, hasFiles: false, hasUrlsOnly: false };
    }
    const hasFiles = attachments.some(
      (item) =>
        item instanceof File ||
        (typeof item === "string" &&
          (item.startsWith("data:image") || item.includes("data:image")))
    );
    const hasUrlsOnly =
      !hasFiles && attachments.every((item) => typeof item === "string");

    return { hasAttachments: true, hasFiles, hasUrlsOnly };
  };

  const inputFile = useRef(null);
  const onFileChange = (files) => {
    let img = [];
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileDataUrl = reader.result;
        if (checkAttachments().hasAttachments) {
          const imageExists = formik.values.attachments.some(
            (existingImage) => existingImage === fileDataUrl
          );
          if (!imageExists) {
            img = img.concat(reader.result);
          }
        } else {
          img = img.concat(reader.result);
        }
        formik.setFieldValue(
          "attachments",
          checkAttachments().hasAttachments
            ? [...formik.values.attachments, ...img]
            : img
        );
      };
      reader.readAsDataURL(file);
    });
    inputFile.current.value = null;
  };
  const onDrop = useCallback((acceptedFiles) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const removeImage = (index) => {
    if (typeof index === "string" && index.includes("merchants")) {
      const c = window.confirm("Are you sure want to delete this image?");
      if (!c) return;
      return deleteImage(index);
    }
    const c = window.confirm("Are you sure want to remove this image?");
    if (!c) return;
    const updatedAttachments = formik.values.attachments.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("attachments", updatedAttachments);
  };
  const deleteImage = async (path) => {
    setIsLoader(true);
    const file_name = path?.split("/").pop();
    try {
      const res = await api.post("api/jobs/delete_imgs", {
        id: formik.values._id,
        file_name,
      });
      if (res?.status === 200) {
        toast.success("Images deleted Successfully");
        const updatedAttachments = formik.values.attachments.filter(
          (attachment) => !attachment.includes(file_name)
        );
        formik.setFieldValue("attachments", updatedAttachments);
      } else {
        toast.error(res.data?.message || res?.data || "Image deletion failed");
      }
    } catch (err) {
      console.log("👊 ~ deleteImage ~ err:", err);
      toast.error(
        err.response.data?.message ||
          err?.response?.data ||
          "Image deletion failed"
      );
    }
    setIsLoader(false);
  };

  return (
    <>
      <div className="bg-white border  px-2 md:px-4 ">
        {isLoader ? <DotsLoader /> : null}
         <div className="pl-2 pt-3 !mb-2">
          <div className="font-semibold text-lg">Customer Information:</div>
        </div>
        {/*<div className="flex grid grid-cols-4 items-center gap-4 pb-2">
          <div className="">
            <FormInput
              name="company_name"
              type="text"
              label="Company Name"
              formik={formik}
              disabled={true}
              value={
                formik.values.call_payload
                  ? formik.values.call_payload[0]?.did_group_id
                  : ""
              }
            />
          </div>

          <div className="mb-2">
            <label
              className="font-semibold text-xs text-gray-500 mb-1"
              htmlFor={"lead_num"}
            >
              {"Industry Category"}
            </label>

            <FormSelectInput
              name="industry_id"
              options={industryDrd}
              valueProp="_id"
              labelProp="name"
              // formik={formik}
              value={formik.values?.industry_id}
              onChange={(value) => {
                const name =
                  industryDrd?.find(({ _id }) => _id === value)?.name || "";
                formik.setFieldValue("industry_id", value);
                formik.setFieldValue("industry_name", name);
              }}
            />
          </div>
        </div> */}

        <div className="border-t-2 mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 mb-4 mt-2">
            <FormInput
              name="name"
              type="text"
              label="Full Name"
              formik={formik}
            />
            {/* <FormInput name="last_name" type="text" label={"Last Name"} /> */}
            <FormInput
              name="email"
              type="text"
              label={"Enter Email"}
              formik={formik}
            />
            <PhoneFormInput
              name="phone"
              formik={formik}
              placeholder="Phone No."
              label="Phone Number"
              onChange={(e) => formik.setFieldValue("phone", e)}
              value={formik.values.phone}
              errors={errors}
            />
            <PhoneFormInput
              name="alternate_phone"
              label="Alternative Number"
              placeholder="Alternate Phone No."
              formik={formik}
              onChange={(e) => formik.setFieldValue("alternate_phone", e)}
              value={formik?.values?.alternate_phone}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerInformation;
